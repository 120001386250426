import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { Terminal } from '../../../../models/dto/terminale';
import * as fromRoot from '../../../../app.reducer';
import * as TERMINAL from '../../../../ngrx/terminal.actions';
import moment from 'moment';
import { DateFormat } from '../../../../helpers/enum';

@Component({
  selector: 'app-terminal-locker-mng-dialog',
  templateUrl: './terminal-locker-mng-dialog.component.html',
  styleUrls: ['./terminal-locker-mng-dialog.component.scss']
})
export class TerminalLockerMngDialogComponent implements OnInit {

  rygiel: any;
  df = DateFormat;

  lockerForm = this.fb.group({
    id: [null],
    nazwa: ['', Validators.required],
    _statusRygla: ['', Validators.required],
    czas: [''],
    oznakowanie: ['']
  });
  isLockerPresent = false;

  statusList = [
    {id: 1, status: 'nowy'},
    {id: 2, status: 'niepotwierdzony'},
    {id: 3, status: 'potwierdzony'},
    {id: 4, status: 'zapasowy'},
    {id: 5, status: 'uszkodzony'},
    {id: 6, status: 'zlikwidowany'},
    {id: 7, status: 'usunięty'},
  ];

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<TerminalLockerMngDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { terminal: Terminal }) {
    this.isLockerPresent = data.terminal.rygiels?.length > 0;
    this.lockerForm.patchValue({_statusRygla: this.statusList[0]});
    if (this.isLockerPresent) {
      this.rygiel = data.terminal.rygiels[0];
      this.lockerForm.patchValue(this.rygiel);
    }
  }

  ngOnInit(): void {
  }

  saveOrUpdate() {
    let obj = {...this.lockerForm.value, _terminal: {id: this.data.terminal.id}};
    delete obj.czas;
    if (!obj.id) {
      obj = {...obj, data_prod_rygla: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')};
    }
    this.store.dispatch(new TERMINAL.LockerInsertOrUpdateRequest({locker: obj, successCallback: () => this.dialogRef.close()}));
    return false;
  }

  compareById(o1, o2) {
    return o1.id === o2.id;
  }

}
