import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';

import { Naczepa } from '../../../../models/dto/vehicle';
import { Privs } from 'src/app/helpers/enum';
import { DetailsTrailComponent } from '../details-trail/details-trail.component';
import { RightDrawerService } from '../../../shared/services';
import { RemoveTrailRequest } from '../../../../ngrx/vehicle.actions';
import { BaseYesNoConfig } from '../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../shared/dialogs';
import { Strings } from '../../../../helpers';

@Component({
  selector: 'app-trailer-list',
  templateUrl: './trailer-list.component.html',
  styleUrls: ['./trailer-list.component.scss']
})
export class TrailListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  trailsInfo: MatTableDataSource<Naczepa>;
  displayedColumns: string[] = ['Company', 'Reg. no.', 'Model', 'Work status', 'Vehicle status', 'Buckle', 'Set alloted', 'Actions'];
  subs = new Subscription();
  ePrivs = Privs;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog,
              private route: ActivatedRoute, private rightDrawer: RightDrawerService) {
  }

  ngOnInit() {
    this.trailsInfo = new MatTableDataSource<Naczepa>([]);
    this.trailsInfo.sort = this.sort;
    this.trailsInfo.sortingDataAccessor = (item: Naczepa, property) => {
      switch (property) {
        case 'Company':
          return item._firma?.nazwa;
        case 'Reg. no.':
          return item.nr_rej;
        case 'Model':
          return item._model.model;
        case 'Work status':
          return item._stan_pracy.stan_pracy;
        case 'Vehicle status':
          return item._status.status;
        case 'Buckle':
          return item._typ_zapiecia.id;
        case 'Set alloted':
          return item.w_zestawie;
        default:
          return item[property];
      }
    };

    this.subs.add(
      combineLatest(
        [
          this.store.select(fromRoot.selectors.vehicles.getTrails),
          this.route.paramMap,
          this.store.select(fromRoot.selectors.vehicles.getVehicleSearch),
        ]
      )
        .subscribe(([trailList, paramMap, searchCond]) => {
          let filteredList = trailList;
          if (searchCond.kind === 'trailer') {
            if (searchCond.term.length > 0) {
              filteredList = [...filteredList]
                .filter(t => Strings.searchTextIgnoreAccents(
                  searchCond.term,
                  t.nr_rej + t._model.model + t._status.status + t._model._marka.marka + t.uwagi
                ) >= 0);
            }

            if (searchCond.states.length > 0) {
              filteredList = [...filteredList].filter(t => searchCond.states.includes(t._status.id));
            }
          }

          this.trailsInfo.data = filteredList;
          const id = paramMap.get('id');
          if (!!id && id !== 'create') {
            const selectedTrail = paramMap.get('id') && trailList.find((trail) => trail.id === +paramMap.get('id'));
            this.rightDrawer.openOver(DetailsTrailComponent, {trailer: selectedTrail, mode: 'edit'});
          } else if (!!id && id === 'create') {
            this.rightDrawer.openOver(DetailsTrailComponent, {mode: 'create'});
          }
        })
    );
  }

  ngAfterViewInit(): void {
    this.trailsInfo.sort = this.sort;
    this.trailsInfo.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.rightDrawer.close();
    this.subs.unsubscribe();
  }

  delete(trail: Naczepa): void {
    const config: BaseYesNoConfig = {
      title: 'Trailer deletion',
      content: `Are you sure you want to delete trailer '${trail.nr_rej}'? It might be used in transport set.`,
      yesAction: () => this.store.dispatch(new RemoveTrailRequest({trail})),
      yesLabel: 'Delete',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-TrailerRemoval',
      position: {top: '7%'}
    });
  }
}
