import { CoordsNamedPL } from '../../modules/shared/interfaces';
import { SvgMapIcon } from '../../helpers/enum';

export interface GenericPoint extends CoordsNamedPL {
  id: number | string;
  label: string;
  pointClickAction?: (evt?: any) => void;
  iconType: SvgMapIcon;
  domIconString?: string;
  groupName?: string;
}
