<form class="example-container" [formGroup]="terminalAssignForm" (ngSubmit)="onSubmit()">
  <div class="myForm">
    <mat-form-field>
      <mat-label>Terminal</mat-label>
      <mat-select formControlName="terminal">
        <mat-option *ngFor="let dev of terminale$ | async" [value]="dev.id">
          {{dev.id}} <i>{{dev.uwagi}}</i>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field>
      <mat-label>Veh. type</mat-label>
      <mat-select [(value)]="typPojazdu">
        <mat-option value="truck">Truck</mat-option>
        <mat-option value="trail">Trailer</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field *ngIf="typPojazdu == 'truck'">
      <mat-label>Truck</mat-label>
      <mat-select formControlName="samochod">
        <mat-option *ngFor="let item of ciezarowki$ | async" [value]="item.id">{{item.nr_rej}}
          <i>{{item._model._marka.marka}} {{item._model.model}}</i></mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="typPojazdu == 'trail'">
      <mat-label>Trailer</mat-label>
      <mat-select formControlName="naczepa">
        <mat-option *ngFor="let item of naczepy$ | async" [value]="item.id">{{item.nr_rej}} <i>{{item._model.model}}
          , {{item._model._marka.marka}}</i>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button mat-raised-button color="accent" type="submit">
    Assign
  </button>
</form>
