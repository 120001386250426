export class SideNavItem {
  path: string;
  title: string;
  icon?: string;

  constructor(url: string, label: string) {
    this.path = url;
    this.title = label;
  }
}
