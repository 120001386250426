<p *ngIf="selectedUser$ | async as user">
  <span *ngIf="user?.czy_zabl && !user?.czy_usun" class="account blocked">
    Account blocked
  </span>
  <span *ngIf="user?.czy_usun" class="account removed">
    Account removed
  </span>
  <span>
    User: {{user?.nazwisko}} {{user?.imie}}, <i>{{user?._firma.nazwa}}, {{user?.email}}</i>
  </span>
</p>
<div class="main" fxLayout="row wrap" fxLayoutGap="15px" [hidden]="myOutlet.isActivated">
  <app-tile-item *ngFor="let item of menuItems" [item]="item"></app-tile-item>
</div>
<router-outlet #myOutlet="outlet"></router-outlet>
