<div class="mat-elevation-z8 white-bar">
  <table mat-table [dataSource]="trucksInfo" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column">{{ column }}</th>
      <td mat-cell *matCellDef="let element">
        <p [ngSwitch]="column">
          <span *ngSwitchCase="'Company'">{{ element._firma.nazwa }}</span>
          <span *ngSwitchCase="'Reg. no.'">
            <a [routerLink]="['../', element.nr_rej]">{{ element.nr_rej }}</a>
          </span>
          <span *ngSwitchCase="'Model'">
            {{ element._model._marka.marka }} - {{ element._model.model }}
          </span>
          <span *ngSwitchCase="'Vehicle status'">{{ element._status.status }}</span>
          <span *ngSwitchCase="'Work status'">{{ element._akt_stan_pracy.stan_pracy }}</span>
          <span *ngSwitchCase="'Buckle'">{{ element._typ_zapiecia.nazwa }}</span>
          <span *ngSwitchCase="'Actions'">
              <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="more-actions-icon">more_vert</mat-icon></button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['../', element.nr_rej]" *appHasPrivileges="[ePrivs.ADM_VEHICLES]">
                  <mat-icon>mode_edit</mat-icon>
                  <span class="action-description">
                    Edit
                  </span>
                </button>
                <button mat-menu-item (click)="delete(element)" *appHasPrivileges="[ePrivs.ADM_VEHICLES]">
                  <mat-icon>delete</mat-icon>
                  <span class="action-description">
                    Delete
                  </span>
                </button>
              </mat-menu>
            </span>
          <span *ngSwitchDefault>{{ element.w_zestawie | yesNo }}</span>

        </p>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"></tr>
  </table>
  <mat-paginator appPaginationPageSize
                 [length]="trucksInfo.data.length"
                 showFirstLastButtons
  ></mat-paginator>
</div>
