import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as TRANSSET from '../../ngrx/transset.actions';

import { DateFormat } from '../../../../helpers/enum';
import { ZestawTransportowyBase } from '../../../../models/dto/transportSets';
import { TransportHelper } from '../../../../helpers';
import { SetCreateComponent } from '../set-create/set-create.component';
import { RightDrawerService } from '../../../shared/services';

@Component({
  selector: 'app-set-list',
  templateUrl: './set-list.component.html',
  styleUrls: ['./set-list.component.scss']
})
export class SetListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['id', 'ost_kom', 'flagi', 'sklad', 'kierowcy', 'uwagi'];
  subs = new Subscription();
  df = DateFormat;

  transport_sets = new MatTableDataSource();


  constructor(private rootStore: Store<fromRoot.State>, private rightDrawer: RightDrawerService) {
    this.rootStore.dispatch(TRANSSET.loadFullTranssetListRequestNew());
  }

  ngOnInit(): void {
    this.transport_sets.sortingDataAccessor = (item: ZestawTransportowyBase, property) => {
      switch (property) {
        case 'czas_utw':
          return new Date(item.czasUtworz);
        case 'ost_kom':
          return new Date(item.czasKom);
        default:
          return item[property];
      }
    };
    this.subs.add(
      combineLatest([
        this.rootStore.select(fromRoot.selectors.transset.getFullTranssetListNew),
      ])
        .pipe(
          filter(res => res[0] !== undefined && res[0].length > 0),
        )
        .subscribe(([sets]) => {
          this.transport_sets.data = [...sets].filter(r => TransportHelper.isValidTransset(r));
          this.transport_sets.sort = this.sort;
          this.transport_sets.paginator = this.paginator;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  edit(setId: number): void {
    this.rightDrawer.openOver(SetCreateComponent, {setId});
  }

}
