<div fxLayout="column" fxLayoutGap="32px">
  <h2>New transport set</h2>
  <form [formGroup]="formZestaw" (ngSubmit)="onSubmit()"
        fxFlex fxLayout="column" fxLayoutGap="16px" class="content-compact">

    <app-input autocomplete="false" label="Name" [control]="getControl('uwagi')" name="fdw-transsetname"></app-input>

    <app-autocomplete label="Truck" [lov]="trucks2Lov$ | async" prefixIcon="" name="fdw-truck"
                      [control]="getControl('samochod')"></app-autocomplete>

    <app-autocomplete label="Trailer" [lov]="trails2Lov$ | async" prefixIcon="" name="fdw-trailer"
                      [control]="getControl('naczepa')"></app-autocomplete>

    <div fxFlex fxLayout="column" fxLayoutGap="16px">
      <h2>Drivers</h2>
      <app-autocomplete label="Default driver 1" [lov]="driversLov$ | async" name="fdw-drv"
                        [control]="getControl('kierowca1')"></app-autocomplete>

      <app-autocomplete label="Default driver 2" [lov]="driversLov$ | async" name="fdw-drv"
                        [control]="getControl('kierowca2')"></app-autocomplete>
    </div>
<!-- [disabled]="formZestaw.invalid"-->
    <button mat-raised-button type="submit" color="primary">Save</button>
  </form>
</div>
