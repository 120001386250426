import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import * as fromRoot from '../../../../app.reducer';
import * as Vehicles from '../../../../ngrx/vehicle.actions';
import * as UI from '../../../../ngrx/ui.actions';

import { Marka, Model, Naczepa, StanPracy, StatusPojazdu, TypZapiecia } from '../../../../models/dto/vehicle';
import { TrailForm } from '../../../../models/form/vehicle';
import { Strings } from '../../../../helpers';
import { General } from '../../../../messages';
import { ToastType } from '../../../../helpers/enum';
import { FormHelper } from '../../../shared/helpers';
import { SelectOption } from '../../../shared/interfaces';
import { TerminalMngService } from '../../../../services/terminal-mng.service';
import { ITerminalEquipment } from '../../../../models/dto/terminale';

@Component({
  selector: 'app-details-trail',
  templateUrl: './details-trail.component.html',
  styleUrls: ['./details-trail.component.scss']
})
export class DetailsTrailComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('DetailsTrailComponent');
  subs = new Subscription();
  form = new TrailForm();
  trailer: Naczepa = null;
  mode = '';
  public terminalEq$: Observable<ITerminalEquipment[]> = this.store
    .select(fromRoot.selectors.devices.getTerminalTrail)
    .pipe(
      switchMap((terminalTrails) => {
        const trailTerminalId =
          terminalTrails.find(
            (terminalTruck) => terminalTruck._naczepa.id === this.trailer.id
          )?._terminal.id || null;

        if (trailTerminalId) {
          return this.terminalService.getTerminalEquipment(trailTerminalId);
        } else {
          return of(null);
        }
      })
    );

  public vehicleModels: Model[] = [];
  public vehicleFilteredModelsLov: SelectOption<Model>[] = [];
  public vehicleVendorsLov: SelectOption<Marka>[] = [];

  public connectTypeListLov: SelectOption<TypZapiecia>[] = [];
  public vehicleStateLov: SelectOption<StatusPojazdu>[] = [];
  public trailerWorkStateLov: SelectOption<StanPracy>[] = [];
  public vehicleTypesLov: SelectOption<any>[] = [];

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private terminalService: TerminalMngService
  ) {
    this.store.dispatch(new Vehicles.LoadVendorsRequest());
    this.store.dispatch(new Vehicles.LoadConnectionTypesRequest());
    this.store.dispatch(new Vehicles.LoadWorkStatesRequest());
    this.store.dispatch(new Vehicles.LoadModelsRequest());
    this.store.dispatch(new Vehicles.LoadVehicleStatesRequest());
    this.store.dispatch(new Vehicles.LoadTrailTypesRequest());
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.ui.getRightDrawerInfo),
        this.store.select(fromRoot.selectors.vehicles.getModels),
        this.store.select(fromRoot.selectors.vehicles.getVendors),
        this.store.select(fromRoot.selectors.vehicles.getConnTypes),
        this.store.select(fromRoot.selectors.vehicles.getWorkStates),
        this.store.select(fromRoot.selectors.vehicles.getVehicleStates),
        this.store.select(fromRoot.selectors.vehicles.getTrailTypes)
      ]).pipe(
        filter(data => (typeof data[0].componentName !== 'undefined' && data[0].componentName === DetailsTrailComponent.myname)
          || (data[0].isOpened === false && this.trailer !== null)
          || (this.trailer && this.trailer.nr_rej !== data[0].componentData.nr_rej)),
        filter(f =>
          !!f[0].componentData
          && f[1] && f[1].length > 0
          && f[2] && f[2].length > 0
          && f[3] && f[3].length > 0
          && f[4] && f[4].length > 0
          && f[5] && f[5].length > 0
          && f[6] && f[6].length > 0
        )
      ).subscribe(([drawerInfo, modelList, vendorList, connTypes, workStates, stateList, typeList]) => {
        this.mode = drawerInfo.componentData.mode;
        if (this.mode === 'edit') {
          this.trailer = drawerInfo.componentData.trailer as Naczepa;
        }

        this.vehicleModels = modelList;
        this.vehicleVendorsLov = FormHelper.makeSelectLov(vendorList, 'marka');
        this.vehicleStateLov = FormHelper.makeSelectLov(stateList, 'status');
        this.connectTypeListLov = FormHelper.makeSelectLov(connTypes, 'nazwa');
        this.vehicleTypesLov = FormHelper.makeSelectLov(typeList, 'typ');

        const lov = [...workStates]
          .filter(item => item.dotyczy_pojazdu === false || (this.trailer && this.trailer._stan_pracy.id === item.id));
        this.trailerWorkStateLov = FormHelper.makeSelectLov(lov, 'stan_pracy', ' ', (v: StanPracy) => v.dotyczy_pojazdu === true);
        if (this.trailer) {
          this.form.patchFromModel(this.trailer);
          const lovModel = this.vehicleModels
            .filter(model => model._marka.id === this.trailer._model._marka.id);
          this.vehicleFilteredModelsLov = FormHelper.makeSelectLov(lovModel, 'model');
        }
      })
    );
  }

  onSubmit(): void {
    const trail = this.form.getUpdatedModel();
    if (!!this.trailer) {
      this.store.dispatch(new Vehicles.EditTrailRequest({
        trail,
        callback: () => {
          this.store.dispatch(UI.showUserMessage({
            message: {
              message: General.OPERATION_SUCCESS,
              type: ToastType.SUCCESS,
            }
          }));
          this.router.navigateByUrl('/dictionaries/vehicles/menu/trail/list');
        }
      }));
    } else {
      this.store.dispatch(new Vehicles.AddTrailRequest({
        trailer: trail,
        callback: () => {
          this.store.dispatch(UI.showUserMessage({
            message: {
              message: General.OPERATION_SUCCESS,
              type: ToastType.SUCCESS,
            }
          }));
        }
      }));
    }
  }

  vendorSelected(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      const lov = this.vehicleModels
        .filter(model => model._marka.id === event.source.value.id);
      this.vehicleFilteredModelsLov = FormHelper.makeSelectLov(lov, 'model');

      if (lov.length < 1) {
        this.store.dispatch(UI.showUserMessage({
          message: {
            type: ToastType.ERROR,
            message: 'No models found for selected brand!',
          }
        }));
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.router.navigateByUrl('/dictionaries/vehicles/menu/trail/list');
  }

}
