import { GenericType } from '../common';

export interface OprogramowanieWersjaRamki extends GenericType {
  active: boolean;
  wersja: number;
  ramka: {
    id: number;
    nazwa: string;
    doSerwera: boolean;
  };
}
