import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TransportHistoryColumns } from '../../enum/transport-history-columns.enum';
import { ITransitHistoryItem } from '../../interfaces/transit-history-item';
import { TransitService } from '../../services';

@Component({
  selector: 'transport-history',
  templateUrl: './transport-history.component.html',
  styleUrls: ['./transport-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportHistoryComponent implements OnChanges {
  @Input() transitId: number;

  private transitId$: ReplaySubject<number> = new ReplaySubject();
  private historyListSource = new MatTableDataSource<ITransitHistoryItem>();

  historyList$: Observable<MatTableDataSource<ITransitHistoryItem>> =
    this.transitId$.pipe(
      switchMap((transitId) => this.transitService.getHistory(transitId)),
      map((historyList) => {
        const dataSource = this.historyListSource;
        dataSource.data = historyList;
        return dataSource;
      })
    );

  TransportHistoryColumns = TransportHistoryColumns;
  historyColumnList = Object.values(TransportHistoryColumns);

  constructor(private transitService: TransitService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.transitId) {
      this.transitId$.next(this.transitId);
    }
  }
}
