<div class="overview-container" fxFlex fxLayoutAlign="none start" [fxLayout]="isRightDrawer ? 'column' : 'row'" fxLayoutGap="16px">
  <div class="overview-details" fxFlex fxLayout="column" fxLayoutGap="16px">
    <app-truck-and-trailer
      [trailer]="transport.zestaw"
      [truck]="transport.idSamochod">
    </app-truck-and-trailer>
    <app-transport-drivers [kierowcaPrzejazdList]="transport.kierowcaPrzejazdList"></app-transport-drivers>
    <app-transport-timetable [transport]="transport"></app-transport-timetable>
    <app-transport-routes [transportRoutes]="routes ?? []" [transport]="transport"></app-transport-routes>
    <app-transport-monitoring [transport]="transport" [parkingLots]="parkingLots" *appHasPrivileges="[ePrivs.MNG_TRANSSET_SAFETY]"></app-transport-monitoring>
<!--    todo: odkomentować jak pojawi się więcej parametrów-->
<!--    <app-transport-monitoring-parameters [transport]="transport"></app-transport-monitoring-parameters>-->
  </div>
  <div fxFlex fxLayout="column" fxLayoutGap="16px" [ngStyle]="{width: '100%'}">
    <app-transport-actions [transport]="transport"></app-transport-actions>
  </div>
</div>
