import { Directive, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { CommonDirective } from '../common.directive';
import { UserRoleNames } from '../../../../helpers/enum';

@Directive({
  selector: '[appHasRoleSuperAdmin]'
})
export class HasRoleSuperAdminDirective extends CommonDirective {

  constructor(protected element: ElementRef,
              protected templateRef: TemplateRef<any>,
              protected viewContainer: ViewContainerRef) {
    super(element, templateRef, viewContainer);
  }

  protected checkPermission(): boolean {
    return CommonDirective.tokenDetails$.getValue().roles.indexOf(UserRoleNames.adminFdw) >= 0;
  }
}
