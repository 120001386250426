export interface NewTrackRequest {
  nazwa_trasy: string;
  czy_utworzyc_szablon: boolean;
  nazwa_szablonu?: string;
  czy_prywatny: boolean;
  /**
   * numeric id value
   */
  id_uzytkownik: string;
  /**
   * numeric id value
   */
  id_wersja_mapy: string;
  /**
   * numeric id value
   */
  id_przejazd: string;
  numer_porzadkowy: string;
  przelicz_trase: boolean;
  trasa_map_json: any;
}
