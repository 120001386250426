import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import * as fromDriver from '../../ngrx/driver.reducer';
import * as DRIVER from '../../ngrx/driver.actions';
import { Kierowca } from '../../interfaces';
import { BaseYesNoConfig } from 'src/app/modules/shared/interfaces/base-yes-no-config';
import { MatDialog } from '@angular/material/dialog';
import { BaseYesNoDialogComponent } from 'src/app/modules/shared/dialogs/base-yes-no-dialog/base-yes-no-dialog.component';

@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss']
})
export class DriverListComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['osoba', 'telefon', 'data_zatr', 'nr_karty', 'login', 'actions'];
  subs = new Subscription();

  dataSource = new MatTableDataSource<Kierowca>();
  kierowcy: Kierowca[] = [];
  searchControl = new FormControl('');
  searchText = '';
  filtered = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private store: Store<fromDriver.State>, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.dataSource.filterPredicate = (data: Kierowca, searchedPhrase: string) => {
      const phrase = (data._uzytkownik.imie + data._uzytkownik.nazwisko + data._uzytkownik.tel1
                    + data._uzytkownik.imie.concat(' ', data._uzytkownik.nazwisko)
                    + data._uzytkownik.nazwisko.concat(' ', data._uzytkownik.imie)
                    + data._uzytkownik.tel2 + data.nr_karty + data._uzytkownik.username).trim().toLowerCase();
      return !filter || phrase.indexOf(searchedPhrase.trim().toLowerCase()) >= 0;
    };
    this.subs.add(
      this.searchControl.valueChanges.pipe(
        debounceTime(200),
        startWith('')
      ).subscribe(string => this.dataSource.filter = string)
    );
    this.subs.add(
      this.store.select(fromDriver.getDrivers)
        .pipe(
          filter(f => f !== undefined && f.length > 0)
        )
        .subscribe(r => {
          this.kierowcy = [...r].sort((a, b) => {
            return a._uzytkownik.nazwisko.toLowerCase() > b._uzytkownik.nazwisko.toLowerCase() ? 1 : -1;
          });

          this.dataSource.sortingDataAccessor = (item: Kierowca, property) => {
            switch (property) {
              case 'osoba':
                return item._uzytkownik.nazwisko;
              case 'telefon':
                return item._uzytkownik.tel1;
              case 'data_zatr':
                return new Date(item._uzytkownik.data_zatr);
              case 'nr_karty':
                return item.nr_karty;
              case 'login':
                return item.nr_karty;
              default:
                return item[property];
            }
          };
          this.dataSource.data = this.kierowcy;
        })
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public deleteDriver(kierowca: Kierowca): void {
      const config: BaseYesNoConfig = {
        title: 'Driver deletion',
        content: `Are you sure you want to delete driver '${kierowca._uzytkownik.imie} ${kierowca._uzytkownik.nazwisko}'?`,
        yesAction: () => this.store.dispatch(new DRIVER.DeleteDriverRequest({id: kierowca.id})),
        yesLabel: 'Delete',
        yesColor: 'warn',
        noLabel: 'Cancel',
        noColor: 'primary',
        autoClosure: true,
      };
      this.dialog.open(BaseYesNoDialogComponent, {
        data: config,
        id: 'BaseYesNoDialogComponent-DriverRemoval',
        position: {top: '7%'}
      });
  }
}
