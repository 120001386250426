import { Terminal } from './terminal';

export interface SamochodTerminal {
  id?: number;
  _samochod: { id: number, nr_rej: string };
  _terminal: Terminal;
  czas_zamont: string;
  czas_usuniecia?: string;
  podstawowy?: boolean;
}
