import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';

import * as fromRoot from '../../../../../app.reducer';
import * as TERMINAL from '../../../../../ngrx/terminal.actions';
import * as UI from '../../../../../ngrx/ui.actions';

import { PoleRamki, PoleRamkiTypDanych, Ramka, WersjaRamki } from '../../../../../models/dto/terminale';
import { FrameFieldEditDialogComponent, FrameVersionEditDialogComponent } from '../../../dialogs';

@Component({
  selector: 'app-config-editor',
  templateUrl: './config-editor.component.html',
  styleUrls: ['./config-editor.component.scss']
})
export class ConfigEditorComponent implements OnInit {

  validatorError = false;
  validators = {
    dubleNazwy: [],
    dublePolaBazy: [],
    nakladajaceBity: false,
    wolneBity: false,
  };

  routeParams: { nazwa: string, typ: string, wersja: number };
  subs = new Subscription();
  ramka: Ramka;
  wersja: WersjaRamki;
  typyPol: PoleRamkiTypDanych[] = [];

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, private dialog: MatDialog, private router: Router) {
    this.store.dispatch(new TERMINAL.LoadFrameFieldTypesRequest());
    this.routeParams = {
      nazwa: this.route.snapshot.paramMap.get('name'),
      typ: this.route.snapshot.paramMap.get('type'),
      wersja: parseInt(this.route.snapshot.paramMap.get('version'), 10),
    };
    this.route.paramMap.subscribe(p => {
      this.routeParams = {
        nazwa: p.get('name'),
        typ: p.get('type'),
        wersja: parseInt(p.get('version'), 10),
      };
    });
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getFrames),
        this.route.paramMap,
        this.store.select(fromRoot.selectors.devices.getFrameFieldTypes)
      ])
        .pipe(
          filter(f => f[0] !== undefined && f[0].length > 0 && f[1].has('name') && f[2].length > 0),
          map(([f, rp, tp]) => {
            const r = f.find(t => t.nazwa === rp.get('name'));
            const w = r.wersjaRamkis.find(t => t.wersja === parseInt(rp.get('version'), 10) && t.typ === rp.get('type'));

            return [r, w, tp];
          }),
          filter(r => r[0] !== undefined && r[1] !== undefined)
        )
        .subscribe(([r, w, tp]) => {
          this.ramka = r as Ramka;
          this.typyPol = tp as PoleRamkiTypDanych[];
          const p = (w as WersjaRamki).poleRamkis;
          this.wersja = {
            ...(w as WersjaRamki),
            poleRamkis: [...p].sort((a, b) => a.bitOd > b.bitOd ? 1 : -1)
          };
          this.sprawdzPoprawnoscPol();
        })
    );
  }

  edytujWersje() {
    this.dialog.open(FrameVersionEditDialogComponent,
      {
        data: {wersja: this.wersja, ramka: this.ramka},
        minWidth: '250px'
      }
    ).afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['../../', result.typ, result.wersja], {relativeTo: this.route});
      }
    });
  }

  edytujPole(poleRamki: PoleRamki) {
    this.dialog.open(FrameFieldEditDialogComponent,
      {
        data: {wersja: this.wersja, ramka: this.ramka, pole: poleRamki, typy: this.typyPol},
        minWidth: '250px'
      }
    ).afterClosed().subscribe(updPole => {
      if (updPole) {
        const nPola = [...this.wersja.poleRamkis].map(p => {
          if (p.id !== updPole.id) {
            return p;
          }
          return updPole;
        });
        const res = this.sprawdzPoprawnoscPol(nPola);
        if (res === true) {
          const request = {
            pole: updPole,
            wersja: this.wersja,
            ramka: this.ramka
          };
          this.store.dispatch(new TERMINAL.UpdateFrameFieldRequest(request));
        } else {
          let msg = 'Niepoprawne wartości, podaj jeszcze raz.';
          if (res.dubleNazwy.length > 0) {
            msg = 'Takie pole jest już określone: [' + res.dubleNazwy[0] + ']';
          }
          if (res.dublePolaBazy.length > 0) {
            msg = 'Taka kolumna już przypisana: [' + res.dublePolaBazy[0] + ']';
          }
          if (res.nakladajaceBity) {
            msg = 'Zakresy bitowe się nakładają!';
          }
          this.store.dispatch(UI.userError({message: msg}));
        }
      }
    });
  }

  private sprawdzPoprawnoscPol(polaRamki?: PoleRamki[]) {
    this.validators = {
      dubleNazwy: [],
      dublePolaBazy: [],
      nakladajaceBity: false,
      wolneBity: false
    };

    let pola = this.wersja.poleRamkis;
    if (polaRamki && polaRamki.length > 0) {
      pola = polaRamki;
    }

    pola.forEach((p, idx) => {
      const idxDB = pola
        .findIndex((q, i) => {
          if (i <= idx || !p.db || !q.db) {
            return false;
          }

          return p.db.trim().length > 0 && q.db.trim().length > 0 && q.db.toLowerCase() === p.db.toLowerCase();
        });
      if (idxDB >= 0) {
        this.validators.dublePolaBazy.push(p.nazwaPola + ' | ' + pola[idxDB].nazwaPola);
      }

      const idxNAZWA = pola
        .findIndex((q, i) => i > idx && q.nazwaPola.toLowerCase() === p.nazwaPola.toLowerCase());
      if (idxNAZWA >= 0) {
        this.validators.dubleNazwy.push(pola[idxNAZWA].nazwaPola);
      }

      if (idx + 1 < pola.length) {
        const kolejny = pola[idx + 1];
        this.validators.nakladajaceBity = this.validators.nakladajaceBity || p.bitDo >= kolejny.bitOd;
        this.validators.wolneBity = this.validators.wolneBity || p.bitDo + 1 !== kolejny.bitOd && kolejny.bitOd > p.bitDo;
      }
    });

    const res = this.validators.nakladajaceBity
      || this.validators.dubleNazwy.length > 0 || this.validators.dublePolaBazy.length > 0;

    if (polaRamki && polaRamki.length > 0) {
      return res ? {...this.validators} : true;
    }
    this.validatorError = res || this.validators.wolneBity;
  }

}
