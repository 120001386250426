import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as TRANSSET from '../ngrx/transset.actions';
import * as fromTransset from '../ngrx/transset.reducer';

import { RightDrawerService } from '../../shared/services';
import { SetCreateComponent } from './set-create/set-create.component';

@Component({
  selector: 'app-transport-set',
  templateUrl: './transport-set.component.html',
  styleUrls: ['./transport-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportSetComponent implements OnInit {

  constructor(
    private store: Store<fromTransset.State>,
    private rightDrawer: RightDrawerService
  ) {
  }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    this.store.dispatch(TRANSSET.loadFullTranssetListRequestNew());
    this.store.dispatch(TRANSSET.loadDriversRequest());
  }

  createSet(): void {
    this.rightDrawer.openOver(SetCreateComponent);
  }
}
