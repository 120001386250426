import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { isNil } from 'lodash';
import { VehicleFromTransitPipe } from 'src/app/modules/shared/pipes';
import * as fromRoot from '../../../../app.reducer';
import { TransportHelper } from '../../../../helpers';
import { TerminalVehicleType } from '../../../../models/dto/terminale';
import { ZestawTransportowyBase } from '../../../../models/dto/transportSets';
import { VehicleBasic } from '../../../../models/dto/vehicle';
import {
  ITruckAndTrailerDetails,
  ZestawTransportowy,
} from '../../../transport-set/interfaces';
import { TransportSetsColumnsEnum } from '../../enum/transport-sets-columns.enum';
import * as TRANSPORT from '../../ngrx/transport.actions';
import * as fromTransport from '../../ngrx/transport.reducer';

@Component({
  selector: 'ready-transport-sets',
  templateUrl: './ready-transport-sets.component.html',
  styleUrls: ['./ready-transport-sets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyTransportSetsComponent implements OnChanges, OnDestroy {
  @Input() transSetValue: ZestawTransportowy | null | undefined;

  currentlyViewedTransportSet$: Observable<ZestawTransportowy>;
  readyTransportSetsPanelIsOpened$: Observable<boolean>;
  subs = new Subscription();

  details: ITruckAndTrailerDetails;
  vehicleType: typeof TerminalVehicleType = TerminalVehicleType;
  transportSetsColumns: typeof TransportSetsColumnsEnum =
    TransportSetsColumnsEnum;
  displayedColumns: string[] = Object.values(this.transportSetsColumns);

  filterSets: FormControl = new FormControl(null);

  transportSetList$: Observable<ZestawTransportowyBase[]> = combineLatest([
    this.store.select(fromTransport.getTransportSetsAvailableWithinTimeRange),
    this.filterSets.valueChanges.pipe(startWith('')),
  ]).pipe(
    filter(
      ([transSets, filterValue]: [
        ZestawTransportowyBase[],
        string | VehicleBasic
      ]) =>
        !isNil(transSets) &&
        !TransportHelper.isZestawTransportowyBase(filterValue)
    ),
    map(([transSets, filterValue]: [ZestawTransportowyBase[], string]) =>
      [...transSets]
        .filter(
          (transSet) =>
            TransportHelper.isValidTransset(transSet) &&
            this.filterTransSet(transSet, filterValue)
        )
        .sort((a, b) => (a.id > b.id ? -1 : 1))
    )
  );

  trailerDetailsText: (
    transSet: ZestawTransportowyBase | null | undefined
  ) => string = (transSet) => {
    if (!isNil(transSet) && !isNil(transSet.trailerSet)) {
      const trailer = VehicleFromTransitPipe.transform(transSet.trailerSet);
      return TransportHelper.getVehicleDescription(trailer);
    }

    return '';
  };

  constructor(private store: Store<fromRoot.State>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.transSetValue) {
      this.initTransportSetFilter(changes.transSetValue.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  selectSet(selectEvent: MatAutocompleteSelectedEvent) {
    this.store.dispatch(
      TRANSPORT.showTransportSetAsChosenNew({
        zestaw: selectEvent.option.value,
      })
    );
  }

  getVehicleInfo(element: VehicleBasic) {
    if (isNil(element)) {
      return 'Not assigned';
    }

    return element.model.marka.marka + ': ' + element.nrRej ?? 'No data';
  }

  private initTransportSetFilter(
    transSetValue: ZestawTransportowyBase | null | undefined
  ): void {
    this.filterSets.setValue(transSetValue);
  }

  private filterTransSet(
    transSet: ZestawTransportowyBase,
    filterValue: string
  ): boolean {
    const { model, nrRej, idPojazduFirmy } = TransportHelper.getBasicVehicle(
      transSet.trailerSet
    );
    const searchCriteria = [
      model.marka.marka,
      model.model,
      nrRej,
      idPojazduFirmy,
      transSet.uwagi,
    ].map((criteria) => (criteria || '').toString().toLowerCase());
    const filter = filterValue.trim().toLowerCase();

    return filter
      ? searchCriteria.some((criteria) => criteria.includes(filter))
      : true;
  }
}
