import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import * as fromRoot from '../../../app.reducer';
import * as USER from '../../../ngrx/user.actions';
import { Uprawnienie, UprawnienieUzytkownika } from '../../../models/authentication';
import { Uzytkownik } from '../../../models/dto/user';
import { ActivatedRoute } from '@angular/router';
import { Strings } from '../../../helpers';

@Component({
  selector: 'app-user-privileges',
  templateUrl: './user-privileges.component.html',
  styleUrls: ['./user-privileges.component.scss']
})
export class UserPrivilegesComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  privs: Uprawnienie[] = [];
  filtered: Uprawnienie[] = [];
  maxNum = 10;

  userPrivs: UprawnienieUzytkownika[] = [];
  searchControl = new FormControl('');

  uzytkownik: Uzytkownik;
  selectedUserName = '';

  constructor(private store: Store<fromRoot.State>, private route: ActivatedRoute) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(res => {
      this.selectedUserName = res.get('username');
    });
    this.subs.add(
      this.store.select(fromRoot.selectors.user.getCompanyUsers)
        .pipe(
          map(users => users.find(u => u.username === this.selectedUserName)),
          filter(user => user !== undefined && user !== null)
        )
        .subscribe(user => this.uzytkownik = user)
    );

    this.subs.add(
      this.searchControl.valueChanges.subscribe((search: string | null) => {
        if (search && typeof search === 'string') {
          this.filtered = this._filter(search);
          return;
        }
        this.filtered = this.privs.slice();
      })
    );

    let pHash = '';
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.admin.getPrivileges),
        this.store.select(fromRoot.selectors.user.getUserPrivilegesByUserId(this.uzytkownik?.id))
      ])
        .pipe(
          filter(res =>
            res[0] && res[0].length > 0 && pHash !== Strings.getObjectHash(res)
          ),
          debounceTime(350),
          tap(r => pHash = Strings.getObjectHash(r))
        )
        .subscribe(([privs, userPrivs]) => {
          this.userPrivs = userPrivs;

          this.privs = privs.filter(priv => !userPrivs.find(u => u._upr.id === priv.id));
          this.filtered = this.privs;
        })
    );
  }

  addPrivToUser(el: Uprawnienie) {
    this.store.dispatch(new USER.AddUserPrivilegeRequest({_uzytkownik: this.uzytkownik, _upr: el, _dost_mod: '/api/dostepne_moduly/11'}));
  }

  removeProvFromUser(el: UprawnienieUzytkownika) {
    this.store.dispatch(new USER.RemoveUserPrivilegeRequest(el));
  }

  private _filter(search: string) {
    return this.privs.slice().filter(res => res.nazwa.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
  }

}
