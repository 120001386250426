import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';

import * as UI from '../../../ngrx/ui.actions';
import * as fromRoot from '../../../app.reducer';

import { PortalSlotService } from '../services';

@Directive({
  selector: '[appSlotAttachTo]'
})
export class SlotAttachToDirective implements OnInit, OnDestroy {
  @Input('appSlotAttachTo') attachTo = '';
  @Input() clearOnDestroy = true;

  constructor(private slotService: PortalSlotService, private template: TemplateRef<unknown>, private store: Store<fromRoot.State>) {
  }

  ngOnDestroy(): void {
    if (this.clearOnDestroy) {
      this.store.dispatch(UI.switchRightDrawer({isOpened: false}));
      this.slotService.clear(this.attachTo);
    }
  }

  ngOnInit(): void {
    this.slotService.attach(this.attachTo, this.template);
  }
}
