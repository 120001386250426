
<div class="truck-filters">
  <mat-form-field appearance="outline" class="w-100 mt-1">
    <mat-label>Select truck</mat-label>
    <input
      [formControl]="filterTrucks"
      [matAutocomplete]="trucksAutocomplete"
      placeholder="Filter trucks"
      type="text"
      class="mat-body-2"
      matInput />

    <mat-icon
      *ngIf="isDefaultSelected"
      class="default-truck-selected"
      matPrefix
      matTooltip="Selected truck is connected with the trailer (recommended)">
      local_shipping
    </mat-icon>

    <mat-autocomplete
      #trucksAutocomplete="matAutocomplete"
      [displayWith]="truckDetailsText"
      class="trucks-list"
      (optionSelected)="selectTruck($event)">
      <mat-option
        *ngIf="defaultTruck"
        [value]="defaultTruck"
        class="trucks-list-option default-set-truck">
        <ng-container *ngTemplateOutlet="truckOption; context {$implicit: defaultTruck, isDefaultSetTruck: true}"></ng-container>
      </mat-option>
      <mat-option
        *ngFor="let element of filteredTrucks$ | async"
        [value]="element"
        class="trucks-list-option">
        <ng-container *ngTemplateOutlet="truckOption; context: {$implicit: element, isDefaultSetTruck: false}"></ng-container>
      </mat-option>
    </mat-autocomplete>

    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<ng-template #truckOption let-element let-isDefaultTruck="isDefaultSetTruck">
  <div class="trucks-list-option-wrapper">
    <p
      *ngIf="isDefaultTruck"
      class="trucks-list-option-wrapper-truck-default">
      <b>Recommended truck (associated with trailer)</b>
    </p>

    <div class="trucks-list-option-wrapper-truck-info">
      <p class="trucks-list-option-wrapper-truck-info-id">
        Id: {{ element?.id }}
      </p>

      <terminal-veh-reg-number
        [vehType]="vehicleType.BRAND">
        {{element.model?.marka?.marka || 'No data' }}
      </terminal-veh-reg-number>

      <terminal-veh-reg-number
        [vehType]="vehicleType.PLATE">
        {{element.nrRej || 'No data' }}
      </terminal-veh-reg-number>

      <terminal-veh-reg-number
        [vehType]="vehicleType.VEHICLE_ID">
        {{element.idPojazduFirmy || 'No data' }}
      </terminal-veh-reg-number>
    </div>

    <p class="trucks-list-option-wrapper-truck-remarks">Remarks: <i>{{ element?.uwagi }}</i></p>
  </div>
</ng-template>
