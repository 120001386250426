import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import * as fromRoot from '../../../../../app.reducer';
import { debounceTime, filter, map, switchMap, take } from 'rxjs/operators';
import { RightDrawer } from '../../../../../models/dto';
import { Karta, KartaTerminal, Terminal } from '../../../../../models/dto/terminale';
import { TerminalSimCardForm } from '../../../../../models/form/terminal-sim-card-form';
import * as TERMINAL from '../../../../../ngrx/terminal.actions';
import { AssignSimCardToTerminal, UnassignSimCardFromTerminal } from '../../../../../ngrx/terminal.actions';
import { BaseYesNoConfig } from '../../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../../shared/dialogs';
import { Strings } from '../../../../../helpers';

@Component({
  selector: 'app-terminal-sim-card-form',
  templateUrl: './terminal-sim-card-form.component.html',
  styleUrls: ['./terminal-sim-card-form.component.scss']
})
export class TerminalSimCardFormComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TerminalSimCardFormComponent');

  terminal$: Observable<Terminal>;
  form = new TerminalSimCardForm();
  filteredSimCards$: Observable<Karta[]>;
  currentTerminalActiveSimCard$: Observable<KartaTerminal>;
  private subscription: Subscription;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog) {
    this.store.dispatch(new TERMINAL.LoadSimCardsRequest());
    this.terminal$ = this.store.select(fromRoot.selectors.ui.getRightDrawerInfo).pipe(
      filter((rightDrawer: RightDrawer) => rightDrawer.componentName === TerminalSimCardFormComponent.myname && rightDrawer.isOpened),
      map((rightDrawer: RightDrawer) => rightDrawer.componentData as Terminal)
    );
    this.currentTerminalActiveSimCard$ = this.terminal$.pipe(
      switchMap((terminal: Terminal) => {
        return this.store.select(fromRoot.selectors.devices.getTerminalSimCardsByTerminalId(terminal.id)).pipe(
          map((cards: KartaTerminal[]) => cards
            .find((card: KartaTerminal) => card.czas_usuniecia === null)
          ),
          take(1)
        );
      }),
    );
    this.filteredSimCards$ = this.form.simCardValueChanges().pipe(
      debounceTime(300),
      switchMap((value) => {
        return this.store.select(fromRoot.selectors.devices.getSimCards).pipe(
          map((simCards) => {
            if (typeof value !== 'string') {
              return simCards;
            }
            return simCards.filter((simCard) => simCard.imsi.includes(value) ||
              simCard.ccid.includes(value) ||
              simCard.notatki.includes(value));
          })
        );
      })
    );
  }

  displaySimCard(simCard: Karta): string {
    return simCard ? `CCID: ${simCard.ccid}, IMSI: ${simCard.imsi}` : '';
  }

  ngOnInit(): void {
    this.subscription = this.currentTerminalActiveSimCard$.subscribe((card: KartaTerminal | null) => {
      if (card) {
        this.form.patchFromModel(card);
        return;
      }
      this.form.reset();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(terminal: Terminal): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.store.dispatch(new AssignSimCardToTerminal(
        {
          terminalSimCard: {...this.form.getUpdatedModel(), _terminal: terminal}
        })
      );
    }
  }

  timeChange(time: string): void {
    this.form.changeInstallmentTime(time);
  }

  removeSimCardFromTerminal(terminalSimCard: KartaTerminal): void {
    const config: BaseYesNoConfig = {
      title: 'Unassign a SIM card from the terminal',
      content: 'Are you sure you want to unassign this sim card?',
      autoClosure: true,
      yesAction: () => {
        this.store.dispatch(new UnassignSimCardFromTerminal({terminalSimCard}));
      }
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-tokenExpirationAlert',
      position: {top: '7%'}
    });
  }
}
