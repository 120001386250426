<div class="content">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mt-3">
    <button
      mat-raised-button
      color="primary"
      routerLink="create"
      routerLinkActive="active"
      title="Add new driver"
    >
      Add new driver
    </button>

    <button *ngIf="shouldDisplayBackButton" mat-raised-button routerLink="list" routerLinkActive="active">
      Back to drivers list
    </button>
  </div>
  <hr />
  <router-outlet></router-outlet>
</div>
