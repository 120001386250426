import { VehiclesComponent } from './vehicles.component';
import { TruckComponent } from './truck/truck.component';
import { TruckListComponent } from './truck/truck-list/truck-list.component';
import { TrailComponent } from './trail/trail.component';
import { TrailListComponent } from './trail/trail-list/trailer-list.component';
import { BaseTypeComponent } from './base-type/base-type.component';
import { MenuComponent } from './menu/menu.component';
import { IsAuthenticatedGuard } from '../../guards';

export const vehicleRoutes = {
  path: 'vehicles', component: VehiclesComponent, data: {breadcrumb: 'Vehicles'}, children: [
    {path: '', redirectTo: 'menu', data: {breadcrumb: null}, pathMatch: 'full'},
    {
      path: 'menu', component: MenuComponent, data: {breadcrumb: 'Menu'}, children: [
        {
          path: 'truck', component: TruckComponent, data: {breadcrumb: 'Truck'}, children: [
            {path: '', redirectTo: 'list', data: {breadcrumb: null}},
            {path: 'list', component: TruckListComponent, data: {breadcrumb: 'Truck list'}},
            {path: ':plates', component: TruckListComponent, data: {breadcrumb: 'Details'}},
          ]
        },
        {
          path: 'trail', component: TrailComponent, data: {breadcrumb: 'Trail'}, children: [
            {path: '', redirectTo: 'list'},
            {path: 'list', component: TrailListComponent, data: {breadcrumb: 'Trailer list'}},
            {path: ':id', component: TrailListComponent, data: {breadcrumb: 'Details'}},
          ]
        },
        {path: 'base-type', component: BaseTypeComponent, data: {breadcrumb: 'Dictionary'}}
      ]
    },
  ], canActivate: [IsAuthenticatedGuard]
};
