<div class="r-drawer-container" fxLayout="column" fxLayoutGap="16px" *ngIf="form">
  <p mat-subheading-1>Details: {{form.getUpdatedModel()?.nr_rej}}</p>
  <form fxLayout="column" fxLayoutGap="10px" [formGroup]="form" (submit)="onSubmit()">
    <div class="row-items">
      <app-input name="company_vehicle" placeholder="Company vehicle ID" label="Company vehicle ID" id="company_vehicle"
                 [control]="form.getFormControl('id_pojazdu_firmy')"></app-input>

      <app-input name="nr_rej" placeholder="Licence plate" label="Licence plate" id="nr_rej"
                 [control]="form.getFormControl('nr_rej')"></app-input>
    </div>

    <app-select placeholder="Brand" label="Brand" id="brand" (selected)="vendorSelected($event)"
                [control]="form.getFormControl('marka')" [lov]="vehicleVendorsLov"></app-select>

    <app-select placeholder="Model" label="Model" id="model"
                [control]="form.getFormControl('model')" [lov]="vehicleFilteredModelsLov"></app-select>

    <app-select placeholder="Connection type" label="Connection type" id="rodzaj_zapiecia"
                [control]="form.getFormControl('rodzaj_zapiecia')" [lov]="connectTypeListLov"></app-select>

    <app-input name="vin" placeholder="VIN" label="VIN" id="vin"
               [control]="form.getFormControl('vin')"></app-input>

    <div fxFlex fxLayout="row" fxLayoutGap="5px">
      <app-select placeholder="Work status" label="Work status" id="stan_pracy"
                  [control]="form.getFormControl('stan_pracy')" [lov]="trailerWorkStateLov"></app-select>

      <app-input name="dop_ladownosc" placeholder="Capacity" label="Capacity"
                 [control]="form.getFormControl('dop_ladownosc')" type="number"></app-input>
    </div>

    <div fxFlex fxLayout="row" fxLayoutGap="5px">
      <app-select placeholder="Trailer type" label="Trailer type" id="typ_naczepy"
                  [control]="form.getFormControl('typ')" [lov]="vehicleTypesLov"></app-select>

      <app-select placeholder="Status" label="Status" id="status_pojazdu"
                  [control]="form.getFormControl('status_pojazdu')" [lov]="vehicleStateLov"></app-select>
    </div>

    <app-textarea name="uwagi" placeholder="Remarks" label="Remarks"
                  [control]="form.getFormControl('uwagi')"></app-textarea>

    <mat-radio-group aria-label="Select" formControlName="czy_naczepa" id="czy_naczepa">
      <mat-radio-button [value]="true">Semi-trailer</mat-radio-button>
      <br/>
      <mat-radio-button [value]="false">Trailer</mat-radio-button>
    </mat-radio-group>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Show safety equipment
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="eq-items">
        <ng-container *ngIf="(terminalEq$ | async) as equipment; else noTerminalEqTpl">
          <div *ngFor="let eqItem of equipment">
            - {{eqItem.wyposazenieNazwa}}
          </div>
        </ng-container>
        <ng-template #noTerminalEqTpl>
          No equipment
        </ng-template>
      </div>
    </mat-expansion-panel>

    <button mat-raised-button type="submit" [disabled]="form.invalid" color="primary">Save</button>
  </form>
</div>
