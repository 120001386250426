import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-details',
  templateUrl: './set-details.component.html',
  styleUrls: ['./set-details.component.scss']
})
export class SetDetailsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
