import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ZestawTransportowyBase } from '../../../../../models/dto/transportSets';
import { VehicleBasic } from 'src/app/models/dto/vehicle';

@Component({
  selector: 'app-truck-and-trailer',
  templateUrl: './truck-and-trailer.component.html',
  styleUrls: ['./truck-and-trailer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruckAndTrailerComponent {
  @Input() trailer: ZestawTransportowyBase;
  @Input() truck: VehicleBasic;
}
