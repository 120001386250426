<div class="table-container mat-elevation-z8 white-bar">
  <table mat-table [dataSource]="transport_sets" matSort class="mat-elevation-z8" matSortActive="czas_utw" matSortDirection="desc">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
<!--  todo: trzeba w API dodać możliwość edycji tak jak jest przy tworzeniu z użyciem 1Shot
      <td mat-cell *matCellDef="let element"> <button mat-raised-button (click)="edit(element.id)"1>{{element.id}}</button> </td>-->
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="czas_utw">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td mat-cell *matCellDef="let element">
        <span [matTooltip]="element.czasUtworz | date : df.PIPE_DATETIME_LONGER_LABEL">
          {{element.czasUtworz | date : df.PIPE_DATE_LABEL}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="ost_kom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last report</th>
      <td mat-cell *matCellDef="let element">
        <span>
          {{element.czasKom | date : df.PIPE_DATETIME_LONGER_LABEL}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="flagi">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active / Vacant</th>
      <td mat-cell *matCellDef="let element">
        Active: {{element.czyAktywny | yesNo}}, Vacant: {{element.czyWolny | yesNo}}
      </td>
    </ng-container>
    <ng-container matColumnDef="sklad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Transport set</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.truckSet | vehicleFromTransit">
          {{(element.truckSet | vehicleFromTransit).nrRej | uppercase}},
          {{(element.truckSet | vehicleFromTransit).model.marka.marka}},
          {{(element.truckSet | vehicleFromTransit).model.model}}
        </span>
        <br/>
        <span *ngIf="element.trailerSet | vehicleFromTransit">
          {{(element.trailerSet | vehicleFromTransit).nrRej | uppercase}},
          {{(element.trailerSet | vehicleFromTransit).model.marka.marka}},
          {{(element.trailerSet | vehicleFromTransit).model.model}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="kierowcy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Drivers / Tel. no.</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.domKier1">
          {{element.domKier1.uzytkownik.imie}}
          {{element.domKier1.uzytkownik.nazwisko}},
          <i>{{element.domKier1.uzytkownik.tel1 || 'No information'}}</i>
        </span>
        <br/>
        <span *ngIf="element.domKier2">
          {{element.domKier2.uzytkownik.imie}}
          {{element.domKier2.uzytkownik.nazwisko}},
          <i>{{element.domKier2.uzytkownik.tel1 || 'No information.'}}</i>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="uwagi">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Remarks</th>
      <td mat-cell *matCellDef="let element">{{element.uwagi}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></tr>
  </table>
  <mat-paginator appPaginationPageSize
                 [length]="transport_sets.data.length"
                 showFirstLastButtons>
  </mat-paginator>
</div>
