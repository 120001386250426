import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import moment from 'moment';

import * as fromRoot from '../../../../app.reducer';
import * as TERMINAL from '../../../../ngrx/terminal.actions';
import * as FRAME from '../../../../ngrx/frame.actions';
import * as UI from '../../../../ngrx/ui.actions';

import {
  TerminalAssignComponent,
  TerminalCreateComponent,
  TerminalLockerMngDialogComponent,
  UpgradeTerminalComponent
} from '../../dialogs';

import { SamochodTerminal, Terminal, TerminalNaczepa, TerminalVehicleType } from '../../../../models/dto/terminale';
import { ConfirmationDialogComponent } from '../../../../components';
import { RightDrawerService } from '../../../shared/services';
import { TerminalSimCardFormComponent } from './terminal-sim-card-form/terminal-sim-card-form.component';
import { IInfo } from '../../../../models/frame';
import { DateFormat, Privs } from '../../../../helpers/enum';
import { LockerHelper } from '../../../shared/helpers';
import { HasPrivilegesDirective } from '../../../access-control/directives/by-privilege/has-privileges.directive';
import { General, TerminalMsg } from '../../../../messages';
import { TerminalTableColumnsEnum } from '../../enums';

interface LockStatus {
  tooltip?: string;
  icon?: string;
  class: string;
}

@Component({
  selector: 'app-terminal-list',
  templateUrl: './terminal-list.component.html',
  styleUrls: ['./terminal-list.component.scss']
})
export class TerminalListComponent implements OnInit, OnDestroy {
  TerminalTableColumns = TerminalTableColumnsEnum;

  displayedColumns: string[] = Object.values(this.TerminalTableColumns);
  terminalList = new MatTableDataSource<Terminal>([]);
  terminalTrail$: Observable<TerminalNaczepa[]>;
  terminalTruck$: Observable<SamochodTerminal[]>;
  searchBoxControl = new FormControl('');
  df = DateFormat;
  ePrivs = Privs;
  vehicleType: typeof TerminalVehicleType = TerminalVehicleType;

  subs = new Subscription();
  terminalInterval: Subscription = undefined;
  showAll = false;
  fullList: Terminal[] = [];
  lastInfo: IInfo[] = [];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private toasrt: ToastrService,
    private rightDrawerService: RightDrawerService
  ) {
    this.store.dispatch(new TERMINAL.LoadTerminalsSimCardsRequest());
    this.store.dispatch(new TERMINAL.LoadTerminalsTrailsRequest());
    this.store.dispatch(new TERMINAL.LoadTerminalsTrucksRequest());
  }

  ngOnInit() {
    this.initTable();

    combineLatest([
      this.store.select(fromRoot.selectors.devices.getTerminals),
      this.store.select(fromRoot.selectors.frame.getInfo)
    ])
      .pipe(filter(r => r && r[0].length > 0))
      .subscribe(([terminalList, info]) => {
        this.fullList = [...terminalList].sort((a, b) => a.id > b.id ? -1 : 1);
        this.lastInfo = info;
        let list = this.fullList;
        if (!this.showAll) {
          list = this.fullList.filter(t => t.czy_aktywny && t._status.id !== 4);
        }
        this.terminalList.data = list;
      });
    setTimeout(() => this.switchInterval(false, 'ngOnInit()'), 2 * 1000);

    this.terminalTruck$ = this.store.select(fromRoot.selectors.devices.getTerminalTruck);
    this.terminalTrail$ = this.store.select(fromRoot.selectors.devices.getTerminalTrail);
  }

  initTable() {
    this.sort.start = 'desc';

    const sortState: Sort = {active: 'czas_ost_kom', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.terminalList.sort = this.sort;
    this.searchBoxControl.valueChanges
      .pipe(
        debounceTime(200),
        startWith('')
      )
      .subscribe(str => {
        this.terminalList.filter = str;
      });

    this.terminalList.sortingDataAccessor = (data: object, sortHeaderId: string): string | number => {
      if (sortHeaderId === 'czas_ost_kom') {
        const d = moment().diff(moment(data[sortHeaderId]), 'seconds');
        return d < 60 ? 1 : -1 * Math.round(d / 60);
      }
      const propPath = sortHeaderId.split('.');
      const value: any = propPath
        .reduce((curObj, property) => curObj[property], data);
      return !isNaN(value) ? Number(value) : value;
    };

    this.terminalList.filterPredicate = (data: Terminal, filterString: string) => {
      const str = (data.id + data.uwagi + data._status.status + data.nr_seryjny + data._opr.oznaczenie).trim().toLowerCase();
      return !filter || str.indexOf(filterString.trim().toLowerCase()) >= 0;
    };
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.switchInterval(true);
  }

  addNewTerminal() {
    this.switchInterval(true);
    this.dialog.open(TerminalCreateComponent, {minWidth: '450px'}).afterClosed()
      .subscribe(() => this.switchInterval(false, 'addNewTerminal()'));
  }

  assignTerminal() {
    this.switchInterval(true);
    this.dialog.open(TerminalAssignComponent).afterClosed()
      .subscribe(() => this.switchInterval(false, 'assignTerminal()'));
  }

  updateSoftware(terminal: Terminal) {
    this.switchInterval(true);
    this.dialog.open(UpgradeTerminalComponent, {data: terminal.id}).afterClosed()
      .subscribe(() => this.switchInterval(false, 'updateSoftware()'));
  }

  edit(e: Terminal) {
    this.switchInterval(true);
    this.dialog.open(TerminalCreateComponent, {data: e, minWidth: '450px'}).afterClosed()
      .subscribe(() => this.switchInterval(false, 'edit()'));
  }

  manageLocker(e: Terminal) {
    this.switchInterval(true);
    this.dialog.open(TerminalLockerMngDialogComponent, {data: {terminal: e}, minWidth: '450px'}).afterClosed()
      .subscribe(() => this.switchInterval(false, 'manageLocker()'));
  }

  wycofane(_: MatCheckboxChange) {
    let list = this.fullList;
    if (!this.showAll) {
      list = this.fullList.filter(t => t.czy_aktywny && t._status.id !== 4);
    }
    this.terminalList.data = list;
  }

  lockStatus(row: Terminal): LockStatus {
    return LockerHelper.getLockerStatus(row.rygiels[0]);
  }

  simStatus(row: Terminal) {
    if (row.kartas.length < 1) {
      return 'no-sim';
    }

    return 'sim-ok';
  }

  requestLockerChange(row: Terminal) {
    if (!HasPrivilegesDirective.check([Privs.MNG_TRANSSET_SAFETY])) {
      this.store.dispatch(UI.userError({message: 'You are not allowed to perform this action.'}));
      return;
    }

    switch (this.lockStatus(row).class) {
      case 'unsafe': {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          maxWidth: '400px',
          data: {title: 'Closing locker', message: `<b>${row.uwagi}</b><br />Are you sure you want to proceed?`}
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            this.store.dispatch(new TERMINAL.LockerCloseRequest({terminalId: row.id}));
          }
        });
        break;
      }
      case 'safe': {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          maxWidth: '400px',
          data: {title: 'Opening locker', message: `<b>${row.uwagi}</b><br />Are you sure you want to proceed?`}
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            this.store.dispatch(new TERMINAL.LockerOpenRequest({terminalId: row.id}));
          }
        });
        break;
      }
      case 'missing': {
        this.toasrt.warning('Bolt not defined', 'Bolt status');
        break;
      }
      case 'closing':
      case 'opening':
      case 'undefined': {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          maxWidth: '400px',
          data: {title: 'Request bolt status', message: `<b>${row.uwagi}</b><br />Do you want to proceed?`}
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            this.store.dispatch(new TERMINAL.LockerStatusRequest({
              terminalId: row.id,
              successCallback: () => {
                setTimeout(() => this.toasrt.warning(TerminalMsg.BOLT_REFRESH, 'Bolt status'), 12 * 1000);
              }
            }));
          }
        });
        break;
      }

    }
  }

  trackByFn(index, item: Terminal) {
    return JSON.stringify(item);
  }

  showSimCardForm(terminal: Terminal): void {
    if (!HasPrivilegesDirective.check([Privs.EDIT_TERMINAL])) {
      this.store.dispatch(UI.userError({message: General.NOT_ALLOWED}));
      return;
    }
    this.rightDrawerService.openOver(TerminalSimCardFormComponent, terminal);
  }

  private switchInterval(turnOff = true, _where = '') {
    if (!turnOff) {
      if (this.terminalInterval === undefined) {
        this.terminalInterval = interval(5 * 1000).subscribe(() => {
          this.store.dispatch(new TERMINAL.LoadTerminalsRequest());
          this.store.dispatch(FRAME.infoRequest());
        });
      }
      return;
    }

    if (this.terminalInterval !== undefined && !this.terminalInterval.closed) {
      this.terminalInterval.unsubscribe();
      this.terminalInterval = undefined;
    }
  }
}
