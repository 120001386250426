import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as fromRoot from '../../../../app.reducer';
import * as fromDriver from '../../ngrx/driver.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import * as DRIVER from '../../ngrx/driver.actions';

import { KierowcaNowy } from '../../interfaces';
import { DriverStatusDetails, ToastType } from '../../../../helpers/enum';
import { DriverForm } from '../../../../models/form/driver-form';
import { BaseYesNoConfig } from '../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../shared/dialogs';

@Component({
  selector: 'app-driver-create',
  templateUrl: './driver-create.component.html',
  styleUrls: ['./driver-create.component.scss'],
})
export class DriverCreateComponent implements OnInit, OnDestroy {
  @Input() idKierowcy: number;
  public isRequiredError = false;
  driverStatus = Object.values(DriverStatusDetails).filter(k => isNaN(Number(k)));

  title = 'Adding new driver';
  subs = new Subscription();
  saving = false;

  public myForm = new DriverForm();

  constructor(private store: Store<fromRoot.State>, private readonly router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (this.idKierowcy) {
      this.title = 'Data edition';
      this.subs.add(
        this.store.select(fromDriver.getDrivers)
          .pipe(
            filter(r => r !== null && r.length > 0),
            map(r => r.find(k => k.id === this.idKierowcy))
          )
          .subscribe(d => {
            this.myForm.reset();
            this.myForm.patchFromModel({
              imie: d._uzytkownik.imie,
              nazwisko: d._uzytkownik.nazwisko,
              data_ur: d.data_ur,
              data_zatr: d._uzytkownik.data_zatr,
              panstwo: d._uzytkownik.panstwo,
              potwierdz: d.potwierdz,
              kod: d._uzytkownik.kod,
              miasto: d._uzytkownik.miasto,
              ulica: d._uzytkownik.ul,
              nr_d: d._uzytkownik.nr_d,
              nr_l: d._uzytkownik.nr_l,
              nr_karty: d.nr_karty,
              tel1: d._uzytkownik.tel1,
              tel2: d._uzytkownik.tel2,
              status: d.status
            });
            this.myForm.markAllAsTouched();
            if (this.myForm.invalid) {
              this.isRequiredError = true;
            }
          })
      );
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit() {
    if (this.myForm.invalid) {
      this.myForm.markAllAsTouched();
      this.isRequiredError = true;
      this.store.dispatch(
        UI.showUserMessage({
          message: {
            type: ToastType.ERROR,
            message: 'Check distinguished fields',
            title: 'Form error'
          }
        })
      );
      return false;
    }

    this.saving = true;

    const kierowca = this.myForm.value as KierowcaNowy;
    if (this.idKierowcy > 0) {
      this.store.dispatch(new DRIVER.UpdDriverRequest({
        kierowca: {...kierowca, id: this.idKierowcy},
        callback: () => {
          this.store.dispatch(UI.showUserMessage({
            message: {
              type: ToastType.SUCCESS,
              message: 'Driver updated.'
            }
          }));
          this.store.dispatch(UI.showUserMessage({
            message: {
              type: ToastType.SUCCESS,
              message: 'Driver updated.'
            }
          }));
          this.store.dispatch(new DRIVER.LoadDriversRequest());
        }
      }));
      this.saving = false;
      return true;
    }

    const o = Object.keys(kierowca)
      .filter((k) => !!kierowca[k])
      .reduce((a, k) => ({...a, [k]: kierowca[k]}), {});
    this.store.dispatch(new DRIVER.NewDriversRequest({
      kierowca: o,
      callback: (driverId) => {
        this.store.dispatch(UI.showUserMessage({
          message: {
            type: ToastType.SUCCESS,
            message: 'Driver has been created.'
          }
        }));

        setTimeout(() => {
          this.router.navigate(['dictionaries', 'driver', 'edit', driverId]);
        }, 500);
      }
    }));

    this.saving = false;
  }

  public deleteDriver(): void {
    const config: BaseYesNoConfig = {
      title: 'Driver deletion',
      content: `Are you sure you want to delete driver '${this.myForm.value.imie} ${this.myForm.value.nazwisko}'?`,
      yesAction: () => this.store.dispatch(new DRIVER.DeleteDriverRequest({id: this.idKierowcy})),
      yesLabel: 'Delete',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-DriverRemoval',
      position: {top: '7%'}
    });
  }
}
