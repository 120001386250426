import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { filter, map } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import { SoftwareFrameDialogComponent, TerminalSoftwareDialogComponent } from '../../dialogs';
import { OprogramowanieTerminala } from '../../../../models/dto/terminale';
import { DateFormat } from '../../../../helpers/enum';
import * as TERMINAL from '../../../../ngrx/terminal.actions';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.scss']
})
export class SoftwareComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  softwareListInfo: MatTableDataSource<OprogramowanieTerminala>;
  allSoftware: OprogramowanieTerminala[] = [];
  displayedColumns: string[] = ['id', 'oznaczenie', 'data_wydania', 'opis', 'wersjaRamkis', 'opis_zmian', 'akcje'];
  pokazWycofane = false;
  df = DateFormat;

  subs = new Subscription();

  constructor(
    private store: Store<fromRoot.State>, private dialog: MatDialog
  ) {
    this.store.dispatch(new TERMINAL.LoadTerminalSoftwareListRequest());
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.devices.getSoftwareList)
      .pipe(
        filter(r => r !== undefined),
        map(r => [...r].sort((a, b) => a.id < b.id ? 1 : -1))
      )
      .subscribe(softwareList => {
        this.softwareListInfo = new MatTableDataSource(softwareList);
        this.allSoftware = softwareList;
        let filtered = this.allSoftware;
        if (!this.pokazWycofane) {
          filtered = this.allSoftware.filter(s => !s.wycofane);
        }
        this.softwareListInfo.data = filtered;
        this.softwareListInfo.sortingDataAccessor = (item: OprogramowanieTerminala, property) => {
          switch (property) {
            case 'id':
              return item.id;
            case 'oznaczenie':
              return item.oznaczenie;
            case 'data_wydania':
              return item.data_wydania;
            case 'opis':
              return item.opis;
            case 'wersjaRamkis':
              return item.wersjaRamkiOprs;
            case 'opis_zmian':
              return item.opis_zmian;
            default:
              return item[property];
          }
        };
        this.softwareListInfo.sort = this.sort;
        this.softwareListInfo.paginator = this.paginator;
      })
    );
  }

  ngAfterViewInit(): void {
    this.softwareListInfo.sort = this.sort;
  }

  addNewSoftware() {
    this.dialog.open(TerminalSoftwareDialogComponent, {minWidth: '450px'});
    return false;
  }

  edit(soft: OprogramowanieTerminala) {
    this.dialog.open(TerminalSoftwareDialogComponent, {data: soft, minWidth: '450px'});
  }

  frameDetails(id_oprogramowanie: number) {
    this.dialog.open(SoftwareFrameDialogComponent, {data: id_oprogramowanie, minWidth: '500px'});
  }

  wycofane(event: MatCheckboxChange) {
    if (event.checked) {
      this.softwareListInfo.data = this.allSoftware;
      return;
    }
    this.softwareListInfo.data = this.allSoftware.filter(s => !s.wycofane);
  }
}
