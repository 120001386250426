<mat-spinner *ngIf="!terminal"></mat-spinner>
<div fxFlex fxLayout="column" fxLayoutGap="10px" *ngIf="terminal">
  <h3>
    Terminal: {{terminal.id}} <br>
  </h3>
  <span>{{terminal.uwagi}}</span>
  <h4>Select:</h4>
  <mat-form-field>
    <mat-label>Select software</mat-label>
    <mat-select [(ngModel)]="oprogramowanie" name="oprogramowanie">
      <mat-option *ngFor="let soft of oprogramowanieTerminali" [value]="soft" [disabled]="soft.wycofane"
                  [title]="soft.opis">
        {{soft.data_wydania | date:df.PIPE_DATE_LABEL}} - {{soft.oznaczenie}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Frame size</mat-label>
    <mat-select [(ngModel)]="rozmiarPaczki" name="rozmiarPaczki">
      <mat-option *ngFor="let rozmiar of rozmiary" [value]="rozmiar">
        {{rozmiar}}B
      </mat-option>
    </mat-select>
  </mat-form-field>
  <hr/>
  <button mat-raised-button color="primary" (click)="onSubmit()">Upgrade</button>
</div>
