<div class="main">
  <div class="float-right">
    <button mat-icon-button title="Refresh data" (click)="reloadData()">
      <mat-icon aria-label="icon-button icon">refresh</mat-icon>
    </button>
  </div>
  <button mat-icon-button color="primary" (click)="createSet()">
    <mat-icon aria-label="List icon button">playlist_add</mat-icon>
  </button>
  <button mat-icon-button routerLink='list' routerLinkActive="active">
    <mat-icon aria-label="Add item to the list icon button">list</mat-icon>
  </button>
  <hr>
  <router-outlet></router-outlet>
</div>
