import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RodzajPojazdu } from 'src/app/models/dto/vehicle';
import { TrucksInventoryService } from 'src/app/services';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import * as Vehicles from '../../../../ngrx/vehicle.actions';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-vehicle-type',
  templateUrl: './vehicle-type.component.html',
  styleUrls: ['./vehicle-type.component.scss']
})
export class VehicleTypeComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  selectedVehicleType: RodzajPojazdu = {rodzaj: '', id: 0};
  buttonContent = 'Add';
  vehicleTypesInfo: MatTableDataSource<RodzajPojazdu>;
  displayedColumns: string[] = ['Id', 'Type', 'Actions'];

  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>, private veh: TrucksInventoryService) {
    this.form = new FormGroup({
      'type': new FormControl('')
    });
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.vehicles.getTruckTypes)
      .subscribe(truckTypes => {
        this.vehicleTypesInfo = new MatTableDataSource(truckTypes);
        this.vehicleTypesInfo.data = truckTypes;
        this.vehicleTypesInfo.sortingDataAccessor = (item: RodzajPojazdu, property) => {
          switch (property) {
            case 'Id':
              return item.id;
            case 'Type':
              return item.rodzaj;
            default:
              return item[property];
          }
        };
        this.vehicleTypesInfo.sort = this.sort;
      })
    );
    this.loadLatestData();
    this.subs.add(
      this.form.valueChanges.subscribe(value => {
        if (value.type === '') {
          this.selectedVehicleType = {rodzaj: '', id: 0};
        }
        this.buttonContent = this.selectedVehicleType.id === 0 ? 'Add' : 'Update';
      })
    );
  }

  private loadLatestData() {
    this.store.dispatch(new Vehicles.LoadTruckTypesRequest());
    this.selectedVehicleType = {rodzaj: '', id: 0};
    this.form.reset();
  }

  removeElement(element: RodzajPojazdu) {
    this.veh.delVehicleType(element).subscribe(() => {
      this.loadLatestData();
    });

  }

  editElement(element: RodzajPojazdu) {
    this.selectedVehicleType = element;
    this.form.get('type').setValue(this.selectedVehicleType.rodzaj);
  }

  saveItem() {
    if (this.selectedVehicleType.id < 1) {
      const newVehicleType = {id: null, rodzaj: this.form.get('type').value};
      this.veh.addVehicleType(newVehicleType)
        .subscribe(() => {
          this.loadLatestData();
        });
    } else {
      const updatedVehicleType = {id: this.selectedVehicleType.id, rodzaj: this.form.get('type').value};
      this.veh.updVehicleType(updatedVehicleType)
        .subscribe(() => {
          this.loadLatestData();
        });
    }
    this.selectedVehicleType = {rodzaj: '', id: 0};

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
