import { FormControl, Validators } from '@angular/forms';
import { DefaultForm } from '../default-form';
import { Naczepa } from '../../dto/vehicle';

export class TrailForm extends DefaultForm<Naczepa> {
  constructor(naczepa?: Naczepa | null) {
    super({
      nr_rej: new FormControl(naczepa?.nr_rej,
        Validators.compose([Validators.required, Validators.minLength(6)])
      ),
      id_pojazdu_firmy: new FormControl(naczepa?.id_pojazdu_firmy),
      marka: new FormControl(naczepa?._model?._marka, Validators.required),
      model: new FormControl({value: naczepa?._model, disabled: false},
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      rodzaj_zapiecia: new FormControl(naczepa?._typ_zapiecia, Validators.required),
      status_pojazdu: new FormControl(naczepa?._status, Validators.required),
      vin: new FormControl('', [Validators.minLength(17)]),
      stan_pracy: new FormControl(naczepa?._stan_pracy, Validators.required),
      dop_ladownosc: new FormControl(naczepa?.ladownosc ?? 20,
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      typ: new FormControl(naczepa?._typ?.id || 2, Validators.required),
      czy_naczepa: new FormControl(naczepa?.czy_naczepa || true),
      uwagi: new FormControl(naczepa?.uwagi || ''),
    });
  }

  patchFromModel(data: Naczepa): void {
    this.model = {...data};
    this.patchValue({
      nr_rej: data.nr_rej,
      id_pojazdu_firmy: data.id_pojazdu_firmy,
      marka: data?._model?._marka,
      model: data?._model,
      rodzaj_zapiecia: data._typ_zapiecia,
      status_pojazdu: data._status,
      vin: data.vin,
      stan_pracy: data._stan_pracy,
      dop_ladownosc: data.ladownosc,
      typ: data?._typ,
      czy_naczepa: data.czy_naczepa,
      uwagi: data?.uwagi || ''
    });
  }

  getUpdatedModel(): Naczepa {
    return {
      ...this.model,
      ...{
        nr_rej: this.get('nr_rej').value,
        id_pojazdu_firmy: this.get('id_pojazdu_firmy').value,
        _model: this.get('model').value,
        _typ_zapiecia: this.get('rodzaj_zapiecia').value,
        _status: this.get('status_pojazdu').value,
        vin: this.get('vin').value,
        _stan_pracy: this.get('stan_pracy').value,
        ladownosc: parseInt(this.get('dop_ladownosc').value, 10),
        _typ: this.get('typ').value,
        czy_naczepa: this.get('czy_naczepa').value,
        uwagi: this.get('uwagi').value
      }
    };
  }
}
