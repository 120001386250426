import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PoleRamki, PoleRamkiTypDanych, Ramka, WersjaRamki } from '../../../../models/dto/terminale';
import { notNegativeDigitOnly, positiveDigitOnly } from '../../../../helpers/validators/custom.validators';

@Component({
  selector: 'app-frame-field-edit-dialog',
  templateUrl: './frame-field-edit-dialog.component.html',
  styleUrls: ['./frame-field-edit-dialog.component.scss']
})
export class FrameFieldEditDialogComponent implements OnInit {

  subs = new Subscription();
  controlBitNum = new FormControl({value: 0, disabled: false});
  formEdit = this.fb.group({
    bitOd: ['', [Validators.required, notNegativeDigitOnly]],
    bitDo: ['', [Validators.required, positiveDigitOnly]],
    nazwaPola: ['', [Validators.required, Validators.minLength(2)]],
    opis: ['', [Validators.required, Validators.minLength(10)]],
    db: [''],
    funkcjaPrzeksztalcenia: [''],
    typDanych: [null, Validators.required],
  });

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<FrameFieldEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { wersja: WersjaRamki, ramka: Ramka, pole: PoleRamki, typy: PoleRamkiTypDanych[] }) {
  }

  ngOnInit(): void {
    if (this.data.pole) {
      this.controlBitNum.patchValue(this.data.pole.bitDo - this.data.pole.bitOd + 1);
      this.formEdit.patchValue({
        bitOd: this.data.pole.bitOd,
        bitDo: this.data.pole.bitDo,
        nazwaPola: this.data.pole.nazwaPola,
        opis: this.data.pole.opis,
        db: this.data.pole.db,
        funkcjaPrzeksztalcenia: this.data.pole.funkcjaPrzeksztalcenia,
        typDanych: this.data.pole.typDanych
      });
    }

    if (this.data.wersja && this.data.wersja.active) {
      this.formEdit.get('bitOd').disable();
      this.formEdit.get('bitDo').disable();
      this.controlBitNum.disable();
    }

    this.controlBitNum.valueChanges.subscribe(e => {
      const end = parseInt(e, 10) + parseInt(this.formEdit.get('bitOd').value, 10) - 1;
      this.formEdit.patchValue({bitDo: end}, {emitEvent: false});
    });
    this.formEdit.get('bitOd').valueChanges.subscribe(e => {
      const end = parseInt(e, 10) + parseInt(this.controlBitNum.value, 10) - 1;
      this.formEdit.patchValue({bitDo: end}, {emitEvent: false});
    });
    this.formEdit.get('bitDo').valueChanges.subscribe(e => {
      const start = parseInt(e, 10) - parseInt(this.controlBitNum.value, 10) + 1;
      this.formEdit.patchValue({bitOd: start}, {emitEvent: false});
    });
  }

  compareById(o1, o2) {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }

  zapisz() {
    if (this.formEdit.invalid) {
      return false;
    }
    this.dialogRef.close({...this.data.pole, ...this.formEdit.value});
    return false;
  }

  anuluj() {
    this.dialogRef.close(false);
    return false;
  }

  usunPole() {
    this.dialogRef.close(true);
    return false;
  }

}
