import { Component, Input, OnInit } from '@angular/core';
import { Kierowca } from '../../../interfaces';

@Component({
  selector: 'driver-pins',
  template: `
    <div class="driver-pins">
      <mat-form-field class="pin">
        <input
          matInput
          [value]="pins?.pin || '*****'"
          placeholder="Regular tablet PIN"
          readonly
        />
      </mat-form-field>
      <mat-form-field class="pin">
        <input
          matInput
          [value]="pins?.pin_ca || '*****'"
          placeholder="Alarm PIN"
          readonly
        />
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./driver-pins.component.scss'],
})
export class DriverPinsComponent {
  @Input() pins: Pick<Kierowca, 'pin' | 'pin_ca'> | null;
  constructor() {}
}
