<form class="example-container" [formGroup]="terminalForm" (ngSubmit)="onSubmit()">
  <div class="myForm" fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-form-field>
      <input matInput placeholder="Produced at" id="data_produkcji" formControlName="data_produkcji">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="S/N" id="nr_seryjny" formControlName="nr_seryjny">
      <mat-error *ngIf="terminalForm.get('nr_seryjny').touched && terminalForm.get('nr_seryjny').invalid">
        Required
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="softwareUpdateAllowed">
      <mat-select placeholder="Software" [compareWith]="compareById" formControlName="_opr">
        <mat-option *ngFor="let opr of softwareList" [value]="opr" [title]="opr.opis">
          {{opr.oznaczenie}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Status" [compareWith]="compareById" formControlName="_status">
        <mat-option *ngFor="let status of terminalStatuses" [value]="status" [title]="status.opis">
          {{status.status | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="10px">
      <div fxLayout="column">
        <mat-label>Is active?</mat-label>
        <mat-slide-toggle formControlName="czy_aktywny">
          <span [innerHTML]="terminalForm.get('czy_aktywny').value ? 'Active' : 'Withdrawn'"></span>
        </mat-slide-toggle>
      </div>
      <div fxLayout="column">
        <mat-label>Test mode?</mat-label>
        <mat-slide-toggle formControlName="tryb_testowy">
          <span>{{terminalForm.get('tryb_testowy').value | yesNo}}</span>
        </mat-slide-toggle>
      </div>
    </div>

    <mat-form-field>
      <textarea matInput placeholder="Notes" id="uwagi" formControlName="uwagi"></textarea>
    </mat-form-field>

    <div fxFlex fxLayout="row" fxLayoutGap="10px">
      <button mat-raised-button color="accent" type="submit"
              [disabled]="terminalForm.invalid || (!terminalForm.touched && !terminalForm.dirty)"
              [innerHTML]="terminal ? 'Save' : 'Create'">
      </button>
      <button mat-raised-button (click)="closeForm()">Cancel</button>
    </div>
  </div>

</form>
