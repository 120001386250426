import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { PortalSlotService } from '../services/portal-slot.service';

@Directive({
  selector: '[appSlot]'
})
export class SlotDirective implements OnInit {
  @Input('appSlot') slotName = '';

  constructor(private portalService: PortalSlotService, private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.portalService.addTarget(this.slotName, this.viewContainerRef);
  }
}
