import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { isNil } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { TransportHelper } from 'src/app/helpers';
import { TerminalVehicleType } from 'src/app/models/dto/terminale';
import { VehicleBasic } from 'src/app/models/dto/vehicle';

@Component({
  selector: 'ready-trucks',
  templateUrl: './ready-trucks.component.html',
  styleUrls: ['./ready-trucks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadyTrucksComponent implements OnChanges, OnDestroy {
  @Input() trucksList: VehicleBasic[];
  @Input() truckValue: VehicleBasic | null | undefined;
  @Input() defaultTruck: VehicleBasic | null | undefined;
  @Output() truckSelected: EventEmitter<VehicleBasic> = new EventEmitter();

  private trucksListSubject$: BehaviorSubject<VehicleBasic[]> =
    new BehaviorSubject([]);

  filterTrucks: FormControl = new FormControl(null);

  filteredTrucks$ = combineLatest([
    this.trucksListSubject$,
    this.filterTrucks.valueChanges.pipe(startWith('')),
  ]).pipe(
    filter(
      ([trucks, filterValue]: [VehicleBasic[], string | VehicleBasic]) =>
        !isNil(trucks) && !TransportHelper.isVehicleBasic(filterValue)
    ),
    map(([trucks, filterValue]: [VehicleBasic[], string]) =>
      [...trucks]
        .filter(
          (truck) =>
            this.filterTruck(truck, filterValue) &&
            !this.isDefaultSetTruck(truck)
        )
        .sort((a, b) => (a.id > b.id ? -1 : 1))
    )
  );

  vehicleType: typeof TerminalVehicleType = TerminalVehicleType;

  truckDetailsText: (truck: VehicleBasic | null | undefined) => string = (
    truck
  ) => {
    if (!isNil(truck)) {
      return TransportHelper.getVehicleDescription(truck);
    }

    return '';
  };

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.trucksList) {
        this.trucksListSubject$.next(changes.trucksList.currentValue);
      }

      if (changes.truckValue) {
        this.initTrucksFilter(changes.truckValue.currentValue);
      }
    }
  }

  ngOnDestroy(): void {
    this.trucksListSubject$.unsubscribe();
  }

  selectTruck(selectEvent: MatAutocompleteSelectedEvent): void {
    this.truckSelected.emit(selectEvent.option.value);
  }

  get isDefaultSelected(): boolean {
    return this.defaultTruck?.id === this.truckValue?.id;
  }

  private initTrucksFilter(truckValue: VehicleBasic | null | undefined): void {
    this.filterTrucks.setValue(truckValue);
  }

  private filterTruck(truck: VehicleBasic, filterValue: string): boolean {
    const { model, nrRej, idPojazduFirmy, uwagi } = truck;
    const searchCriteria = [
      model.marka.marka,
      model.model,
      nrRej,
      idPojazduFirmy,
      uwagi,
    ].map((criteria) => (criteria || '').toString().toLowerCase());
    const filter = filterValue.trim().toLowerCase();

    return filter
      ? searchCriteria.some((criteria) => criteria.includes(filter))
      : true;
  }

  private isDefaultSetTruck(truck: VehicleBasic): boolean {
    return truck.id === this.defaultTruck?.id;
  }
}
