import { Karta } from './karta';
import { Terminal } from './terminal';

export interface KartaTerminal {
  id: number;
  _karta: Partial<Karta>;
  _terminal: Partial<Terminal>;
  czas_zamont: string;
  czas_usuniecia: string | null;
}
