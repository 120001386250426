import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { OprogramowanieTerminala, StatusTerminala, Terminal } from '../../../../models/dto/terminale';
import * as TERMINALE from '../../../../ngrx/terminal.actions';
import * as fromRoot from '../../../../app.reducer';
import { AppUserInfo } from '../../../../models/authentication';
import { UserRoles } from '../../../../helpers/enum';

@Component({
  selector: 'app-create-terminal',
  templateUrl: './terminal-create.component.html',
  styleUrls: ['./terminal-create.component.scss']
})
export class TerminalCreateComponent implements OnInit, OnDestroy {
  terminalForm: FormGroup;
  terminalStatuses: StatusTerminala[] = [];
  softwareList: OprogramowanieTerminala[] = [];

  subs = new Subscription();
  currUser: AppUserInfo;
  softwareUpdateAllowed = false;

  constructor(private store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<TerminalCreateComponent>,
              @Inject(MAT_DIALOG_DATA) public terminal: Terminal,
              public fb: FormBuilder) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    const cDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
    this.terminalForm =
      this.fb.group({
        data_produkcji: [formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en'), [
          Validators.pattern(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/)
        ]],
        uwagi: ['', Validators.required],
        nr_seryjny: ['', Validators.required],
        _status: [null, Validators.required],
        czy_aktywny: [true, Validators.required],
        tryb_testowy: [false, Validators.required],
        _opr: [null],

        czas_ost_kom: [cDate],
        oczek_czas_kom: [cDate],
        czas_odczytu_wypos: [cDate],
        id: [null],
      });
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getTerminalStatuses),
        this.store.select(fromRoot.selectors.devices.getSoftwareList),
        this.store.select(fromRoot.selectors.auth.getUserInfo),
      ])
        .pipe(
          filter(f => f[0].length > 0 && f[2] !== undefined)
        )
        .subscribe(([ts, sl, u]) => {
          this.softwareList = sl.filter(s => !s.wycofane || (this.terminal && this.terminal._opr.id === s.id));
          this.terminalStatuses = ts;
          this.currUser = u;

          this.initFormValues();
        })
    );
  }

  compareById(o1, o2) {
    return o1.id === o2.id;
  }

  onSubmit() {
    if (this.terminalForm.invalid) {
      return;
    }

    const nowyTerminal: Terminal = {
      ...this.terminalForm.value,
      zmiana_kluczy: false,
      _graber: {id: 1}
    };

    this.store.dispatch(
      new TERMINALE.NewTerminalRequest({
        device: nowyTerminal,
        successCallback: () => this.dialogRef.close()
      })
    );
    return false;
  }

  closeForm() {
    this.dialogRef.close();
    return false;
  }

  private initFormValues() {
    this.terminalForm.patchValue({
      _opr: this.softwareList[0]
    });

    if (this.terminal && this.terminal.id) {
      this.terminalForm.addControl('czy_aktywny', new FormControl(''));
      this.terminalForm.get('data_produkcji').disable();
      this.softwareUpdateAllowed = this.terminal._opr.id === 1 && this.softwareList.length > 0;

      if (!this.softwareUpdateAllowed) {
        this.terminalForm.get('_opr').disable();
      }

      this.terminalForm.patchValue({
        uwagi: this.terminal.uwagi,
        data_produkcji: moment(this.terminal.data_produkcji).format('YYYY-MM-DD HH:mm'),
        _status: this.terminal._status,
        nr_seryjny: this.terminal.nr_seryjny,
        czy_aktywny: this.terminal.czy_aktywny,
        tryb_testowy: this.terminal.tryb_testowy,
        _opr: this.softwareList.find(s => s.id === this.terminal._opr.id),

        id: this.terminal.id,
        czas_odczytu_wypos: this.terminal.czas_odczytu_wypos,
        czas_ost_kom: this.terminal.czas_ost_kom,
      });
    }
    this.softwareUpdateAllowed = this.softwareUpdateAllowed ||
      this.currUser.role.id in [UserRoles.ADMIN, UserRoles.ADMIN_FDW, UserRoles.SERWISANT];
  }

}
