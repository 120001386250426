<div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <div mat-dialog-title fxFlex fxLayout="column" fxLayoutGap="10px">
      <div class="title">Software: {{oprogramowanie.oznaczenie}}</div>
      <div class="description" fxLayoutGap="5px" fxLayout="column">
        <span>{{oprogramowanie.opis}}</span>
        <span>{{oprogramowanie.opis_zmian}}</span>
      </div>
    </div>
    <div fxFlex fxLayout="row" fxLayoutGap="10px">
      <div fxFlex fxLayout="column" fxLayoutGap="10px">
        <h2>Attached frames</h2>
        <div class="ramki przypisane">
          <div *ngFor="let r of wersjePrzypisane" (click)="remove(r)" [class.inactive]="!r.status"
               fxLayout="row" fxLayoutAlign="start center" class="table-row hover">
            <span>{{r.nazwa}} / {{r.typ}} / {{r.wersja}}</span>
            <span fxFlex></span>
            <mat-icon class="icon-remove">delete</mat-icon>
          </div>
        </div>
      </div>
      <div fxFlex fxLayout="column" fxLayoutGap="10px">
        <h2>Available frames</h2>
        <div class="ramki dostepne">
          <div *ngFor="let r of wersjeDostepne" (click)="add(r)" [class.inactive]="!r.status"
               fxLayout="row" fxLayoutAlign="start center" class="table-row hover">
            <span>{{r.nazwa}} / {{r.typ}} / {{r.wersja}}</span>
            <span fxFlex></span>
            <mat-icon class="icon-remove">add</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
