<div fxFlex fxLayout="column" fxLayoutGap="5px">
  <div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
    <mat-form-field>
      <input matInput placeholder="Search..." [formControl]="searchField">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="showAll">Expand all</mat-checkbox>
    <button mat-stroked-button routerLink="../new">Add new</button>
    <span fxFlex></span>
    <button mat-raised-button color="accent">Save</button>
  </div>
  <div class="ramki mat-elevation-z8">
    <ul fxFlex fxLayout="column" fxLayoutGap="8px">
      <li *ngFor="let ramka of ramki">
        <div fxFlex fxLayout="column" fxLayoutGap="8px">
          <div fxLayout="row" fxLayoutGap="15px" class="ramka" (click)="showFrame(ramka)">
            <span>{{ramka.nazwa}}</span>
            <span>Il: {{ramka.wersjaRamkis.length}}</span>
            <span fxFlex></span>
            <span><i [textContent]="ramka.doSerwera ? 'Przychodząca' : 'Wychodząca'"></i></span>
          </div>
          <ul fxFlex fxLayout="column" fxLayoutGap="8px" *ngIf="pokazRamke && ramka.nazwa === pokazRamke.nazwa">
            <li *ngFor="let wersja of ramka.wersjaRamkis">
              <div fxFlex fxLayout="row" fxLayoutGap="15px" class="wersja" [class.inactive]="!wersja.active"
                   [routerLink]="['../', ramka.nazwa, wersja.typ, wersja.wersja]">
                <span>{{wersja.typ}}/{{wersja.wersja}}</span><span>pola: {{wersja.poleRamkis.length}}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
