import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PowodNieczynnosci } from 'src/app/models/dto/vehicle';
import { TrucksInventoryService } from 'src/app/services';
import * as Vehicles from '../../../../ngrx/vehicle.actions';
import * as fromRoot from '../../../../app.reducer';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-unavail-reason',
  templateUrl: './unavail-reason.component.html',
  styleUrls: ['./unavail-reason.component.scss']
})
export class UnavailReasonComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  buttonContent = 'Add';
  unavailReason: PowodNieczynnosci = {powod: '', id: 0};
  unavailReasonsInfo: MatTableDataSource<PowodNieczynnosci>;
  displayedColumns: string[] = ['Id', 'Reason', 'Actions'];

  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>, private veh: TrucksInventoryService) {
    this.form = new FormGroup({
      'reason': new FormControl('')
    });
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.vehicles.getUnavailReasons)
      .subscribe(reasons => {
        this.unavailReasonsInfo = new MatTableDataSource(reasons);
        this.unavailReasonsInfo.data = reasons;
        this.unavailReasonsInfo.sortingDataAccessor = (item: PowodNieczynnosci, property) => {
          switch (property) {
            case 'Id':
              return item.id;
            case 'Reason':
              return item.powod;
            default:
              return item[property];
          }
        };
        this.unavailReasonsInfo.sort = this.sort;
      })
    );
    this.loadLatestData();
    this.subs.add(
      this.form.valueChanges.subscribe(value => {
        if (value.reason === '') {
          this.unavailReason = {powod: '', id: 0};
        }
        this.buttonContent = this.unavailReason.id === 0 ? 'Add' : 'Update';
      })
    );
  }

  private loadLatestData() {
    this.store.dispatch(new Vehicles.LoadUnavailReasonsRequest());
    this.unavailReason = {powod: '', id: 0};
    this.form.reset();
  }

  removeElement(element: PowodNieczynnosci) {
    this.veh.delUnavailReason(element)
      .subscribe(() => {
        this.loadLatestData();
      });

  }

  editElement(element: PowodNieczynnosci) {
    this.unavailReason = element;
    this.form.get('reason').setValue(this.unavailReason.powod);
  }

  saveItem() {
    if (this.unavailReason.id < 1) {
      const newReason: PowodNieczynnosci = {id: null, powod: this.form.get('reason').value};
      this.veh.addUnavailReason(newReason)
        .subscribe(() => {
          this.loadLatestData();
        });
    } else {
      const updatedReason: PowodNieczynnosci = {id: this.unavailReason.id, powod: this.form.get('reason').value};
      this.veh.updUnavailReason(updatedReason)
        .subscribe(() => {
          this.loadLatestData();
        });
    }
  }
}
