<h4 class="mb-4">Transport history</h4>
<table
  mat-table
  [dataSource]="historyList$ | async"
  class="w-100 mat-elevation-z8">
  <ng-container [matColumnDef]="TransportHistoryColumns.Time">
    <th mat-header-cell *matHeaderCellDef>
      {{ TransportHistoryColumns.Time }}
    </th>
    <td mat-cell *matCellDef="let element">{{element.czas | date: 'YYYY-MM-dd HH:mm:ss'}}</td>
  </ng-container>

  <ng-container [matColumnDef]="TransportHistoryColumns.User">
    <th mat-header-cell *matHeaderCellDef>
      {{ TransportHistoryColumns.User }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.uzytkownik.imie }} {{ element.uzytkownik.nazwisko }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="TransportHistoryColumns.Description">
    <th mat-header-cell *matHeaderCellDef>
      {{ TransportHistoryColumns.Description }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.opis }}</td>
  </ng-container>

  <ng-container [matColumnDef]="TransportHistoryColumns.Terminal">
    <th mat-header-cell *matHeaderCellDef>
      {{ TransportHistoryColumns.Terminal }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.idTerminal || '-' }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="TransportHistoryColumns.HistoryEvent">
    <th mat-header-cell *matHeaderCellDef>
      {{ TransportHistoryColumns.HistoryEvent }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.idTypZdarzenia.nazwa }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="historyColumnList"></tr>
  <tr mat-row *matRowDef="let row; columns: historyColumnList;"></tr>
</table>
