<form [formGroup]="formSoftware" novalidate (ngSubmit)="onSubmit()">
  <mat-card class="shipping-card" fxFlex fxLayout="column">
    <mat-card-content fxFlex fxLayoutGap="10px" fxLayout="column">
      <h2>Software</h2>
      <mat-form-field class="full-width">
        <input matInput placeholder="Version" formControlName="oznaczenie"
               (keydown.space)="$event.preventDefault();">
      </mat-form-field>
      <mat-form-field class="full-width">
        <textarea matInput placeholder="Description" formControlName="opis"></textarea>
      </mat-form-field>
      <mat-form-field class="full-width">
        <textarea matInput placeholder="Notes" formControlName="opis_zmian"></textarea>
      </mat-form-field>
      <div *ngIf="terminalSoftware && terminalSoftware.id">
        <mat-slide-toggle formControlName="aktywne">
          <span [innerText]="formSoftware.get('aktywne').value ? 'Aktywne' : 'Wycofane'"></span>
        </mat-slide-toggle>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" *ngIf="!terminalSoftware">
        <button *ngIf="!formSoftware.value.program" type="button" mat-raised-button (click)="fileInput.click()"
                [disabled]="terminalSoftware !== null && terminalSoftware.id !== undefined">
          Select file
        </button>
        <div *ngIf="formSoftware.value.program" class="file-info">
          <span class="file-name">Name: {{formSoftware.value.program.name}}</span><br/>
          <span class="file-size">Size: {{formSoftware.value.program.size / 1024 | number}} KB</span>
        </div>
        <input hidden (change)="onFileChange($event)" #fileInput type="file" id="file">
      </div>
      <button mat-raised-button color="primary" type="submit" [disabled]="formSoftware.invalid">Save</button>
    </mat-card-content>
  </mat-card>
</form>
