import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import * as Vehicles from '../../../../ngrx/vehicle.actions';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TypNaczepy } from '../../../../models/dto/vehicle/typ-naczepy';
import { TrucksInventoryService } from '../../../../services/trucks-inventory.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-trail-type',
  templateUrl: './trail-type.component.html',
  styleUrls: ['./trail-type.component.scss']
})
export class TrailTypeComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  selectedTrail: TypNaczepy = {typ: '', id: 0};
  buttonContent = 'Add';
  trailTypesInfo: MatTableDataSource<TypNaczepy>;
  displayedColumns: string[] = ['Id', 'Type', 'Actions'];

  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>, private veh: TrucksInventoryService) {
    this.form = new FormGroup({
      'type': new FormControl('')
    });
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.vehicles.getTrailTypes)
      .subscribe(trailTypes => {
        this.trailTypesInfo = new MatTableDataSource(trailTypes);
        this.trailTypesInfo.data = trailTypes;
        this.trailTypesInfo.sortingDataAccessor = (item: TypNaczepy, property) => {
          switch (property) {
            case 'Id':
              return item.id;
            case 'Type':
              return item.typ;
            default:
              return item[property];
          }
        };
        this.trailTypesInfo.sort = this.sort;
      })
    );
    this.loadLatestData();
    this.subs.add(
      this.form.valueChanges.subscribe(value => {
        if (value.type === '') {
          this.selectedTrail = {typ: '', id: 0};
        }
        this.buttonContent = this.selectedTrail.id === 0 ? 'Add' : 'Update';
      })
    );
  }

  private loadLatestData() {
    this.store.dispatch(new Vehicles.LoadTrailTypesRequest());
    this.selectedTrail = {typ: '', id: 0};
    this.form.reset();
  }

  removeElement(element: TypNaczepy) {
    this.veh.delTrailType(element).subscribe(() => {
      this.loadLatestData();
    });
  }

  editElement(element: TypNaczepy) {
    this.selectedTrail = element;
    this.form.get('type').setValue(this.selectedTrail.typ);
  }

  saveItem() {
    if (this.selectedTrail.id < 1) {
      const newTrailType = {id: null, typ: this.form.get('type').value};
      this.veh.addTrailType(newTrailType)
        .subscribe(() => {
          this.loadLatestData();
        });
    } else {
      const updatedTrailType = {id: this.selectedTrail.id, typ: this.form.get('type').value};
      this.veh.updTrailType(updatedTrailType)
        .subscribe(() => {
          this.loadLatestData();
        });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
