import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import { AppUserInfo } from '../models/authentication';
import { EnvHelper } from '../helpers';

@Injectable()
export class HttpJwtInterceptor implements HttpInterceptor {

  private uToken = null;
  private uInfo: AppUserInfo = null;

  constructor(private store: Store<fromRoot.State>) {
    this.store.select(fromRoot.selectors.auth.getUserToken).subscribe(token => this.uToken = token);
    this.store.select(fromRoot.selectors.auth.getUserInfo).subscribe(info => this.uInfo = info);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (EnvHelper.getDomainJwtBlacklist().some(url => request.url.includes(url))) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Accept: `application/json;charset=UTF-8, text/plain, */*`,
        'Content-Type': request.method === 'PATCH' ? 'application/merge-patch+json' : 'application/json'
      },
      url: request.url.replace('[', '%5B').replace(']', '%5D')
    });

    if (this.uInfo && this.uToken) {
      const headers = {
        Authorization: `Bearer ${this.uToken}`,
      };
      headers['X-CompanyId'] = this.uInfo.companyId;
      headers['X-ViewCompanyId'] = this.uInfo.companyId;
      headers['X-UserId'] = this.uInfo.userId;
      if (this.uInfo.role) {
        headers['X-RoleId'] = '' + this.uInfo.role.id;
      }

      request = request.clone({
        setHeaders: headers,
        url: request.url.replace('[', '%5B').replace(']', '%5D')
      });
    }

    return next.handle(request);
  }
}
