import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromTransport from './ngrx/transport.reducer';
import { CustomMaterialModule } from '../material.modules';
import { TransportEffects } from './ngrx/transport.effects';
import {
  AssignParkingToTransitComponent,
  CargoOwnerComponent,
  ParkingViewComponent,
  ReadyTransportSetsComponent,
  TableListComponent,
  TransitAlarmTestComponent,
  TransitDetailsComponent,
  TransitDriversFormComponent,
  TransitInfoTailComponent,
  TransitMonitorParamsComponent,
  TransitRelatedAlertsComponent,
  TransportSetDetailsComponent,
  TransportTileComponent,
  TransportTileListComponent,
  ViewFilterComponent
} from './components';
import { TransportRouting } from './transport.routing';
import { SharedModule } from '../shared/shared.module';
import {
  CargoOwnerListComponent,
  CreateNewTransportComponent,
  FavouriteTransitsComponent,
  ParkingLotsComponent,
  ShippingPointsComponent,
  TransportListComponent
} from './page';
import { EntrypointComponent } from './entrypoint/entrypoint.component';
import { TransitAssignedParamsComponent, TransitMonitorParamFormComponent } from './components/parameter';
import { IsTransportStartedPipe } from './pipes/is-transport-started.pipe';
import { GetTransportStatusIconPipe } from './pipes/get-transport-status-icon.pipe';
import {
  TransportActionsComponent,
  TransportDriversComponent,
  TransportMonitoringComponent,
  TransportMonitoringParametersComponent,
  TransportOverviewComponent,
  TransportParkingsComponent,
  TransportRoutesComponent,
  TransportTimetableComponent,
  TruckAndTrailerComponent
} from './components/transport-overview';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { GetTransportDatesTooltipPipe } from './pipes/get-transport-dates-tooltip.pipe';
import { CanPresentTransportOnMapPipe } from './pipes/can-present-transport-on-map.pipe';
import { TransitTestResultFrameComponent } from './components/transit-alarm-test/transit-test-result-frame/transit-test-result-frame.component';
import { TransitTestResultGsmComponent } from './components/transit-alarm-test/transit-test-result-gsm/transit-test-result-gsm.component';
import { TransitTestResultSatComponent } from './components/transit-alarm-test/transit-test-result-sat/transit-test-result-sat.component';
import { TransitTestResultTabletComponent } from './components/transit-alarm-test/transit-test-result-tablet/transit-test-result-tablet.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { SatSignalStrengthComponent } from './components/sat-signal-strength/sat-signal-strength.component';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { SirenDialogComponent } from './components/siren-dialog/siren-dialog.component';
import { FilterDriversPipe } from './pipes/filter-drivers.pipe';
import { BoltDialogComponent } from './components/bolt-dialog/bolt-dialog.component';
import { ReadyTrucksComponent } from './components/ready-trucks/ready-trucks.component';
import { TransportHistoryComponent } from './components/transport-history/transport-history.component';

@NgModule({
  declarations: [
    EntrypointComponent,

    FavouriteTransitsComponent,
    ParkingLotsComponent,

    TransportListComponent,
    ParkingViewComponent,
    TableListComponent,
    TransitRelatedAlertsComponent,
    TransitDetailsComponent,
    CreateNewTransportComponent,
    ReadyTransportSetsComponent,
    TransportSetDetailsComponent,
    TransitMonitorParamsComponent,
    TransitInfoTailComponent,
    TransitAssignedParamsComponent,
    TransitMonitorParamFormComponent,
    AssignParkingToTransitComponent,
    ShippingPointsComponent,
    IsTransportStartedPipe,
    GetTransportStatusIconPipe,
    TransportOverviewComponent,
    TruckAndTrailerComponent,
    TransportDriversComponent,
    TransportRoutesComponent,
    TransportParkingsComponent,
    TransportMonitoringComponent,
    TransportActionsComponent,
    TransportTimetableComponent,
    CargoOwnerListComponent,
    CargoOwnerComponent,
    TransitDriversFormComponent,
    TransportTileComponent,
    TransportTileListComponent,
    ViewFilterComponent,
    GetTransportDatesTooltipPipe,
    CanPresentTransportOnMapPipe,
    TransportMonitoringParametersComponent,
    TransitAlarmTestComponent,
    TransitTestResultFrameComponent,
    TransitTestResultGsmComponent,
    TransitTestResultSatComponent,
    TransitTestResultTabletComponent,
    SatSignalStrengthComponent,
    SirenDialogComponent,
    FilterDriversPipe,
    BoltDialogComponent,
    ReadyTrucksComponent,
    TransportHistoryComponent
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule,
    SharedModule,
    TransportRouting,
    StoreModule.forFeature(fromTransport.featureName, fromTransport.reducer),
    EffectsModule.forFeature([TransportEffects]),
    ScrollingModule,
    TableVirtualScrollModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    VehiclesModule,
  ],
  exports: [
    TransitRelatedAlertsComponent,
    TransitDetailsComponent,
    TransportTileComponent,
    TransportTileListComponent,
    TransportTileComponent
  ]
})
export class TransportModule {
}
