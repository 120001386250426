import { OprogramowanieWersjeRamek } from './oprogramowanie-wersje-ramek';

export interface OprogramowanieTerminala {
  id?: number;
  oznaczenie: string;
  opis?: string;
  opis_zmian?: string;
  rodziaj_aktualizacji?: number;
  data_wydania?: string;
  wycofane?: boolean;
  program?: string;
  sciezka?: string;
  wersjaRamkiOprs?: OprogramowanieWersjeRamek[];
}
