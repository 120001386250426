import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounceTime, map, startWith } from 'rxjs/operators';

import * as Vehicles from '../../../ngrx/vehicle.actions';
import * as fromRoot from '../../../app.reducer';

import { SelectOption } from '../../shared/interfaces';
import { StatusPojazdu } from '../../../models/dto/vehicle';
import { FormHelper } from '../../shared/helpers';
import * as TERMINAL from '../../../ngrx/terminal.actions';

@Component({
  selector: 'app-trail',
  templateUrl: './trail.component.html',
  styleUrls: ['./trail.component.scss']
})
export class TrailComponent implements OnDestroy {
  searchControl = new FormControl();
  vehStateControl = new FormControl();

  subs = new Subscription();
  vehStateLov$: Observable<SelectOption<StatusPojazdu>[]>;

  constructor(private store: Store<fromRoot.State>) {
    this.store.dispatch(new Vehicles.LoadVehicleStatesRequest());
    this.store.dispatch(new TERMINAL.LoadTerminalsTrailsRequest());

    this.vehStateLov$ = this.store.select(fromRoot.selectors.vehicles.getVehicleStates)
      .pipe(map(list => FormHelper.makeSelectLov(list, 'status')));

    this.subs.add(
      this.searchControl.valueChanges
        .pipe(
          debounceTime(350),
          startWith(''),
          map(r => r as string)
        )
        .subscribe(term => {
          if (term.trim().length < 3) {
            this.store.dispatch(new Vehicles.FilterVehicles({kind: 'trailer', term: ''}));
          } else if (term.trim().length >= 3) {
            this.store.dispatch(new Vehicles.FilterVehicles({kind: 'trailer', term}));
          }
        })
    );

    this.subs.add(
      this.vehStateControl.valueChanges
        .pipe(
          debounceTime(350),
          map(r => r as SelectOption<StatusPojazdu>[])
        )
        .subscribe(term => {
          this.store.dispatch(new Vehicles.FilterVehicles({kind: 'trailer', states: (term || []).map(t => t.value.id)}));
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  reloadData() {
    this.store.dispatch(new Vehicles.LoadTrailsRequest());
  }

}
