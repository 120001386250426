<mat-form-field [ngClass]="{'fill': fill}" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControl]="control" [autocomplete]="autocomplete" [required]="isRequired()"
         [placeholder]="placeholder" [readonly]="readonly" [name]="name" [matDatepicker]="hireDate">
  <mat-datepicker-toggle matSuffix [for]="hireDate"></mat-datepicker-toggle>
  <mat-datepicker #hireDate></mat-datepicker>
  <mat-error *ngIf="control.hasError('required')">Value is required.</mat-error>
  <mat-error *ngIf="control.hasError('min')">Minimum value should greater than {{ control.errors.min.min }}.</mat-error>
  <mat-error *ngIf="control.hasError('max')">Minimum value should lower than {{ control.errors.max.max }}.</mat-error>
  <mat-error *ngIf="control.hasError('email')">Value should have email format.</mat-error>
  <mat-error *ngIf="control.hasError('minlength')">Value should have more characters
    than {{ control.errors.minlength.requiredLength }}.
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">Value should have less characters
    than {{ control.errors.maxlength.requiredLength }}.
  </mat-error>
  <mat-error *ngIf="control.hasError('pattern')">Value must match pattern: {{ control.errors.pattern.requiredPattern }}
    .
  </mat-error>
  <mat-error *ngIf="control.hasError('custom')">{{ control.errors.custom.message }}.</mat-error>
</mat-form-field>
