import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CompatClient, Stomp, StompSubscription } from '@stomp/stompjs';
import { Howl } from 'howler';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import SockJS from 'sockjs-client/dist/sockjs';
import * as fromRoot from 'src/app/app.reducer';
import { EnvHelper, TransportAlert } from 'src/app/helpers';
import * as ALERT from 'src/app/modules/alerts/ngrx/alert.actions';
import { WebsocketMessage, WebsocketMessageType } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private socket: SockJS | undefined;
  private stompClient: CompatClient | undefined;
  private channelSubscription: StompSubscription | undefined;
  private messageSound: Howl = new Howl({
    src: ['/assets/notification_2.wav'],
  });

  private alertListRefreshSubject$ = new Subject<void>();
  private alertListRefresh$ = this.alertListRefreshSubject$.pipe(
    debounceTime(REFRESH_DEBOUNCE_MS),
    tap(() => {
      this.store.dispatch(
        new ALERT.LoadTransportAlertsByStatusRequest({
          statusList: TransportAlert.activeAlertStatusList,
        })
      );
    })
  );
  private alertListRefreshSubscription: Subscription | undefined;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async connect(): Promise<boolean> {
    this.socket = new SockJS(EnvHelper.getWebsocketUrl());

    this.stompClient = Stomp.over(this.socket);

    return new Promise((resolve, reject) => {
      this.stompClient.connect(
        {},
        () => {
          resolve(true);
        },
        () => {
          reject(false);
        }
      );
    });
  }

  disconnect(): void {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }

    this.unsubscribeAlertListRefresh();
  }

  subscribeTo(where: 'co' | 'cm', companyId: number): void {
    this.alertListRefreshSubscription = this.alertListRefresh$.subscribe();

    this.channelSubscription = this.stompClient.subscribe(
      `/topic/newalarmorevent/${where}/${companyId}`,
      (msg) => {
        let body: WebsocketMessage | undefined;
        try {
          body = JSON.parse(msg.body);
        } catch (e) {
          console.error(e);
        }

        if (body && this.isWebsocketMessage(body)) {
          switch (body.value) {
            case WebsocketMessageType.ALERT_CREATE:
              this.store.dispatch(
                new ALERT.LoadTransportAlertsByStatusRequest({
                  statusList: TransportAlert.activeAlertStatusList,
                  refreshDates: true
                })
              );

              this.messageSound.play();

              if (this.router.url !== '/events/alerts/transport') {
                const toastr = this.toastr.error(
                  `Click to show alert list.`,
                  'New alert',
                  {
                    timeOut: 0,
                    disableTimeOut: true,
                    tapToDismiss: true,
                    closeButton: true,
                    positionClass: 'toast-bottom-right',
                  }
                );

                toastr.onTap.pipe(take(1)).subscribe(() => {
                  this.router.navigate([`/events/alerts/transport`]);
                });
              }
              break;

            case WebsocketMessageType.ALERT_UPDATE:
            case WebsocketMessageType.EVENT_CREATE:
            case WebsocketMessageType.EVENT_UPDATE:
              this.alertListRefreshSubject$.next();
              break;

            default:
              console.warn(`Unhandled websocket message type: "${body.value}"`);
              break;
          }
        }
      }
    );
  }

  unsubscribeFromChannel(): void {
    if (this.channelSubscription) {
      this.channelSubscription.unsubscribe();
    }

    this.unsubscribeAlertListRefresh();
  }

  get connected(): boolean {
    return this.stompClient.connected;
  }

  private isWebsocketMessage(body: WebsocketMessage): body is WebsocketMessage {
    return typeof body === 'object' && body.hasOwnProperty('value');
  }

  private unsubscribeAlertListRefresh(): void {
    if (this.alertListRefreshSubscription) {
      this.alertListRefreshSubscription.unsubscribe();
    }
  }
}

const REFRESH_DEBOUNCE_MS = 200;
