import { Uzytkownik } from '../dto/user';
import { DostepneModuly } from './dostepne-moduly';
import { Uprawnienie } from './uprawnienie';

export interface UprawnienieUzytkownika {
  id?: number;
  _uzytkownik: Uzytkownik;
  _dost_mod?: DostepneModuly | string;
  _upr: Uprawnienie;
}
