<div fxFlex fxLayout="row" fxLayoutGap="10px">
  <div class="mat-elevation-z8 column" fxLayout="column" fxLayoutGap="15px">
    <h2>New frame version</h2>
    <mat-form-field>
      <mat-label>Select to clone</mat-label>
      <mat-select [formControl]="dostepneWersje">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let conf of konfiguracje | onlyActive : 'status'" [value]="conf">
          {{conf.nazwa}} / {{conf.typ}} / {{conf.wersja}} ({{conf.pola.length}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <form [formGroup]="myForm" (ngSubmit)="zapiszNowaWersje()">
      <mat-form-field>
        <mat-label>Frame</mat-label>
        <input matInput name="nazwa" readonly formControlName="nazwa">
      </mat-form-field>
      <div fxLayout="column" fxLayoutGap="5px">
        <mat-label>frame type</mat-label>
        <mat-radio-group fxLayoutGap="5px" formControlName="typ">
          <mat-radio-button value="d">Dev</mat-radio-button>
          <mat-radio-button value="v">Prod</mat-radio-button>
          <mat-radio-button value="o">Inna</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field>
        <mat-label>Version</mat-label>
        <input matInput name="wersja" formControlName="wersja">
      </mat-form-field>
      <div>
        <button type="submit" mat-raised-button color="primary" [disabled]="validatorError">Create</button>
      </div>
    </form>
  </div>
  <div *ngIf="polaRamki.length > 0" class="mat-elevation-z8 column" fxLayout="column" fxLayoutGap="5px">
    <div fxLayout="row" fxLayoutGap="5px">
      <h2>Fields</h2>
      <span fxFlex></span>
      <div>
        <button mat-stroked-button color="primary" (click)="nowePole()">Add new</button>
      </div>
    </div>
    <table>
      <thead>
      <tr>
        <th>Name</th>
        <th>Bit from (Bajt)</th>
        <th>Bit to</th>
        <th>Length</th>
        <th>Type</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let pole of polaRamki;let idx=index;" (click)="edytujPole(idx)">
        <td>{{pole.nazwaPola}}</td>
        <td>{{pole.bitOd}} ({{(pole.bitOd / 8 + 1) | number:'1.0-0'}})</td>
        <td>{{pole.bitDo}}</td>
        <td>{{pole.bitDo - pole.bitOd + 1}} B</td>
        <td>{{pole.typDanych.typ}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="validatorError || validators.wolneBity">
    <div *ngIf="polaRamki.length > 0"
         class="mat-elevation-z8 column" fxLayout="column" fxLayoutGap="5px">
      <h2>Validation control</h2>
      <span class="label error" *ngIf="validators.dubleNazwy.length > 0">
        Fields names are not unique!
        <ul>
          <li *ngFor="let p of validators.dubleNazwy">{{p}}</li>
        </ul>
      </span>
      <span class="label error" *ngIf="validators.dublePolaBazy.length > 0">
        DB column names are not unique!
        <ul>
          <li *ngFor="let p of validators.dublePolaBazy">{{p}}</li>
        </ul>
      </span>
      <div fxLayout="row" fxLayoutGap="5px" *ngIf="validators.nakladajaceBity || validators.wolneBity">
        <div fxLayout="column" fxLayoutGap="5px">
          <span class="label error" *ngIf="validators.nakladajaceBity">Fields are overlapping!</span>
          <span class="label warning" *ngIf="validators.wolneBity">Gaps between fields</span>
        </div>
        <button mat-raised-button color="primary" (click)="poprawBity()">Fix</button>
      </div>
    </div>
  </div>
</div>
