import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OprogramowanieTerminala, OprogramowanieWersjaRamkiInsert, OprogramowanieWersjeRamek } from '../../../../models/dto/terminale';
import * as fromRoot from '../../../../app.reducer';
import * as TERMINALE from '../../../../ngrx/terminal.actions';
import { filter, map, take } from 'rxjs/operators';
import { Api } from '../../../../../environments/api';

@Component({
  selector: 'app-terminal-software',
  templateUrl: './terminal-software-dialog.component.html',
  styleUrls: ['./terminal-software-dialog.component.scss']
})
export class TerminalSoftwareDialogComponent implements OnInit {
  formSoftware = this.fb.group({
    oznaczenie: [null, [Validators.required, Validators.maxLength(15), Validators.minLength(3)]],
    opis: [null, Validators.required],
    opis_zmian: null,
    program: null
  });

  wersjeRamekPoprzedniegoOpr: OprogramowanieWersjeRamek[] = [];

  constructor(private fb: FormBuilder,
              private store: Store<fromRoot.State>,
              @Inject(MAT_DIALOG_DATA) public terminalSoftware: OprogramowanieTerminala) {
  }

  ngOnInit(): void {
    if (this.terminalSoftware && this.terminalSoftware.id) {
      this.formSoftware.get('program').disable();
      this.formSoftware.patchValue({
        oznaczenie: this.terminalSoftware.oznaczenie,
        opis: this.terminalSoftware.opis,
        opis_zmian: this.terminalSoftware.opis_zmian
      });

      this.formSoftware.addControl('aktywne', new FormControl(!this.terminalSoftware.wycofane));
    } else {
      // dodawanie nowego oprogramowania
      this.store.select(fromRoot.selectors.devices.getSoftwareList)
        .pipe(
          filter(r => r.length > 0),
          take(1),
          map(r => r
            .filter(s => !s.wycofane)
            .reduce((prev, next) => prev.id > next.id ? prev : next)
          )
        )
        .subscribe(opr => {
          this.wersjeRamekPoprzedniegoOpr = opr.wersjaRamkiOprs;
        });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        const newVal = {
          lastModified: file.lastModified,
          name: file.name,
          size: file.size,
          type: file.type,
          content: reader.result
        };

        this.formSoftware.patchValue({
          program: newVal
        });
      };
    }
  }

  onSubmit() {
    if (!this.formSoftware.valid) {
      return false;
    }

    let newSoftware: OprogramowanieTerminala = {
      oznaczenie: this.formSoftware.value.oznaczenie,
      opis: this.formSoftware.value.opis,
      opis_zmian: this.formSoftware.value.opis_zmian,
      sciezka: '/dev/null',
      program: undefined,
      data_wydania: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en')
    };

    if (this.terminalSoftware) {
      newSoftware = {
        id: this.terminalSoftware.id,
        oznaczenie: this.formSoftware.value.oznaczenie,
        opis: this.formSoftware.value.opis,
        opis_zmian: this.formSoftware.value.opis_zmian,
        wycofane: !this.formSoftware.value.aktywne
      };
      this.store.dispatch(new TERMINALE.UpdateTerminalSoftwareRequest(newSoftware));
    } else {
      newSoftware.program = this.formSoftware.value.program.content
        .split(';')[1]
        .split(',')[1];
      const payload = {
        soft: newSoftware,
        successCallback: (softId: number) => {
          const records: OprogramowanieWersjaRamkiInsert[] = [];
          this.wersjeRamekPoprzedniegoOpr.forEach(w => {
            records.push({
              _oprogramowanie_terminala: Api.Terminale.OprogramowanieTerminala + '/' + softId,
              _wersja_ramki: Api.Ramki.WersjaRamek + '/' + w._wersja_ramki.id
            });
          });
          this.store.dispatch(new TERMINALE.NewSoftwareFramesRequest({records}));
        }
      };
      this.store.dispatch(new TERMINALE.NewTerminalSoftwareRequest(payload));
    }

  }
}
