<ng-container *ngIf="{
  drivers1: drivers1$ | async,
  drivers2: drivers2$ | async
} as truckDetails">
  <div class="r-drawer-container" fxLayout="column" fxLayoutGap="16px">
    <p mat-subheading-1 *ngIf="truck">Details: {{truck.nr_rej}}</p>
    <p mat-subheading-1 *ngIf="!truck">Create truck</p>
    <form fxLayout="column" fxLayoutGap="10px" [formGroup]="form" (submit)="save()">
      <div class="row-items">
        <app-input name="company_vehicle" placeholder="Company vehicle ID" label="Company vehicle ID" id="company_vehicle"
                   [control]="form.getFormControl('id_pojazdu_firmy')"></app-input>

        <app-input name="nr_rej" placeholder="Licence plate" label="Licence plate" id="nr_rej"
                   [control]="form.getFormControl('nr_rej')"></app-input>
      </div>
      <app-select placeholder="Brand" label="Brand" id="brand" (selected)="vendorSelected($event)"
                  [control]="truckVendorControl" [lov]="vehicleVendorsLov"></app-select>

      <app-select placeholder="Model" label="Model" id="model"
                  [control]="form.getFormControl('model')" [lov]="vehicleFilteredModelsLov"></app-select>

      <div fxLayout="row" fxLayoutGap="5px">
        <app-select placeholder="Connection type" label="Connection type" id="rodzaj_zapiecia"
                    [control]="form.getFormControl('rodzaj_zapiecia')" [lov]="connectTypeListLov"></app-select>

        <app-select placeholder="Vehicle status" label="Vehicle status" id="status_pojazdu"
                    [control]="form.getFormControl('status_pojazdu')" [lov]="vehicleStateLov"></app-select>
      </div>

      <app-input autocomplete="false" label="VIN" placeholder="VIN" name="vin"
                [control]="form.getFormControl('vin')"></app-input>

      <app-select placeholder="Work status" label="Work status" id="akt_stan_pracy"
                  [control]="form.getFormControl('akt_stan_pracy')" [lov]="truckWorkStateLov"></app-select>

      <div fxLayout="row" fxLayoutGap="5px">
        <app-input autocomplete="false" label="Truck's telephone" placeholder="Truck's telephone" name="phonenumber"
                  [control]="form.getFormControl('phonenumber')"></app-input>

        <app-input name="liczba_miejsc" placeholder="Seats number" label="Seats number"
                  [control]="form.getFormControl('liczba_miejsc')" type="number"></app-input>
      </div>

      <div fxLayout="row" fxLayoutGap="5px">
        <app-select
          [control]="form.getFormControl('id_kierowca_1')"
          [lov]="truckDetails.drivers1"
          [compareWith]="compareDrivers"
          placeholder="Select from list"
          label="First default driver"
          id="id_kierowca_1">
        </app-select>

        <app-select
          [control]="form.getFormControl('id_kierowca_2')"
          [lov]="truckDetails.drivers2"
          [compareWith]="compareDrivers"
          placeholder="Select from list"
          label="Second default driver"
          id="id_kierowca_2">
        </app-select>
      </div>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Show safety equipment
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="eq-items">
          <ng-container *ngIf="(terminalEq$ | async) as equipments;else noTerminalEqTpl">
            <div *ngFor="let eqItem of equipments">
              - {{eqItem.wyposazenieNazwa}}
            </div>
          </ng-container>
          <ng-template #noTerminalEqTpl>
            No equipment
          </ng-template>
        </div>
      </mat-expansion-panel>

      <app-textarea name="uwagi" placeholder="Remarks" label="Remarks"
                    [control]="form.getFormControl('uwagi')"></app-textarea>

      <button mat-raised-button type="submit" [disabled]="form.invalid" color="primary">Save</button>
    </form>
  </div>
</ng-container>
