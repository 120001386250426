import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import * as fromDriver from '../../ngrx/driver.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import * as DRIVER from '../../ngrx/driver.actions';

import { Kategoria, Kierowca, KierowcaKategoria } from '../../interfaces';
import { ToastType } from '../../../../helpers/enum';
import { BaseYesNoConfig } from 'src/app/modules/shared/interfaces';
import { BaseYesNoDialogComponent } from 'src/app/modules/shared/dialogs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-driver-edit',
  templateUrl: './driver-edit.component.html',
  styleUrls: ['./driver-edit.component.scss'],
})
// todo: zweryfikować poprawne dodawanie uprawnień
// todo: dodać możliwość edycji oraz usuwania istniejących uprawnień
export class DriverEditComponent implements OnInit {
  idKierowca = 0;
  kierowca$: Observable<Kierowca>;
  kategorie$: Observable<Kategoria[]>;

  myForm = this.fb.group({
    id: [0],
    id_kierowca: [0],
    data_wydania: ['', Validators.required],
    data_waznosci: [''],
    _kategoria: [null, Validators.required],
  });

  pinsToDisplay$: Observable<Pick<Kierowca, 'pin' | 'pin_ca'> | null> =
    this.store.select(fromDriver.getPins).pipe(
      filter((pins) => pins !== null),
      tap(() => {
        this.store.dispatch(new DRIVER.SetPinsToDisplay(null));
      })
    );

  pinGenerationInProgress$: Observable<boolean> = this.store.select(
    (state) => state.driver.pinGenerationInProgress
  );

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.route.paramMap.subscribe(
      (res) => (this.idKierowca = parseInt(res.get('id'), 10))
    );
  }

  ngOnInit(): void {
    this.kategorie$ = this.store.select(fromDriver.getLicences);
    this.kierowca$ = this.store.select(fromDriver.getDrivers).pipe(
      filter((r) => r !== null && r.length > 0),
      map((d) => d.find((i) => i.id === this.idKierowca))
    );
    this.myForm.patchValue({ id_kierowca: this.idKierowca });
  }

  licenseAdd(el: HTMLElement) {
    if (!el.hidden) {
      if (this.myForm.invalid) {
        this.store.dispatch(
          UI.showUserMessage({
            message: {
              type: ToastType.ERROR,
              title: 'Błąd na formularzu',
              message: 'Popraw aby zapisać',
            },
          })
        );
        return false;
      }

      const kierowcaKategoria: KierowcaKategoria = {
        ...this.myForm.value,
        id_kierowca: this.idKierowca,
      };
      this.store.dispatch(
        new DRIVER.AddDriverLicenceRequest({ kierowcaKategoria })
      );
      el.hidden = !el.hidden;
      this.myForm.reset();
    } else {
      el.hidden = !el.hidden;
    }
  }

  licenseCancel(el: HTMLElement) {
    el.hidden = true;
    this.myForm.reset();
  }

  licenseRemove(el: HTMLElement) {
    const request = {
      id: this.myForm.get('id').value,
      successCallback: () => {
        el.hidden = true;
        this.myForm.reset();
      },
    };
    this.store.dispatch(new DRIVER.RemoveDriverLicenceRequest(request));
  }

  licenseEdit(el: HTMLElement, kategoriaKierowcy: KierowcaKategoria) {
    this.myForm.patchValue({
      id: kategoriaKierowcy.id,
      id_kierowca: kategoriaKierowcy.id_kierowca,
      data_wydania: new Date(kategoriaKierowcy.data_wydania),
      data_waznosci: new Date(kategoriaKierowcy.data_waznosci),
      _kategoria: kategoriaKierowcy._kategoria,
    });
    el.hidden = false;
  }

  licenseCompare(o1: KierowcaKategoria, o2: KierowcaKategoria): boolean {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }

  generateNewPins(pinType?: 'pin' | 'pinca'): void {
    const config: BaseYesNoConfig = {
      title: 'Generate new PINs',
      content:
        'Do you want to generate new PIN codes? Previously stored PINs will be lost.',
      yesAction: () =>
        this.store.dispatch(
          new DRIVER.GenerateNewPins({ id: this.idKierowca, pinType })
        ),
      yesLabel: 'Yes',
      yesColor: 'primary',
      noLabel: 'Cancel',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-NewPinCodes',
      position: { top: '7%' },
    });
  }
}
