import { FormControl, Validators } from '@angular/forms';
import { DefaultForm } from '../../../models/form/default-form';
import { Model, Samochod } from '../../../models/dto/vehicle';

export class TruckForm extends DefaultForm<any> {
  protected model: Samochod | null = null;

  constructor(vehicle?: Samochod, model?: Model) {
    super({
      id: new FormControl(null),
      nr_rej: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      id_pojazdu_firmy: new FormControl(''),
      phonenumber: new FormControl('', [Validators.minLength(9)]),
      model: new FormControl(null, [Validators.required, Validators.min(1)]),
      rodzaj_zapiecia: new FormControl(null, Validators.required),
      status_pojazdu: new FormControl(null, Validators.required),
      vin: new FormControl('', [Validators.required, Validators.minLength(17)]),
      akt_stan_pracy: new FormControl(null, Validators.required),
      dop_ladownosc: new FormControl(20, [Validators.required, Validators.min(0)]),
      l_przycisk_napad: new FormControl(0, [Validators.required, Validators.min(0)]),
      liczba_miejsc: new FormControl(2, [Validators.required, Validators.min(1), Validators.max(7)]),
      uwagi: new FormControl(''),
      id_kierowca_1: new FormControl(null),
      id_kierowca_2: new FormControl(null)
    });

    if (vehicle) {
      this.patchFromModel(vehicle, model);
    }
  }

  get newTruckInfo(): Partial<Samochod> {
    let samochod: Partial<Samochod> = {
      _model: this.value.model,
      l_przycisk_napad: parseInt(this.value.l_przycisk_napad, 10),
      liczba_miejsc: parseInt(this.value.liczba_miejsc, 10),
      phonenumber: this.value.phonenumber,
      vin: this.value.vin,
      nr_rej: this.value.nr_rej.trim().toUpperCase(),
      id_pojazdu_firmy: this.value.id_pojazdu_firmy,
      dop_ladownosc: parseInt(this.value.dop_ladownosc, 10),
      _akt_stan_pracy: this.value.akt_stan_pracy,
      _status: this.value.status_pojazdu,
      _typ_zapiecia: this.value.rodzaj_zapiecia,
      uwagi: this.value.uwagi,
      id_kierowca_1: this.value.id_kierowca_1,
      id_kierowca_2: this.value.id_kierowca_2
    };

    if (!!this.value.id) {
      samochod = {...samochod, id: +this.value.id};
    }

    return samochod;
  }

  patchFromModel(vehicle: Samochod, currentModel?: Model): void {
    const formData = {
      id: vehicle.id,
      nr_rej: vehicle.nr_rej,
      id_pojazdu_firmy: vehicle.id_pojazdu_firmy,
      uwagi: vehicle.uwagi,
      status_pojazdu: vehicle._status,
      vin: vehicle.vin,
      akt_stan_pracy: vehicle._akt_stan_pracy,
      rodzaj_zapiecia: vehicle._typ_zapiecia,
      dop_ladownosc: vehicle.dop_ladownosc,
      liczba_miejsc: vehicle.liczba_miejsc,
      l_przycisk_napad: vehicle.l_przycisk_napad,
      model: currentModel,
      phonenumber: vehicle.phonenumber || '',
      id_kierowca_1: vehicle.id_kierowca_1,
      id_kierowca_2: vehicle.id_kierowca_2
    };
    this.patchValue(formData);
    this.model = vehicle;
  }
}
