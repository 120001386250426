export * from './terminal.component';
export * from './terminal-short-info/terminal-short-info.component';
export * from './list-terminal/terminal-list.component';
export * from './list-terminal/terminal-sim-card-form/terminal-sim-card-form.component';
export * from './software/software.component';
export * from './frame-config/frame-config.component';

export * from './frame-config/config-creator/config-creator.component';
export * from './frame-config/config-editor/config-editor.component';
export * from './frame-config/config-list/config-list.component';
