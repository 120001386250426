import { RoleUzytkownikow } from './role-uzytkownikow';

export interface AppUserInfo {
  login: string;
  companyId?: string;
  userId?: string;

  name?: string;
  surname?: string;
  email?: string;

  role?: RoleUzytkownikow;
}

