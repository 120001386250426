export enum TerminalTableColumnsEnum {
  Id = 'Id',
  Status = 'Status',
  Assigned = 'Assigned',
  SerialNumber = 'SerialNumber',
  BatteryLevel = 'BatteryLevel',
  Software = 'Software',
  Active = 'Active',
  LastMsg = 'LastMsg',
  Notes = 'Notes',
  Actions = 'Actions'
}
