import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { merge, Observable, of } from 'rxjs';
import moment from 'moment';

import { KartaTerminal } from '../dto/terminale';
import { DefaultForm } from './default-form';
import { DateFormat } from '../../helpers/enum';

export class TerminalSimCardForm extends DefaultForm<KartaTerminal> {
  private static HOUR_FORMAT = DateFormat.PIPE_TIMESHORT_24H;
  private static PARSE_DATE_FORMAT = DateFormat.MOMENT_DATE;
  private static VIEW_DATE_FORMAT = DateFormat.PRIME_NG_DATE;

  constructor(terminalSimCard?: KartaTerminal | null) {
    super({
      simCard: new FormControl(terminalSimCard?._karta, [Validators.required]),
      installationDateTime: new FormGroup(
        {
          date: new FormControl(),
          time: new FormControl(),
        },
        [TerminalSimCardForm.validDate]
      ),
    });

    if (terminalSimCard) {
      this.patchFromModel(terminalSimCard);
    }
  }

  private static validDate(form: FormGroup): ValidationErrors | null {
    const installationDateTime = TerminalSimCardForm.addDateAndTime(form);

    if (installationDateTime && installationDateTime.isValid()) {
      return null;
    }
    return { invalid: 'Date format is not valid' };
  }

  private static addDateAndTime(form: FormGroup): moment.Moment | null {
    if (!form.get('date').value) {
      return null;
    }
    if (!form.get('time').value) {
      return null;
    }
    const date = moment.utc(
      form.get('date').value,
      TerminalSimCardForm.PARSE_DATE_FORMAT
    );
    const time = moment.utc(
      form.get('time').value,
      TerminalSimCardForm.HOUR_FORMAT
    );

    if (!date.isValid()) {
      return null;
    }

    return moment(`${form.get('date').value}T${form.get('time').value}:00.000Z`);
  }

  viewDateFormat(): string {
    return TerminalSimCardForm.VIEW_DATE_FORMAT;
  }

  simCardValueChanges(): Observable<string | null> {
    return merge(of(null), this.get('simCard').valueChanges);
  }

  patchFromModel(terminalSimCard: KartaTerminal): void {
    this.patchValue({
      simCard: terminalSimCard?._karta,
      installationDateTime: {
        date: terminalSimCard?.czas_zamont
          ? moment
              .utc(terminalSimCard?.czas_zamont)
              .format(TerminalSimCardForm.PARSE_DATE_FORMAT)
          : null,
        time: terminalSimCard?.czas_zamont
          ? moment
              .utc(terminalSimCard?.czas_zamont)
              .format(TerminalSimCardForm.HOUR_FORMAT)
          : null,
      },
    });
    this.model = terminalSimCard;
  }

  getUpdatedModel(): KartaTerminal {
    if (!this.get('installationDateTime').valid) {
      return {
        ...this.model,
        ...{ _karta: this.get('simCard').value, czas_zamont: null },
      };
    }

    const installationDateTime = TerminalSimCardForm.addDateAndTime(
      this.get('installationDateTime') as FormGroup
    );

    return {
      ...this.model,
      ...{
        _karta: this.get('simCard').value,
        czas_zamont: installationDateTime.toDate().toISOString(),
      },
    };
  }

  changeInstallmentTime(time: string): void {
    this.get('installationDateTime').get('time').patchValue(time);
  }
}
