<div mat-dialog-title>
  Edit: {{data.ramka.nazwa}} / {{data.wersja.typ}} / {{data.wersja.wersja}}
</div>
<div mat-dialog-content>
  <form (ngSubmit)="zapisz()" fxLayoutGap="10px"
        [formGroup]="formEdit" fxLayout="column">
    <mat-form-field>
      <input matInput formControlName="wersja" placeholder="Frame version">
    </mat-form-field>
    <div fxLayout="column" fxLayoutGap="5px">
      <mat-label>Frame type</mat-label>
      <mat-radio-group fxLayoutGap="5px" formControlName="typ">
        <mat-radio-button value="d">Dev</mat-radio-button>
        <mat-radio-button value="v">Prod</mat-radio-button>
        <mat-radio-button value="o">Other</mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <mat-slide-toggle formControlName="active">
        <span [innerText]="formEdit.get('active').value ? 'Active' : 'Withdrawn'"></span>
      </mat-slide-toggle>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
      <button mat-stroked-button (click)="anuluj()">Cancel</button>
      <button mat-raised-button type="submit" color="primary" [disabled]="formEdit.invalid">Save</button>
    </div>
  </form>
</div>
