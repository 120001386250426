<div fxLayout="column" class="mat-elevation-z3 main">
  <h5>Truck & Trailer</h5>
  <div fxLayout="column" fxLayoutGap="8px" class="mat-elevation-z1 vehicle">
    <ng-container>
      <div *ngIf="truck; else noTruck">
        <span>
          Truck: {{truck.id}}, {{truck.nrRej}}
        </span>
      </div>
      <div *ngIf="trailer.truckSet | vehicleFromTransit; else noTrailer">
        <span>
          Trailer: {{(trailer.trailerSet | vehicleFromTransit)?.id}}, {{(trailer.trailerSet | vehicleFromTransit).nrRej}}
        </span>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noTruck>
  <div class="error">No truck selected.</div>
</ng-template>

<ng-template #noTrailer>
  <div class="error">No trailer selected.</div>
</ng-template>
