import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Marka } from 'src/app/models/dto/vehicle';
import { TrucksInventoryService } from 'src/app/services';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import * as Vehicles from '../../../../ngrx/vehicle.actions';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-vehicle-vendor',
  templateUrl: './vehicle-vendor.component.html',
  styleUrls: ['./vehicle-vendor.component.scss']
})
export class VehicleVendorComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  form: FormGroup;
  selectedVehicleKind: Marka = {marka: '', id: 0};
  buttonContent = 'Add';
  vehicleMakesInfo: MatTableDataSource<Marka>;
  displayedColumns: string[] = ['Id', 'Make', 'Actions'];

  subs= new Subscription();

  constructor(private store: Store<fromRoot.State>, private veh: TrucksInventoryService) {
    this.form = new FormGroup({
      'make': new FormControl('')
    });
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.vehicles.getVendors)
      .subscribe(vendors => {
        this.vehicleMakesInfo = new MatTableDataSource(vendors);
        this.vehicleMakesInfo.data = vendors;
        this.vehicleMakesInfo.sortingDataAccessor = (item: Marka, property) => {
          switch (property) {
            case 'Id':
              return item.id;
            case 'Make':
              return item.marka;
            default:
              return item[property];
          }
        };
        this.vehicleMakesInfo.sort = this.sort;
      })
    );
    this.loadLatestData();
    this.subs.add(
      this.form.valueChanges.subscribe(value => {
        if (value.type === '') {
          this.selectedVehicleKind = {marka: '', id: 0};
        }
        this.buttonContent = this.selectedVehicleKind.id === 0 ? 'Add' : 'Update';
      })
    );
  }

  private loadLatestData() {
    this.store.dispatch(new Vehicles.LoadVendorsRequest());
    this.selectedVehicleKind = {marka: '', id: 0};
    this.form.reset();
  }

  removeElement(element: Marka) {
    this.veh.delVehicleKind(element)
      .subscribe(() => {
        this.loadLatestData();
      });
  }

  editElement(element: Marka) {
    this.selectedVehicleKind = element;
    this.form.get('make').setValue(this.selectedVehicleKind.marka);
  }

  saveItem() {
    if (this.selectedVehicleKind.id < 1) {
      const newVehicleMake = {id: null, marka: this.form.get('make').value};
      this.veh.addVehicleKind(newVehicleMake)
        .subscribe(() => {
          this.loadLatestData();
        });
    } else {
      const updatedVehicleMake = {id: this.selectedVehicleKind.id, marka: this.form.get('make').value};
      this.veh.updVehicleKind(updatedVehicleMake)
        .subscribe(() => {
          this.loadLatestData();
        });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
