export * from './vehicles.component';
export * from './vehicle.routing';
export * from './menu/menu.component';
export * from './base-type/base-type.component';
export * from './base-type/connection-type/connection-type.component';
export * from './base-type/trail-type/trail-type.component';
export * from './base-type/unavail-reason/unavail-reason.component';
export * from './base-type/vehicle-vendor/vehicle-vendor.component';
export * from './base-type/vehicle-model/vehicle-model.component';
export * from './base-type/vehicle-type/vehicle-type.component';

export * from './trail/trail.component';
export * from './trail/trail-list/trailer-list.component';
export * from './trail/details-trail/details-trail.component';

export * from './truck/truck.component';
export * from './truck/truck-details/truck-details.component';
export * from './truck/truck-list/truck-list.component';

export * from './terminal/list-terminal/terminal-list.component';
