import { FormControl, Validators } from '@angular/forms';
import { KierowcaNowy } from '../../modules/drivers/interfaces';
import { DefaultForm } from './default-form';

export class DriverForm extends DefaultForm<any> {
  protected model: KierowcaNowy | null = null;

  constructor(driver?: KierowcaNowy) {
    super({
      id: new FormControl(''),
      imie: new FormControl('', Validators.required),
      nazwisko: new FormControl('', [Validators.required, Validators.minLength(3)]),
      data_ur: new FormControl(''),
      data_zatr: new FormControl(''),
      panstwo: new FormControl(''),
      potwierdz: new FormControl('', [Validators.required, Validators.minLength(3)]),
      kod: new FormControl(''),
      miasto: new FormControl(''),
      ulica: new FormControl(''),
      nr_d: new FormControl(''),
      nr_l: new FormControl(''),
      nr_karty: new FormControl('', [Validators.required]),
      tel1: new FormControl('', [Validators.required, Validators.minLength(9)]),
      tel2: new FormControl(''),
      status: new FormControl('', [Validators.required]),
    });

    if (driver) {
      this.patchFromModel(driver);
    }
  }

  patchFromModel(driver: KierowcaNowy): void {
    this.patchValue({
      ...driver
    });
    this.model = driver;
  }
}
