<ng-container *ngIf="terminal$ | async as terminal">
  <div class="terminal-sim-card-form-container fwd-form" fxLayout="column" fxLayoutGap="16px">
    <h2 mat-subheading-1>Assign a SIM card to the terminal {{ terminal.id }}</h2>

    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(terminal)" fxLayout="column" fxLayoutGap="16px">
      <mat-form-field appearance="outline" class="sim-card">
        <mat-label>Card</mat-label>
        <input type="text" placeholder="Select SIM" matInput [matAutocomplete]="auto"
               formControlName="simCard">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySimCard">
          <mat-option *ngFor="let card of filteredSimCards$ | async" [value]="card">
            {{ displaySimCard(card) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="static-section-container"  formGroupName="installationDateTime" style="width: 100%">
        <div class="calendar-container" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="32px">
          <div class="primeng-form-control" fxFlex="50">
            <p-calendar [showIcon]="true" formControlName="date" [dateFormat]="form.viewDateFormat()" readonlyInput="true"
                        placeholder="Data zamontowania" dataType="string">
            </p-calendar>
          </div>
          <div class="primeng-form-control" fxFlex="50">
            <p-calendar class="time-only" [timeOnly]="true" formControlName="time"
                        placeholder="Godzina zamontowania" dataType="string">
            </p-calendar>
          </div>
        </div>
      </div>
      <div fxFlex fxLayoutAlign="end center" fxLayoutGap="5px">
        <button mat-stroked-button color="warn" type="button" (click)="removeSimCardFromTerminal(terminalSimCard)"
                *ngIf="currentTerminalActiveSimCard$ | async as terminalSimCard">
          <mat-icon class="icon-remove">delete</mat-icon> Unassign
        </button>
        <button mat-raised-button color="primary" class="mat-button" type="submit" [disabled]="!form.valid">
          Assign
        </button>
      </div>
    </form>
  </div>
</ng-container>
