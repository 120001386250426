export interface NewTransitRouteRequest {
  nazwa_trasy: string;
  czy_utworzyc_szablon: boolean;
  nazwa_szablonu: string;
  czy_prywatny: boolean;
  id_uzytkownik: string;
  id_wersja_mapy: string;
  id_przejazd: string;
  numer_porzadkowy: string;
  przelicz_trase: boolean;
  trasa_map_json: any;
  id_punkt_sped_poczatkowy: number;
  id_punkt_sped_koncowy: number;
}
