import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Router } from '@angular/router';

import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import * as Vehicles from '../../../../ngrx/vehicle.actions';

import { Marka, Model, Samochod, StanPracy, StatusPojazdu, TypZapiecia } from '../../../../models/dto/vehicle';
import { ToastType } from '../../../../helpers/enum';
import { Strings } from '../../../../helpers';
import { General } from '../../../../messages';
import { TruckForm } from '../../forms';
import { SelectOption } from '../../../shared/interfaces';
import { FormHelper } from '../../../shared/helpers';
import { TerminalMngService } from '../../../../services/terminal-mng.service';
import { ITerminalEquipment } from '../../../../models/dto/terminale';
import { DriverService } from 'src/app/modules/drivers/services';
import { KierowcaBasic, KierowcaBasicOption } from 'src/app/models/dto/user';

@Component({
  selector: 'app-truck-details',
  templateUrl: './truck-details.component.html',
  styleUrls: ['./truck-details.component.scss']
})
export class TruckDetailsComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TruckDetailsComponent');
  private driversSubject$: BehaviorSubject<KierowcaBasic[]> = new BehaviorSubject([]);
  subs = new Subscription();
  form = new TruckForm();

  truck: Samochod = null;

  terminalEq$: Observable<ITerminalEquipment[]> = this.store
    .select(fromRoot.selectors.devices.getTerminalTruck)
    .pipe(
      switchMap((terminalTrucks) => {
        const truckTerminalId =
          terminalTrucks.find(
            (terminalTruck) => terminalTruck._samochod?.id === this.truck?.id
          )?._terminal.id || null;

        if (truckTerminalId) {
          return this.terminalService.getTerminalEquipment(truckTerminalId);
        } else {
          return of(null);
        }
      })
    );
  drivers1$: Observable<KierowcaBasicOption[]> = this.filterDriversBy(
    'id_kierowca_2'
  ).pipe(map(this.mapDriversToOptions));
  drivers2$: Observable<KierowcaBasicOption[]> = this.filterDriversBy(
    'id_kierowca_1'
  ).pipe(map(this.mapDriversToOptions));

  mode = '';
  truckVendorControl = new FormControl();

  vehicleModels: Model[] = [];
  vehicleFilteredModelsLov: SelectOption<Model>[] = [];
  vehicleVendorsLov: SelectOption<Marka>[] = [];

  connectTypeListLov: SelectOption<TypZapiecia>[] = [];
  vehicleStateLov: SelectOption<StatusPojazdu>[] = [];
  truckWorkStateLov: SelectOption<StanPracy>[] = [];

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private terminalService: TerminalMngService,
    private driverService: DriverService
  ) {
  }

  ngOnInit(): void {
    this.driverService.getDriversList().subscribe(list => {
      this.driversSubject$.next(list);
    });

    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.ui.getRightDrawerInfo),
        this.store.select(fromRoot.selectors.vehicles.getModels),
        this.store.select(fromRoot.selectors.vehicles.getVendors),
        this.store.select(fromRoot.selectors.vehicles.getConnTypes),
        this.store.select(fromRoot.selectors.vehicles.getWorkStates),
        this.store.select(fromRoot.selectors.vehicles.getVehicleStates),
      ]).pipe(
        filter(data => (typeof data[0].componentName !== 'undefined' && data[0].componentName === TruckDetailsComponent.myname)
          || (data[0].isOpened === false && this.truck !== null)
          || (this.truck && this.truck.nr_rej !== data[0].componentData.nr_rej)),
        filter(f =>
          !!f[0].componentData
          && f[1] && f[1].length > 0
          && f[2] && f[2].length > 0
          && f[3] && f[3].length > 0
          && f[4] && f[4].length > 0
          && f[5] && f[5].length > 0
        )
      ).subscribe(([drawerInfo, modelList, markList, connTypes, workStates, vehicleStatus]) => {
        this.mode = drawerInfo.componentData.mode;
        if (this.mode === 'create') {
          this.mode = drawerInfo.componentData.mode;
        }
        if (this.mode === 'edit') {
          this.truck = drawerInfo.componentData.truck as Samochod;
        }

        this.vehicleModels = modelList;
        this.vehicleVendorsLov = FormHelper.makeSelectLov(markList, 'marka');
        this.vehicleStateLov = FormHelper.makeSelectLov(vehicleStatus, 'status');
        this.connectTypeListLov = FormHelper.makeSelectLov(connTypes, 'nazwa');

        const lov = [...workStates]
          .filter(item => item.dotyczy_pojazdu === true || (this.truck && this.truck._akt_stan_pracy.id === item.id));
        this.truckWorkStateLov = FormHelper.makeSelectLov(lov, 'stan_pracy', ' ', (v: StanPracy) => v.dotyczy_pojazdu === false);
        this.initForm();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.router.navigateByUrl('/dictionaries/vehicles/menu/truck/list');
  }

  vendorSelected(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      const lov = this.vehicleModels
        .filter(model => model._marka.id === event.source.value.id);
      this.vehicleFilteredModelsLov = FormHelper.makeSelectLov(lov, 'model');

      if (lov.length < 1) {
        this.store.dispatch(UI.showUserMessage({message: {
          type: ToastType.ERROR,
          message: 'No models found for selected brand!',
        }}));
      }
    }
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    const truckInfo = this.form.newTruckInfo;
    if (!!this.form.newTruckInfo.id) {
      this.store.dispatch(
        new Vehicles.EditVehicleRequest({
          record: truckInfo,
          callback: () => {
            this.store.dispatch(UI.showUserMessage({message: {
              message: General.OPERATION_SUCCESS,
              type: ToastType.SUCCESS,
            }}));
          }
        })
      );
    } else {
      const truck: Samochod = {
        ...truckInfo,
        akt_ryzyko: 0,
        usuniety: false,
        w_trakcie_przejazdu: false,
        w_zestawie: false,
        czy_ciagnik: true,
        czy_osobowy: false,
      } as Samochod;
      this.store.dispatch(new Vehicles.AddTruckRequest({truck}));
    }
  }

  compareDrivers(id1: number | null, id2: number | null): boolean {
    return id1 === id2;
  }

  private initForm(): void {
    if (this.truck) {
      this.truckVendorControl.patchValue(this.truck._model._marka);
      const lov = this.vehicleModels
        .filter(model => model._marka.id === this.truck._model._marka.id);
      this.vehicleFilteredModelsLov = FormHelper.makeSelectLov(lov, 'model');

      const currentModel = lov.find(m => m.id === this.truck._model.id);
      this.form.patchFromModel(this.truck, currentModel);
    }
  }

  private mapDriversToOptions(drivers: KierowcaBasic[]): KierowcaBasicOption[] {
    return [
      {
        label: '-',
        value: null,
      },
      ...drivers.map((driver) => ({
        label: `${driver._uzytkownik.imie} ${driver._uzytkownik.nazwisko}`,
        value: driver.id,
      })),
    ];
  }

  private filterDriversBy(
    controlName: string
  ): Observable<KierowcaBasic[]> {
    return combineLatest([
      this.driversSubject$.asObservable(),
      this.form.get(controlName).valueChanges.pipe(startWith(-1)),
    ]).pipe(
      map(([drivers, driverId]: [KierowcaBasic[], number]) =>
        drivers.filter((driver) => driver.id !== driverId)
      )
    );
  }
}
