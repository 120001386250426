import { Terminal } from './terminal';

export interface TerminalNaczepa {
  id?: number;
  _naczepa: { id: number, nr_rej: string };
  _terminal: Terminal;
  czas_zamont: string;
  czas_usuniecia?: null;
  podstawowy?: boolean;
}
