export interface Coords {
  gpsNs: number;
  gpsEw: number;
}

// todo: do wywalenia jak zmiany zostaną wprowadzone w API
export interface CoordsU {
  gps_ns: number;
  gps_ew: number;
}

export interface CoordsMap {
  lat: number;
  lng: number;
}

export interface CoordsNamed {
  latitude: number;
  longitude: number;
}

// todo: do wywalenia po aktualizacji bazy oraz API
export interface CoordsNamedPL {
  szerokosc: number;
  dlugosc: number;
}

// todo: do wywalenia po aktualizacji bazy oraz API
export interface CoordsExtended extends CoordsNamedPL {
  azymut: number;
  wysokosc?: number;
}
