<div fxFlex="80%" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start" class="center-container">
  <div fxLayout="column" fxFlex="1 1 0">
    <h3>Given permissions</h3>
    <div fxFlex fxLayout="column" fxLayoutGap="5px">
      <section *ngFor="let p of userPrivs" [class.disabled]="!p._upr.aktywne" class="privilege"
               fxFlex fxLayout="row" fxLayoutGap="5px">
        <span fxFlex="0 1 40px">{{p._upr.id}}</span>
        <span>{{p._upr.nazwa}}</span>
        <span fxFlex></span>
        <mat-icon class="icon-remove" (click)="removeProvFromUser(p)">delete</mat-icon>
      </section>
    </div>
  </div>
  <div fxLayout="column" fxFlex="1 1 0">
    <h3>Available permissions</h3>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Search..." [formControl]="searchControl">
    </mat-form-field>
    <div fxFlex fxLayout="column" fxLayoutGap="5px">
      <section *ngFor="let p of filtered | slice:0:maxNum" [class.disabled]="!p.aktywne" class="privilege"
               fxFlex fxLayout="row" fxLayoutGap="5px">
        <span fxFlex="0 1 40px">{{p.id}}</span>
        <span>{{p.nazwa}}</span>
        <span fxFlex></span>
        <mat-icon class="icon-add" (click)="addPrivToUser(p)">add</mat-icon>
      </section>
    </div>
    <div class="show-elements-control">
      <span *ngIf="maxNum != -1; else mniej" (click)="maxNum = -1">Show all... (+)</span>
      <ng-template #mniej>
        <span (click)="maxNum = 10">Show less... (-)</span>
      </ng-template>
    </div>
  </div>
</div>
