import { MapPoint } from './map-point';

export interface MapDragEvent {
  target: {
    getGeometry: () => unknown
    setGeometry: (coord) => unknown
    getData: () => MapPoint
  };
  viewportX: number;
  viewportY: number;
  currentPointer: { viewportX: number; viewportY: number };
}
