<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxFlex="75%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <app-input label="Search" suffixIcon="search" name="fdw-trailer-Search"
               placeholder="Type at least 3 chars..."
               [control]="searchControl"></app-input>
    <app-multiselect fxFlex="50%" [lov]="vehStateLov$ | async" [control]="vehStateControl" label="State"></app-multiselect>

  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <button mat-raised-button color="primary" routerLink='create' routerLinkActive="active" title="Add new entry">
      Add new
    </button>
    <button mat-icon-button title="Refresh data" (click)="reloadData()">
      <mat-icon aria-label="icon-button icon">refresh</mat-icon>
    </button>
  </div>
  <div style="clear: both;"> </div>
</div>
<router-outlet></router-outlet>
