import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import * as fromRoot from '../../../../app.reducer';

import { Samochod } from '../../../../models/dto/vehicle';
import { TruckDetailsComponent } from '../truck-details/truck-details.component';
import { RightDrawerService } from '../../../shared/services';
import { Strings } from '../../../../helpers';
import { RemoveVehicleRequest } from '../../../../ngrx/vehicle.actions';
import { Privs } from '../../../../helpers/enum';
import { BaseYesNoConfig } from '../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../shared/dialogs';

@Component({
  selector: 'app-truck-list',
  templateUrl: './truck-list.component.html',
  styleUrls: ['./truck-list.component.scss']
})
export class TruckListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  trucksInfo: MatTableDataSource<Samochod>;
  displayedColumns: string[] = ['Company', 'Reg. no.', 'Model', 'Work status', 'Vehicle status', 'Buckle', 'Set alloted', 'Actions'];
  truckDetailsPlates$: Observable<string>;
  subs = new Subscription();
  objHash = '';
  ePrivs = Privs;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog,
              private route: ActivatedRoute, private rightDrawer: RightDrawerService) {
    this.truckDetailsPlates$ = this.route.paramMap
      .pipe(map(r => r.get('plates')));
  }

  ngOnInit() {
    this.trucksInfo = new MatTableDataSource<Samochod>([]);
    this.trucksInfo.sort = this.sort;
    this.trucksInfo.sortingDataAccessor = (item: Samochod, property) => {
      switch (property) {
        case 'idFirma':
          return item._firma?.nazwa;
        case 'Reg. no.':
          return item.nr_rej;
        case 'Model':
          return item._model.model;
        case 'Work status':
          return item._akt_stan_pracy.stan_pracy;
        case 'Vehicle status':
          return item._status.status;
        case 'Buckle':
          return item._typ_zapiecia.id;
        case 'Set alloted':
          return item.w_zestawie;
        default:
          return item[property];
      }
    };

    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.vehicles.getTrucks),
        this.truckDetailsPlates$,
        this.store.select(fromRoot.selectors.vehicles.getVehicleSearch),
      ])
        .pipe(
          filter(r => this.objHash !== Strings.getObjectHash(r) && r.length > 0),
          tap(r => this.objHash = Strings.getObjectHash(r))
        )
        .subscribe(([truckList, plateNum, searchCond]) => {
          let filteredList = truckList;
          if (searchCond.kind === 'truck') {
            if (searchCond.term.length > 0) {
              filteredList = [...filteredList]
                .filter(t => Strings.searchTextIgnoreAccents(
                  searchCond.term,
                  t.nr_rej + t._model.model + t._status.status + t._model._marka.marka + t.uwagi
                ) >= 0);
            }

            if (searchCond.states.length > 0) {
              filteredList = [...filteredList].filter(t => searchCond.states.includes(t._status.id));
            }
          }

          this.trucksInfo.data = filteredList;
          if (!!plateNum && plateNum !== 'new') {
            this.rightDrawer.openOver(
              TruckDetailsComponent,
              {truck: truckList.find(f => f.nr_rej === plateNum), mode: 'edit'}
            );
          } else if (!!plateNum && plateNum === 'create') {
            this.rightDrawer.openOver(TruckDetailsComponent, {mode: 'create'});
          }
        })
    );
  }

  delete(vehicle: Samochod): void {
    const config: BaseYesNoConfig = {
      title: 'Truck deletion',
      content: `Are you sure you want to delete truck '${vehicle.nr_rej}'?`,
      yesAction: () => this.store.dispatch(new RemoveVehicleRequest({vehicle})),
      yesLabel: 'Delete',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-TruckRemoval',
      position: {top: '7%'}
    });
  }

  ngAfterViewInit(): void {
    this.trucksInfo.sort = this.sort;
    this.trucksInfo.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.rightDrawer.close();
    this.subs.unsubscribe();
  }
}
