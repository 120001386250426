import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromDriver from '../ngrx/driver.reducer';
import * as DRIVER from '../ngrx/driver.actions';
import { Router } from '@angular/router';

const BACK_BUTTON_ROUTES = ['driver/create', 'driver/edit'];

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss'],
})
export class DriverComponent implements OnInit {
  constructor(private store: Store<fromDriver.State>, private router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(new DRIVER.LoadDriversRequest());
    this.store.dispatch(new DRIVER.LoadDrivingLicensesRequest());
  }

  get shouldDisplayBackButton(): boolean {
    return (
      this.router.url.match(new RegExp(BACK_BUTTON_ROUTES.join('|'), 'gm')) !==
      null
    );
  }
}
