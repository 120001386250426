import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as fromRoot from '../../../../app.reducer';
import * as TERMINAL from '../../../../ngrx/terminal.actions';
import { Ramka, WersjaRamki } from '../../../../models/dto/terminale';
import { positiveDigitOnly } from '../../../../helpers/validators/custom.validators';

@Component({
  selector: 'app-frame-version-edit-dialog',
  templateUrl: './frame-version-edit-dialog.component.html',
  styleUrls: ['./frame-version-edit-dialog.component.scss']
})
export class FrameVersionEditDialogComponent implements OnInit {

  formEdit = this.fb.group({
    wersja: ['', [Validators.required, positiveDigitOnly]],
    typ: ['', Validators.required],
    active: [true, Validators.required]
  });

  constructor(private store: Store<fromRoot.State>, private fb: FormBuilder,
              public dialogRef: MatDialogRef<FrameVersionEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { wersja: WersjaRamki, ramka: Ramka }) {
  }

  ngOnInit(): void {
    this.formEdit.patchValue({
      wersja: this.data.wersja.wersja,
      typ: this.data.wersja.typ,
      active: this.data.wersja.active
    });
  }

  zapisz() {
    if (this.formEdit.invalid) {
      return false;
    }

    const obj = this.formEdit.value;
    obj['wersja'] = parseInt(obj['wersja'], 10);

    const updWersja = {...this.data.wersja, ...obj};
    this.store.dispatch(new TERMINAL.UpdateFrameVersionRequest({
      wersja: updWersja,
      ramka: this.data.ramka,
      succCallback: () => {
        this.dialogRef.close({...updWersja});
      }
    }));
  }

  anuluj() {
    this.dialogRef.close();
    return false;
  }

}
