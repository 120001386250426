import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import * as TERMINAL from '../../../../ngrx/terminal.actions';

import { OprogramowanieTerminala, Terminal } from '../../../../models/dto/terminale';
import { DateFormat } from '../../../../helpers/enum';

@Component({
  selector: 'app-upgrade-terminal',
  templateUrl: './upgrade-terminal.component.html',
  styleUrls: ['./upgrade-terminal.component.scss']
})
export class UpgradeTerminalComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  oprogramowanieTerminali: OprogramowanieTerminala[] = [];
  oprogramowanie: OprogramowanieTerminala;
  rozmiarPaczki = 512;
  rozmiary = [64, 128, 512, 1024, 1280];
  df = DateFormat;
  terminal: Terminal;

  constructor(
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public terminalId: number,
    public dialog: MatDialog
  ) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getSoftwareList),
        this.store.select(fromRoot.selectors.devices.getTerminalById(this.terminalId))
      ])
        .pipe(
          filter(r => r[0] !== undefined && r[0].length > 0
            && r[1] !== undefined),
          take(1)
        )
        .subscribe(([softwareList, terminal]) => {
          this.oprogramowanieTerminali = softwareList.filter(o => !o.wycofane || o.id === terminal._opr.id);
          this.oprogramowanie = softwareList.find(s => s.id === terminal._opr.id);
          this.terminal = terminal;
        })
    );
  }

  onSubmit() {
    if (this.oprogramowanie.wycofane) {
      this.store.dispatch(UI.userError({message: 'Attempted to send discontinued software!'}));
      return false;
    }
    this.store.dispatch(new TERMINAL.UpgradeSoftwareRequest({
      termId: this.terminalId,
      softCode: this.oprogramowanie.oznaczenie,
      packetSize: this.rozmiarPaczki
    }));
    this.dialog.closeAll();
  }
}
