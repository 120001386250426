import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alertState'
})
export class AlertStatePipe implements PipeTransform {

  transform(value: boolean): string {
    return value ? 'tak' : 'nie';
  }

}
