import { Component } from '@angular/core';
import { ITileItem } from '../../shared/interfaces/tile-item';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  menuItems: ITileItem[] = [
    {link: 'truck', label: 'Trucks', imageSrc: 'assets/img/truck_h110.png'},
    {link: 'trail', label: 'Trailers', imageSrc: 'assets/img/trail_h110.png'},
    {link: 'base-type', label: 'Settings', imageSrc: 'assets/img/handyman.png'}
  ];

}
