export interface Uprawnienie {
  id?: number;
  _upr_nadrz?: string | Uprawnienie;
  nazwa: string;
  aktywne: boolean;
  odsw_proxy: boolean;
  get: boolean;
  post: boolean;
  put: boolean;
  patch: boolean;
  delete: boolean;
  children?: Uprawnienie[];
  visible?: boolean;
}
