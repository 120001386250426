<div fxFlex fxLayout="row" fxLayoutGap="10px" *ngIf="ramka" class="content">
  <div class="mat-elevation-z8 column" fxLayout="column" fxLayoutGap="5px">
    <h2>Frame info</h2>
    <div fxLayout="row" fxLayoutGap="10px">
      <span class="etykieta">Ramka</span>
      <span>{{ramka.nazwa}} / {{wersja.typ}} / {{wersja.wersja}}</span>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <span class="etykieta">In/Out</span>
      <span [textContent]="ramka.doSerwera ? 'Incoming' : 'Outgoing'"></span>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <span class="etykieta">Num of fields</span>
      <span>{{wersja.poleRamkis.length}}</span>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <span class="etykieta" fxLayoutAlign="start center">Status</span>
      <span>
        <mat-chip-list>
        <mat-chip removable="false" selectable="false"
                  [textContent]="wersja.active ? 'Active' : 'Deprecated'"
                  color="accent"></mat-chip>
        </mat-chip-list>
      </span>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <button mat-raised-button color="primary" (click)="edytujWersje()">Edit</button>
    </div>

  </div>
  <div class="mat-elevation-z8 column" fxLayout="column" fxLayoutGap="5px">
    <h2>Fields</h2>
    <table class="border-collapsed">
      <thead>
      <tr>
        <th>Name</th>
        <th>Bit from (Bajt)</th>
        <th>Bit to</th>
        <th>Length</th>
        <th>Type</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let pole of wersja.poleRamkis" (click)="edytujPole(pole)" class="table-row hover">
        <td>{{pole.nazwaPola}}</td>
        <td>{{pole.bitOd}} ({{(pole.bitOd / 8 + 1) | number:'1.0-0'}})</td>
        <td>{{pole.bitDo}}</td>
        <td>{{pole.bitDo - pole.bitOd + 1}}</td>
        <td>{{pole.typDanych.typ}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="validatorError"
       class="mat-elevation-z8 column" fxLayout="column" fxLayoutGap="5px">
    <h2>Validation</h2>
    <span class="label error" *ngIf="validators.dubleNazwy.length > 0">
      fields names are not unique!
      <ul>
        <li *ngFor="let p of validators.dubleNazwy">{{p}}</li>
      </ul>
    </span>
    <span class="label error" *ngIf="validators.dublePolaBazy.length > 0">
      DB fields names are not unique!
      <ul>
        <li *ngFor="let p of validators.dublePolaBazy">{{p}}</li>
      </ul>
    </span>
    <div fxLayout="row" fxLayoutGap="5px" *ngIf="validators.nakladajaceBity || validators.wolneBity">
      <div fxLayout="column" fxLayoutGap="5px">
        <span class="label error" *ngIf="validators.nakladajaceBity">Fields are overlapping!</span>
        <span class="label warning" *ngIf="validators.wolneBity">Gaps between fields</span>
      </div>
    </div>
  </div>
</div>
