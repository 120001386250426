<div mat-dialog-title>
  <span>Locker management</span>
</div>
<div *ngIf="!isLockerPresent" mat-dialog-actions>
  <button mat-raised-button matTooltip="Click to associate locker to terminal" (click)="isLockerPresent = true">
    <mat-icon>add</mat-icon>
    Locker not associated
  </button>
</div>
<form [formGroup]="lockerForm" (ngSubmit)="saveOrUpdate()" *ngIf="isLockerPresent">

  <div mat-dialog-content>

    <div class="content" fxLayout="column" fxLayoutGap="10px">
      <mat-form-field *ngIf="data.terminal.rygiels?.length">
        <mat-label>Last info</mat-label>
        <input matInput readonly [value]="lockerForm.get('czas').value | date : df.PIPE_DATETIME_LONGER_LABEL">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="nazwa">
      </mat-form-field>
      <mat-form-field>
        <mat-label>S/N</mat-label>
        <input matInput formControlName="oznakowanie">
      </mat-form-field>
      <mat-form-field *ngIf="rygiel">
        <mat-label>Status</mat-label>
        <mat-select formControlName="_statusRygla" [compareWith]="compareById">
          <mat-option *ngFor="let opr of statusList" [value]="opr">{{opr.status | titlecase}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>
      Cancel
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="lockerForm.invalid">
      Save
    </button>
  </div>
</form>
