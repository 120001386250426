<mat-accordion>
  <mat-expansion-panel [expanded]="true" class="first">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Trailer type
      </mat-panel-title>
      <mat-panel-description>
        Edit trailer types
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-trail-type></app-trail-type>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Connection type
      </mat-panel-title>
      <mat-panel-description>
        Edit connection types
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-connection-type></app-connection-type>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Vehicle type
      </mat-panel-title>
      <mat-panel-description>
        Avail veh. types
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-vehicle-type></app-vehicle-type>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Unavailability reasons
      </mat-panel-title>
      <mat-panel-description>
        Trailer / Truck
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-unavail-reason></app-unavail-reason>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Models
      </mat-panel-title>
      <mat-panel-description>
        Trailer / Truck
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-vehicle-model></app-vehicle-model>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Makes
      </mat-panel-title>
      <mat-panel-description>
        Trailer / Truck
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-vehicle-vendor></app-vehicle-vendor>
  </mat-expansion-panel>
</mat-accordion>
