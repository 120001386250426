
<div class="transport-set-filters">
  <mat-form-field appearance="outline" class="w-100 mt-1">
    <mat-label>Select trailer</mat-label>
    <input
      [formControl]="filterSets"
      [matAutocomplete]="setsAutocomplete"
      placeholder="Filter trailers"
      type="text"
      class="mat-body-2"
      matInput />

    <mat-autocomplete
      #setsAutocomplete="matAutocomplete"
      [displayWith]="trailerDetailsText"
      class="sets-list"
      (optionSelected)="selectSet($event)">
      <mat-option
        *ngFor="let element of transportSetList$ | async"
        [value]="element"
        class="sets-list-option">
        <ng-container *ngTemplateOutlet="trailerOption; context: {$implicit: element}"></ng-container>
      </mat-option>
    </mat-autocomplete>

    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<ng-template #trailerOption let-element>
  <div class="sets-list-option-wrapper">
    <div class="sets-list-option-wrapper-trailer-info">
      <p class="sets-list-option-wrapper-trailer-info-id">
        Id: {{ element?.id }}
      </p>

      <terminal-veh-reg-number
        [vehType]="vehicleType.BRAND">
        {{(element.trailerSet | vehicleFromTransit)?.model?.marka?.marka || 'No data' }}
      </terminal-veh-reg-number>

      <terminal-veh-reg-number
        [vehType]="vehicleType.PLATE">
        {{(element.trailerSet | vehicleFromTransit)?.nrRej || 'No data' }}
      </terminal-veh-reg-number>

      <terminal-veh-reg-number
        [vehType]="vehicleType.VEHICLE_ID">
        {{(element.trailerSet | vehicleFromTransit)?.idPojazduFirmy || 'No data' }}
      </terminal-veh-reg-number>
    </div>

    <p class="sets-list-option-wrapper-trailer-remarks">
      Remarks: <i>{{ element?.uwagi }}</i><br />
      <b>Currently connected truck</b> - {{ getVehicleInfo(element.truckSet | vehicleFromTransit) }}
    </p>
  </div>
</ng-template>
