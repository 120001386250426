import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Uzytkownik } from '../../../models/dto/user';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { ITileItem } from '../../shared/interfaces/tile-item';
import * as USER from '../../../ngrx/user.actions';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menuItems: ITileItem[] = [
    {link: 'userdata', label: 'User data', imageSrc: 'assets/img/folder.png'},
    {link: 'password-change', label: 'Password change', imageSrc: 'assets/img/password.png'},
    {link: 'privileges', label: 'Privileges', imageSrc: 'assets/img/privileges.png'}
  ];
  selectedUser$: Observable<Uzytkownik>;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>) {
    this.route.params.subscribe(res => {
      const selectedUsername = res.username;
      this.store.dispatch(new USER.LoadUserByLoginRequest(selectedUsername));
      this.store.dispatch(new USER.LoadCompanyRolesRequest());
    });
  }

  ngOnInit(): void {
    this.selectedUser$ = this.store.select(fromRoot.selectors.user.getSelectedUserInfo);
  }
}
