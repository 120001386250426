import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { PoleRamki, PoleRamkiTypDanych, Ramka, WersjaRamkiRequest } from '../../../../../models/dto/terminale';
import { positiveDigitOnly } from '../../../../../helpers/validators/custom.validators';
import { FrameFieldEditDialogComponent } from '../../../dialogs/frame-field-edit-dialog/frame-field-edit-dialog.component';
import * as fromRoot from '../../../../../app.reducer';
import * as TERMINAL from '../../../../../ngrx/terminal.actions';

interface ProstaKonfiguracja {
  nazwa: string;
  ramkaId?: number;
  typ: string;
  wersja: number;
  pola: PoleRamki[];
  status: boolean;
}

@Component({
  selector: 'app-config-creator',
  templateUrl: './config-creator.component.html',
  styleUrls: ['./config-creator.component.scss']
})
export class ConfigCreatorComponent implements OnInit {

  validatorError = true;

  validators = {
    dubleNazwy: [],
    dublePolaBazy: [],
    nakladajaceBity: false,
    wolneBity: false,
  };
  subs = new Subscription();
  ramki: Ramka[] = [];
  typyPol: PoleRamkiTypDanych[] = [];

  konfiguracje: ProstaKonfiguracja[] = [];
  dostepneWersje = new FormControl('');

  polaRamki: PoleRamki[] = [];
  prostaKonfiguracja: ProstaKonfiguracja;

  myForm = this.fb.group({
    nazwa: [''],
    typ: ['', Validators.required],
    wersja: ['', [Validators.required, positiveDigitOnly]],
  });

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.dostepneWersje.valueChanges.subscribe(r => {
        if (r) {
          this.prostaKonfiguracja = r;
          const ostatniaWersja = this.konfiguracje
            .filter(k => k.typ === r.typ && k.nazwa === r.nazwa)
            .reduce((a, b) => a.wersja > b.wersja ? a : b);
          this.myForm.patchValue({
            nazwa: r.nazwa,
            typ: r.typ,
            wersja: ostatniaWersja.wersja + 1
          });
          this.polaRamki = [...this.konfiguracje
            .find(k =>
              k.typ === r.typ
              && k.wersja === r.wersja
              && k.nazwa === r.nazwa
            )
            .pola].map(p => {
            const rr = {...p};
            delete rr.id;
            return rr;
          }).sort((a, b) => a.bitOd > b.bitOd ? 1 : -1);
          this.sprawdzPoprawnoscPol();
        } else {
          this.myForm.patchValue({
            nazwa: '',
            typ: '',
            wersja: ''
          });
          this.polaRamki = [];
        }
      })
    );

    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getFrames),
        this.store.select(fromRoot.selectors.devices.getFrameFieldTypes)
      ]).pipe(
        filter(f => f[0].length > 0 && f[1].length > 0),
        map(w => {
          w[0] = [...w[0]].sort((a, b) => a.nazwa > b.nazwa ? 1 : -1);
          return w;
        })
      )
        .subscribe(([frames, fieldTypes]) => {
          this.ramki = frames;
          this.typyPol = fieldTypes;
          this.ramki.forEach(r => {
            r.wersjaRamkis.forEach(w => {
              const conf: ProstaKonfiguracja = {
                ramkaId: r.id,
                nazwa: r.nazwa,
                typ: w.typ,
                wersja: w.wersja,
                pola: w.poleRamkis,
                status: w.active
              };
              this.konfiguracje = [...this.konfiguracje, conf];
            });
          });
        })
    );
  }

  edytujPole(idx: number) {
    const pole = this.polaRamki[idx];
    this.dialog.open(FrameFieldEditDialogComponent,
      {
        data: {pole: pole, typy: this.typyPol},
        minWidth: '250px'
      }
    ).afterClosed().subscribe((result) => {
      if (result && result.nazwaPola) {
        this.polaRamki[idx] = result;
        this.polaRamki = this.polaRamki.sort((a, b) => a.bitOd > b.bitOd ? 1 : -1);
      } else if (result === true) {
        this.polaRamki.splice(idx, 1);
      }
      this.sprawdzPoprawnoscPol();
    });
  }

  nowePole() {
    this.dialog.open(FrameFieldEditDialogComponent,
      {
        data: {typy: this.typyPol},
        minWidth: '250px'
      }
    ).afterClosed().subscribe((result) => {
      if (result && result.nazwaPola) {
        this.polaRamki.push(result);
        this.polaRamki = this.polaRamki.sort((a, b) => a.bitOd > b.bitOd ? 1 : -1);
      }
      this.sprawdzPoprawnoscPol();
    });
  }

  zapiszNowaWersje() {
    if (this.myForm.invalid) {
      return false;
    }

    const nowaKofiguracja: WersjaRamkiRequest = {
      active: true,
      wersja: parseInt(this.myForm.value.wersja, 10),
      ramka: '/api/ramka/' + this.prostaKonfiguracja.ramkaId,
      typ: this.myForm.value.typ,
      poleRamkis: this.polaRamki
    };

    this.store.dispatch(new TERMINAL.NewFrameVersionRequest({version: nowaKofiguracja}));
  }

  public poprawBity() {
    this.polaRamki = [...this.polaRamki].map((ob, i) => {
      if (i < 1) {
        return ob;
      }
      const pop = this.polaRamki[i - 1];
      const rozm = ob.bitDo - ob.bitOd;

      ob.bitOd = pop.bitDo + 1;
      ob.bitDo = ob.bitOd + rozm;

      return ob;
    });

    this.sprawdzPoprawnoscPol();
  }

  private sprawdzPoprawnoscPol() {
    this.validators = {
      dubleNazwy: [],
      dublePolaBazy: [],
      nakladajaceBity: false,
      wolneBity: false
    };

    this.polaRamki.forEach((p, idx) => {
      const idxDB = this.polaRamki
        .findIndex((q, i) => {
          if (i <= idx || !p.db || !q.db) {
            return false;
          }

          return p.db.trim().length > 0 && q.db.trim().length > 0 && q.db.toLowerCase() === p.db.toLowerCase();
        });
      if (idxDB >= 0) {
        this.validators.dublePolaBazy.push(p.nazwaPola + ' | ' + this.polaRamki[idxDB].nazwaPola);
      }

      const idxNAZWA = this.polaRamki
        .findIndex((q, i) => i > idx && q.nazwaPola.toLowerCase() === p.nazwaPola.toLowerCase());
      if (idxNAZWA >= 0) {
        this.validators.dubleNazwy.push(this.polaRamki[idxNAZWA].nazwaPola);
      }

      if (idx + 1 < this.polaRamki.length) {
        const kolejny = this.polaRamki[idx + 1];
        this.validators.nakladajaceBity = this.validators.nakladajaceBity || p.bitDo >= kolejny.bitOd;
        this.validators.wolneBity = this.validators.wolneBity || p.bitDo + 1 !== kolejny.bitOd && kolejny.bitOd > p.bitDo;
      }
    });

    this.validatorError = this.validators.nakladajaceBity
      || this.validators.dubleNazwy.length > 0 || this.validators.dublePolaBazy.length > 0;
  }

}
