import { RygielStatus } from './rygiel-status';

export interface Rygiel {
  id?: number;
  data_prod_rygla: string;
  oznakowanie?: string;
  _statusRygla: RygielStatus;
  _terminal?: { id: number };
  nazwa: string;
  czas: string;
  zamkniety: boolean;
  otwarty: boolean;
  otwieranie: boolean;
  zamykanie: boolean;
  pozycja: boolean;
}
