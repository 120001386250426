<div fxFlex fxLayout="column" fxLayoutAlign="start center" class="content">
  <div fxLayout="column" fxLayoutGap="8px" class="cards">
    <div *ngIf="idKierowca > 0">
      <app-driver-create [idKierowcy]="idKierowca"></app-driver-create>
    </div>
    <div>
      <mat-card class="pin-card">
        <mat-card-header>PIN</mat-card-header>
        <mat-card-content>
          <p>
            These are driver PIN codes. You can see them only once.<br />
            If you forget them you can re-generate pin codes by clicking Generate new PINs button.
          </p>
          <driver-pins class="pin-viewer" [pins]="pinsToDisplay$ | async"></driver-pins>

          <button
            mat-raised-button
            [matMenuTriggerFor]="generateMenu"
            type="button"
            color="accent">
            Generate new PINs
          </button>

          <mat-menu #generateMenu="matMenu">
            <button mat-menu-item
            (click)="generateNewPins()">
              Both PIN codes
            </button>
            <button mat-menu-item
            (click)="generateNewPins('pin')">
              Tablet PIN
            </button>
            <button mat-menu-item
            (click)="generateNewPins('pinca')">
              Alarm PIN
            </button>
          </mat-menu>
        </mat-card-content>

        <mat-card-footer>
          <mat-progress-bar
            *ngIf="pinGenerationInProgress$ | async"
            color="accent"
            mode="indeterminate">
          </mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <mat-card-header>Driving license</mat-card-header>
        <mat-card-content fxLayout="column" fxLayoutGap="15px">
          <mat-divider></mat-divider>
          <mat-list *ngIf="kierowca$">
            <mat-list-item *ngFor="let kat of (kierowca$ | async)?._kategorie" (click)="licenseEdit(nowaKategoria, kat)"
                           class="table-row hover">
              <span class="kategoria">{{kat._kategoria.kategoria}}</span>
              <span>Issued: {{kat.data_wydania}}</span>
              <span>Valid until:
                <span [innerHTML]="kat.data_waznosci || '<i>Undefined</i>'"></span>
              </span>
            </mat-list-item>
          </mat-list>
          <form [formGroup]="myForm" fxLayout="column" fxLayoutGap="10px">
            <div #nowaKategoria [hidden]="true" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start start">
              <mat-form-field class="kategoria">
                <mat-label>Cat.</mat-label>
                <mat-select formControlName="_kategoria" [compareWith]="licenseCompare">
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let kat of kategorie$ | async" [value]="kat"
                              [matTooltip]="kat.opis">
                    {{kat.kategoria}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div fxLayout="column" fxFlex="30">
                <p class="label">Issuing date</p>
                <div class="primeng-form-control">
                  <p-calendar [showIcon]="true" formControlName="data_wydania" dateFormat="dd.mm.yy"
                              [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2050"></p-calendar>
                </div>
              </div>
              <div fxLayout="column" fxFlex="30">
                <p class="label">Expiration date</p>
                <div class="primeng-form-control" fxFlex="30">
                  <p-calendar [showIcon]="true" formControlName="data_waznosci" dateFormat="dd.mm.yy"
                              [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2050"></p-calendar>
                </div>
              </div>

            </div>
            <div fxFlex fxLayoutAlign="end center" fxLayoutGap="5px">
              <button *ngIf="myForm.get('id').value" color="warn" mat-raised-button (click)="licenseRemove(nowaKategoria)">
                <mat-icon>delete</mat-icon> Remove
              </button>
              <span fxFlex></span>
              <button mat-stroked-button (click)="licenseCancel(nowaKategoria)" *ngIf="!nowaKategoria.hidden">Cancel
              </button>
              <button mat-raised-button (click)="licenseAdd(nowaKategoria)"
                      color="primary" type="submit"
                      [innerText]="nowaKategoria.hidden ? 'Add' : 'Save'">
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
