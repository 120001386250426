import { ThemePalette } from '@angular/material/core/common-behaviors/color';

export interface BaseYesNoConfig {
  title: string;
  content: string;
  yesLabel?: string;
  noLabel?: string;
  yesAction?: () => void;
  noAction?: () => void;
  yesColor?: ThemePalette;
  noColor?: ThemePalette;
  autoClosure?: boolean;
}
