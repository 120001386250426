<div>
  <form [formGroup]="form" (ngSubmit)="saveItem()" class="form-container" fxLayout="row" fxLayoutGap="15px">
    <mat-form-field appearance="outline" class="custom-form-ctrl">
      <mat-label>Enter connection type</mat-label>
      <input matInput formControlName="type" class="mat-body-2">
    </mat-form-field>
    <mat-form-field appearance="outline" class="custom-form-ctrl">
      <mat-label>Notes</mat-label>
      <textarea matInput formControlName="notes" class="mat-body-2"></textarea>
    </mat-form-field>
    <button mat-raised-button color="primary" class="accept-button mat-button" type="submit">
      {{ buttonContent }}
    </button>
  </form>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="connectionTypesInfo" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column">{{ column }}</th>
        <td mat-cell *matCellDef="let element">
          <p [ngSwitch]="column">
            <span *ngSwitchCase="'Id'">{{ element.id }}</span>
            <span *ngSwitchCase="'Type'">{{ element.nazwa }}</span>
            <span *ngSwitchCase="'Notes'">{{ element.opis }}</span>
            <span *ngSwitchDefault>
              <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="more-actions-icon">more_vert</mat-icon></button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editElement(element)">
                  <mat-icon>mode_edit</mat-icon>
                  <span class="action-description">Edit connection type</span>
                </button>
                <button mat-menu-item (click)="removeElement(element)">
                  <mat-icon>delete</mat-icon>
                  <span class="action-description">Delete connection type</span>
                </button>
              </mat-menu>
            </span>
          </p>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></tr>
    </table>
  </div>
</div>
