import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, map, take } from 'rxjs/operators';
import moment from 'moment';

import * as TRANSSET from '../../ngrx/transset.actions';
import * as UI from '../../../../ngrx/ui.actions';
import * as fromRoot from '../../../../app.reducer';

import { Kierowca, ZestawTransportowyRequestNew, ZestawTransportowyRequestNewFields } from '../../interfaces';
import { Naczepa, Samochod } from '../../../../models/dto/vehicle';
import { DriversStatus, ToastType, UserRoles } from '../../../../helpers/enum';
import { Strings } from '../../../../helpers';
import { SelectOption } from '../../../shared/interfaces';
import { FormHelper } from '../../../shared/helpers';
import { AppUserInfo } from '../../../../models/authentication';

@Component({
  selector: 'app-set-create',
  templateUrl: './set-create.component.html',
  styleUrls: ['./set-create.component.scss']
})
export class SetCreateComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('SetCreateComponent');

  trucks2Lov$: Observable<SelectOption<Samochod>[]>;
  trails2Lov$: Observable<SelectOption<Naczepa>[]>;
  driversLov$: Observable<SelectOption<Kierowca>[]>;

  formZestaw = new FormGroup({
    id: new FormControl(null),
    uwagi: new FormControl(null, Validators.required),
    samochod: new FormControl(null),
    naczepa: new FormControl(null, Validators.required),
    kierowca1: new FormControl(null, Validators.required),
    kierowca2: new FormControl()
  });

  subs = new Subscription();
  inputData: { successCallback: () => void, setId?: any };
  currentUser: AppUserInfo;

  // todo: poprawić szablon HTML - brakujące dane dla samochodu czy naczepy
  constructor(private store: Store<fromRoot.State>) {
    this.subs.add(
      this.store.select(fromRoot.selectors.auth.getUserInfo)
        .subscribe(info => this.currentUser = info)
    );

    this.subs.add(
      store.select(fromRoot.selectors.ui.getRightDrawerInfo).pipe(
        filter(data => (data.componentName !== undefined && data.componentName === SetCreateComponent.myname)),
        map(data => data.componentData)
      ).subscribe(data => {
        this.inputData = data;
        if (this.inputData?.setId) {
          this.store.select(fromRoot.selectors.transset.getFullTranssetListNewById(this.inputData.setId))
            .pipe(filter(r => r?.id !== undefined), take(1))
            .subscribe(set => {
              const truck = ([...set.truckSet] || []).sort((a, b) => a.id > b.id ? 1 : -1)[0].samochod;
              const trailer = ([...set.trailerSet] || []).sort((a, b) => a.id > b.id ? 1 : -1)[0].naczepa;

              this.formZestaw.patchValue({
                id: set.id,
                uwagi: set.uwagi,
                samochod: FormHelper.makeSelectItemWithSub(truck, ['nrRej', 'model.model'], 'uwagi', ', '),
                naczepa: FormHelper.makeSelectItemWithSub(trailer, ['nrRej', 'model.model'], 'uwagi', ', '),
                kierowca1: FormHelper.makeSelectItem(set.domKier1, ['uzytkownik.imie', 'uzytkownik.nazwisko']),
                kierowca2: FormHelper.makeSelectItem(set.domKier2, ['uzytkownik.imie', 'uzytkownik.nazwisko'])
              });
            });
        }
      })
    );
  }

  public getControl(name: string): FormControl {
    return this.formZestaw.get(name) as FormControl;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.trails2Lov$ = this.store.select(fromRoot.selectors.vehicles.getTrails)
      .pipe(map(list => FormHelper.makeSelectLovWithSub(
        list, ['nr_rej', '_model.model'], 'uwagi', ', '
      )));

    this.trucks2Lov$ = this.store.select(fromRoot.selectors.vehicles.getTrucks)
      .pipe(map(list => FormHelper.makeSelectLovWithSub(
        list, ['nr_rej', '_model.model'], 'uwagi', ', '
      )));

    this.driversLov$ = this.store.select(fromRoot.selectors.transset.getAvailableDrivers)
      .pipe(
        filter(r => r !== undefined && r.length > 0),
        map(drv => [...drv].filter(d => d.id_uzytkownik.id_rola === UserRoles.KIEROWCA && d.status === DriversStatus.READY)),
        map(list => FormHelper.makeSelectLov(list, ['id_uzytkownik.nazwisko', 'id_uzytkownik.imie']))
      );
  }

  onSubmit(): void {
    if (!this.formZestaw.valid) {
      return;
    }
    const formData = this.formZestaw.value;

    const setRequest: ZestawTransportowyRequestNewFields = {
      aktRyzyko: 0,
      aktRyzykoTerm: 0,
      blokCzas: false,
      czasKom: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      czyAktywny: true,
      czyWolny: true,
      czyZlikwidowany: false,
      idDomKier1: null,
      idDomKier2: null,
      idNaczepa: null,
      idSamochod: null,
      idFirma: +this.currentUser.companyId,
      nadzCm: false,
      nadzDysp: false,
      oczekCzasKom: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      uwagi: formData.uwagi,
      wwDziurze: false,
      zezwOdlaczeniaNaczepa: true,
      zezwOdlaczeniaSamochod: true
    };

    if (formData.samochod) {
      setRequest.idSamochod = formData.samochod.value.id;
    }
    if (formData.naczepa) {
      setRequest.idNaczepa = formData.naczepa.value.id;
    }
    if (formData.kierowca1) {
      setRequest.idDomKier1 = formData.kierowca1.value.id;
    }
    if (formData.kierowca2) {
      setRequest.idDomKier2 = formData.kierowca2.value.id;
    }

    const req: ZestawTransportowyRequestNew = {
      request: setRequest,
      callback:  () => {
        this.formZestaw.reset();
        this.store.dispatch(UI.showUserMessage({message: {type: ToastType.SUCCESS, message: 'Saved successfully'}}));
        if (this.inputData && this.inputData.successCallback) {
          this.inputData.successCallback();
        }
      }
    };

    this.store.dispatch(TRANSSET.addNewSetFullNew({request: req}));
  }
}
