import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Ramka } from '../../../../../models/dto/terminale';
import * as fromRoot from '../../../../../app.reducer';
import * as TERMINAL from '../../../../../ngrx/terminal.actions';

@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrls: ['./config-list.component.scss']
})
export class ConfigListComponent implements OnInit, OnDestroy {

  ramki: Ramka[] = [];
  subs = new Subscription();
  showAll = false;
  searchField = new FormControl();
  pokazRamke: Ramka;

  constructor(private store: Store<fromRoot.State>) {
    this.store.dispatch(new TERMINAL.LoadFramesRequest());
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.devices.getFrames)
        .pipe(
          map(r => {
            const z = r.map(w => {
              return {
                ...w, wersjaRamkis: [...w.wersjaRamkis].sort((a, b) => {
                  const o = a.typ > b.typ ? 1 : 0;
                  if (o !== 0) {
                    return o;
                  }
                  return a.wersja > b.wersja ? 1 : -1;
                })
              };
            });
            return [...z].sort((a, b) => {
              if (a.doSerwera === true) {
                return 1;
              }
              return a.nazwa > b.nazwa ? 1 : -1;
            });
          })
        )
        .subscribe(r => this.ramki = r)
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


  showFrame(ramka: Ramka) {
    if (this.pokazRamke && ramka.nazwa === this.pokazRamke.nazwa) {
      this.pokazRamke = undefined;
      return;
    }
    this.pokazRamke = ramka;
  }
}
