import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import { SamochodTerminal, Terminal, TerminalNaczepa } from '../../../../models/dto/terminale';
import { Naczepa, Samochod } from '../../../../models/dto/vehicle';
import * as TERMINALE from '../../../../ngrx/terminal.actions';

@Component({
  selector: 'app-assign',
  templateUrl: './terminal-assign.component.html',
  styleUrls: ['./terminal-assign.component.scss']
})
export class TerminalAssignComponent implements OnInit {

  public terminalAssignForm: FormGroup;
  public terminale$: Observable<Terminal[]>;
  public ciezarowki$: Observable<Samochod[]>;
  public naczepy$: Observable<Naczepa[]>;

  public typPojazdu = 'truck';

  constructor(private store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<TerminalAssignComponent>) {

    this.terminale$ = this.store.select(fromRoot.selectors.devices.getTerminals);
    this.ciezarowki$ = this.store.select(fromRoot.selectors.vehicles.getTrucks);
    this.naczepy$ = this.store.select(fromRoot.selectors.vehicles.getTrails);

    this.terminalAssignForm = new FormGroup({
      terminal: new FormControl('', Validators.required),
      samochod: new FormControl(''),
      naczepa: new FormControl('')
    });

  }

  ngOnInit() {
  }

  onSubmit() {
    const cDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');

    if (this.terminalAssignForm.invalid) {
      return;
    }

    let powiazanie: any = {
      _terminal: this.terminalAssignForm.value.terminal,
      czas_zamont: cDate
    };

    switch (this.typPojazdu.toString().toLocaleLowerCase()) {
      case 'truck': {
        powiazanie = {...powiazanie, _samochod: this.terminalAssignForm.value.samochod};

        const req = {
          device: powiazanie as SamochodTerminal,
          successCallback: () => this.dialogRef.close()
        };
        this.store.dispatch(new TERMINALE.NewTerminalTruckRequest(req));
        break;
      }

      case 'trail': {
        powiazanie = {...powiazanie as TerminalNaczepa, _naczepa: this.terminalAssignForm.value.naczepa};

        this.store.dispatch(
          new TERMINALE.NewTerminalTrailRequest({
            device: powiazanie as TerminalNaczepa,
            successCallback: () => this.dialogRef.close()
          })
        );
        break;
      }
    }

  }
}
