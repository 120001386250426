import { PoleRamkiTypDanych } from './pole-ramki-typ-danych';

export interface PoleRamki {
  id?: number;
  nazwaPola: string;
  bitOd: number;
  bitDo: number;
  typDanych: PoleRamkiTypDanych;
  opis: string;
  db?: string;
  funkcjaPrzeksztalcenia?: string;
}
