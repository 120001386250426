<div fxFlex fxLayout="column" fxLayoutAlign="start center">
  <mat-card class="driver-create-form-card">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" fxLayout="column">
      <mat-card-header>
        <span [innerHTML]="title || 'not set'"></span>
      </mat-card-header>
      <mat-card-content fxFlex fxLayout="column" fxLayoutGap="5px">
        <mat-divider></mat-divider>
        <div fxFlex xfxLayout="row" fxLayoutGap="15px" class="first-row" fxLayoutAlign="space-between center">
          <app-input fxFlex autocomplete="false" label="Name" [control]="myForm.getFormControl('imie')" name="fdw-firstname"></app-input>
          <app-input fxFlex autocomplete="false" label="Surname" [control]="myForm.getFormControl('nazwisko')" name="fdw-lastname"></app-input>
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
          <app-date-picker fxFlex autocomplete="false" label="Date of birth" name="fdw-date-of-birth"
                           [fill]="true"
                           [control]="myForm.getFormControl('data_ur')"></app-date-picker>
          <app-date-picker fxFlex autocomplete="false" label="Date of employment" name="fdw-date-of-empl"
                           [fill]="true"
                           [control]="myForm.getFormControl('data_zatr')"></app-date-picker>
        </div>

        <app-input  autocomplete="false" label="Card number" [hint]="'Important information for system functioning'" name="fdw-driver-card"
                   [control]="myForm.getFormControl('nr_karty')"></app-input>
        <app-input autocomplete="false" label="Country" name="fdw-country"
                   [control]="myForm.getFormControl('panstwo')"></app-input>

        <div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
          <app-input fxFlex autocomplete="false" label="Postal code" name="fdw-postal-code"
                     [control]="myForm.getFormControl('kod')"></app-input>
          <app-input fxFlex autocomplete="false" label="City" name="fdw-city"
                     [control]="myForm.getFormControl('miasto')"></app-input>
        </div>
        <app-input autocomplete="false" label="Street" name="fdw-street"
                   [control]="myForm.getFormControl('ulica')"></app-input>
        <div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
          <app-input fxFlex autocomplete="false" label="House no." name="fdw-house-no"
                     [control]="myForm.getFormControl('nr_d')"></app-input>
          <app-input fxFlex autocomplete="false" label="Accommodation no." name="fdw-accom-no"
                     [control]="myForm.getFormControl('nr_l')"></app-input>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
          <app-input fxFlex autocomplete="false" label="Telephone 1" [hint]="'Primary number'" name="fdw-phone1"
                     [control]="myForm.getFormControl('tel1')"></app-input>
          <app-input fxFlex autocomplete="false" label="Telephone 2" [hint]="'Backup if exists'" name="fdw-phone2"
                     [control]="myForm.getFormControl('tel2')"></app-input>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
          <mat-form-field fxFlex appearance="outline">
            <mat-label>Employee status</mat-label>
            <mat-select formControlName="status" required>
              <mat-option *ngFor="let status of driverStatus; let i = index" [value]="i">{{status}}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-input fxFlex autocomplete="false" label="Password" [hint]="'Safe answer'" name="fdw-safe-answer"
                     [control]="myForm.getFormControl('potwierdz')"></app-input>
        </div>
        <div class="error-info" fxLayout="row" fxLayoutAlign=" center" *ngIf="isRequiredError && myForm.invalid">
          Please fill in a valid value for all required fields
        </div>
      </mat-card-content>
      <mat-card-actions fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <button mat-raised-button type="button" color="warn" *ngIf="idKierowcy" (click)="deleteDriver()">Delete Driver</button>
        </div>
        <div>
          <button mat-raised-button type="reset">Cancel</button>
          <button mat-raised-button color="primary" type="submit"
                  [innerText]="idKierowcy ? 'Save' : 'Create'" [disabled]="saving">
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
