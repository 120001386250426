import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TrucksInventoryService } from 'src/app/services';
import { Marka, Model, RodzajPojazdu } from 'src/app/models/dto/vehicle';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import * as Vehicles from '../../../../ngrx/vehicle.actions';
import { Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-vehicle-model',
  templateUrl: './vehicle-model.component.html',
  styleUrls: ['./vehicle-model.component.scss']
})
export class VehicleModelComponent implements OnInit, OnDestroy {
  vehicleVendors$: Observable<Marka[]>;
  vehicleTypes$: Observable<RodzajPojazdu[]>;

  @ViewChild (MatSort) sort: MatSort;
  form: FormGroup;
  selectedVehicleModel: Model = {model: '', id: 0, _marka: {id: 0, marka: ''}, _rodzaj: {id: 0, rodzaj: ''}};
  buttonContent = 'Add';
  vehicleModelsInfo: MatTableDataSource<Model>;
  displayedColumns: string[] = ['Id', 'Model', 'Make', 'Type', 'Actions'];

  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>, private veh: TrucksInventoryService) {
    this.form = new FormGroup({
      'model': new FormControl(''),
      'make': new FormControl(null),
      'type': new FormControl(null)
    });
  }

  ngOnInit() {
    this.vehicleTypes$ = this.store.select(fromRoot.selectors.vehicles.getTruckTypes);
    this.vehicleVendors$ = this.store.select(fromRoot.selectors.vehicles.getVendors);

    this.subs.add(
      this.store.select(fromRoot.selectors.vehicles.getModels)
      .subscribe((models) => {
        this.vehicleModelsInfo = new MatTableDataSource(models);
        this.vehicleModelsInfo.data = models;
        this.vehicleModelsInfo.sortingDataAccessor = (item: Model, property) => {
          switch (property) {
            case 'Id':
              return item.id;
            case 'Model':
              return item.model;
            case 'Make':
              return item._marka.marka;
            case 'Type':
              return item._rodzaj.rodzaj;
            default:
              return item[property];
          }
        };
        this.vehicleModelsInfo.sort = this.sort;
      })
    );
    this.loadLatestData();
    this.subs.add(
      this.form.valueChanges.subscribe(value => {
        if (value.model === '') {
          this.selectedVehicleModel = {model: '', id: 0, _marka: {id: 0, marka: ''}, _rodzaj: {id: 0, rodzaj: ''}};
        }
        this.buttonContent = this.selectedVehicleModel.id === 0 ? 'Add' : 'Update';
      })
    );
  }

  private loadLatestData() {
    this.store.dispatch(new Vehicles.LoadModelsRequest());
    this.selectedVehicleModel = {model: '', id: 0, _marka: {id: 0, marka: ''}, _rodzaj: {id: 0, rodzaj: ''}};
    this.form.reset();
  }

  removeElement(element: Model) {
    this.veh.delVehicleModel(element)
      .subscribe(() => {
        this.loadLatestData();
      });
  }

  editElement(element: Model) {
    this.selectedVehicleModel = element;
    this.form.patchValue({
      'model': this.selectedVehicleModel.model,
      'make': this.selectedVehicleModel._marka.id,
      'type': this.selectedVehicleModel._rodzaj.id
    });
  }

  saveItem() {
    if (this.selectedVehicleModel.id < 1) {
      const newVehicleModel = {
        id: null,
        model: this.form.get('model').value,
        _marka: {id: this.form.get('make').value.id, marka: this.form.get('make').value.marka},
        _rodzaj: {id: this.form.get('type').value.id, rodzaj: this.form.get('type').value.rodzaj}
      };
      this.veh.addVehicleModel(newVehicleModel)
        .subscribe(() => {
          this.loadLatestData();
        });
    } else {
      const updatedVehicleModel = {
        id: this.selectedVehicleModel.id,
        model: this.form.get('model').value,
        _marka: {id: this.selectedVehicleModel._marka.id, marka: this.form.get('make').value},
        _rodzaj: {id: this.selectedVehicleModel._rodzaj.id, rodzaj: this.form.get('type').value}
      };
      this.veh.updVehicleModel(updatedVehicleModel)
        .subscribe(() => {
          this.loadLatestData();
        });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
