<div fxFlex fxLayout="column" fxLayoutGap="5px">
  <div fxLayout="row" class="form-container" fxLayoutAlign="start center" fxLayoutGap="15px">
    <mat-form-field appearance="outline" class="custom-form-ctrl">
      <mat-label>Search...</mat-label>
      <input matInput  [formControl]="searchControl" class="mat-body-2">
    </mat-form-field>
  </div>
  <div class="mat-elevation-z8 white-bar">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="osoba">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver</th>
        <td mat-cell *matCellDef="let element">
          <a href="#"
             [routerLink]="'../edit/'+element.id">{{element._uzytkownik.nazwisko}} {{element._uzytkownik.imie}}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="telefon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tel. no. 1 / Tel. no. 2</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element._uzytkownik.tel1; else noInfo">{{ element._uzytkownik.tel1 }}</span>
          <ng-template #noInfo>not-set</ng-template>
          /
          <span *ngIf="element._uzytkownik.tel2; else noInfo">{{element._uzytkownik.tel2}}</span>
          <ng-template #noInfo>not-set</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="data_zatr">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of employ.</th>
        <td mat-cell *matCellDef="let element">
          {{element._uzytkownik.data_zatr | date}}
        </td>
      </ng-container>
      <ng-container matColumnDef="nr_karty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Card</th>
        <td mat-cell *matCellDef="let element">{{element.nr_karty}}</td>
      </ng-container>
      <ng-container matColumnDef="login">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Login</th>
        <td mat-cell *matCellDef="let element">{{element._uzytkownik.username}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="more-actions-icon">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deleteDriver(element)">
              <mat-icon>delete</mat-icon>
              <span>
                Delete
              </span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator appPaginationPageSize
                   [length]="dataSource.data.length"
                   showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

