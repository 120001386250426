<div class="table-container mat-elevation-z8 white-bar" fxLayout="column">
  <div class="action-buttons" fxLayout="row" fxLayoutGap="10px">
    <button mat-raised-button color="primary" (click)="addNewSoftware()" class="accept-button mat-button">Add new</button>
    <mat-checkbox (change)="wycofane($event)" [(ngModel)]="pokazWycofane">Show withdrawn</mat-checkbox>
  </div>
  <table mat-table [dataSource]="softwareListInfo" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Idss</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="oznaczenie">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag</th>
      <td mat-cell *matCellDef="let element">{{ element.oznaczenie }} </td>
    </ng-container>

    <ng-container matColumnDef="data_wydania">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="release_date"> Release date</th>
      <td mat-cell *matCellDef="let element"
          class="release_date"> {{ element.data_wydania  | date : df.PIPE_DATE_LABEL}} </td>
    </ng-container>

    <ng-container matColumnDef="opis">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc.</th>
      <td mat-cell *matCellDef="let element"> {{ element.opis }} </td>
    </ng-container>

    <ng-container matColumnDef="czy_wycofane">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Withdrawn?</th>
      <td mat-cell *matCellDef="let element"> {{ element.wycofane | yesNo }} </td>
    </ng-container>

    <ng-container matColumnDef="wersjaRamkis">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Frames</th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button (click)="frameDetails(element.id)" *ngIf="!element.wycofane">
          Ramki: {{ element.wersjaRamkiOprs.length }}
        </button>
        <span *ngIf="element.wycofane">Ramki: {{ element.wersjaRamkiOprs.length }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="opis_zmian">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Changes</th>
      <td mat-cell *matCellDef="let element">{{ element.opis_zmian }}</td>
    </ng-container>

    <ng-container matColumnDef="akcje">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="more-actions-icon">more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="edit(row)">
            <mat-icon>mode_edit</mat-icon>
            <span class="action-description">Edit software</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; let row; columns: displayedColumns;"
        [class.inactive]="element.wycofane" class="element-row"></tr>
  </table>
  <mat-paginator appPaginationPageSize
                 [length]="softwareListInfo.data.length"
                 showFirstLastButtons>
  </mat-paginator>
</div>
