<div mat-dialog-title>
  <div fxFlex fxLayout="column" fxLayoutGap="5px">
    <span *ngIf="data.ramka && data.wersja">
      Edytujesz: {{data.ramka.nazwa}} / {{data.wersja.typ}} / {{data.wersja.wersja}}
    </span>
    <span *ngIf="data.pole">Pole: {{data.pole.nazwaPola}}</span>
    <span *ngIf="!data.pole">Nowe pole, podaj jego właściwości</span>
  </div>
</div>
<div mat-dialog-content>
  <form [formGroup]="formEdit" (ngSubmit)="zapisz()" fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="2 0 0">
        <input matInput placeholder="Name:" formControlName="nazwaPola">
      </mat-form-field>
      <mat-form-field fxFlex="1 0 0">
        <mat-select formControlName="typDanych" [compareWith]="compareById">
          <mat-option *ngFor="let typ of data.typy" [value]="typ">
            {{typ.typ}}<span *ngIf="typ.maxWartosc">, max: {{typ.maxWartosc}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field>
        <input matInput placeholder="Bit from:" formControlName="bitOd">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Bit to:" formControlName="bitDo">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Num of buts:" [formControl]="controlBitNum">
      </mat-form-field>
    </div>
    <mat-form-field>
      <textarea matInput placeholder="Description:" formControlName="opis"></textarea>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="In DB:" formControlName="db">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Function:" formControlName="funkcjaPrzeksztalcenia">
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="10px">
      <button mat-stroked-button (click)="anuluj()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="formEdit.invalid">Save</button>
      <button mat-raised-button color="accent" *ngIf="!data.ramka && this.data.pole" (click)="usunPole()">Remove
      </button>
    </div>
  </form>
</div>
