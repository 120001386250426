import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as TERMINAL from '../../../../ngrx/terminal.actions';
import * as fromRoot from '../../../../app.reducer';

@Component({
  selector: 'app-frame-config',
  templateUrl: './frame-config.component.html',
  styleUrls: ['./frame-config.component.scss']
})
export class FrameConfigComponent {

  constructor(private store: Store<fromRoot.State>) {
    this.store.dispatch(new TERMINAL.LoadFramesRequest());
    this.store.dispatch(new TERMINAL.LoadFrameFieldTypesRequest());
  }
}
