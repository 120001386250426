import { Component, OnInit, Input } from '@angular/core';
import { ITileItem } from '../../interfaces/tile-item';

@Component({
  selector: 'app-tile-item',
  templateUrl: './tile-item.component.html',
  styleUrls: ['./tile-item.component.scss']
})
export class TileItemComponent implements OnInit {

  @Input() item: ITileItem;

  constructor() { }

  ngOnInit(): void {
  }

}
