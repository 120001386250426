import { OprogramowanieTerminala, SoftSimpleInfo } from '../../../models/dto/terminale';

export class TerminalSoftwareHelper {

  static przypisaneWersjeDoTerminala(opr: OprogramowanieTerminala): SoftSimpleInfo[] {
    const wersjePrzypisane = [];

    opr.wersjaRamkiOprs.forEach(w => {
      const wer: SoftSimpleInfo = {
        id: w._wersja_ramki.id,
        id_wersja_ramki_opr: w.id,
        nazwa: w._wersja_ramki.ramka.nazwa,
        typ: w._wersja_ramki.typ,
        wersja: w._wersja_ramki.wersja,
        status: w._wersja_ramki.active
      };
      wersjePrzypisane.push(wer);
    });

    return wersjePrzypisane;
  }
}
