import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TypZapiecia } from 'src/app/models/dto/vehicle';
import { TrucksInventoryService } from 'src/app/services';
import * as fromRoot from '../../../../app.reducer';
import * as Vehicles from '../../../../ngrx/vehicle.actions';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-connection-type',
  templateUrl: './connection-type.component.html',
  styleUrls: ['./connection-type.component.scss']
})
export class ConnectionTypeComponent implements OnInit, OnDestroy {
  @ViewChild (MatSort) sort: MatSort;
  form: FormGroup;
  selectedConnection: TypZapiecia = {nazwa: '', id: 0};
  buttonContent = 'Add';
  connectionTypesInfo: MatTableDataSource<TypZapiecia>;
  displayedColumns: string[] = ['Id', 'Type', 'Notes', 'Actions'];

  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>, private veh: TrucksInventoryService) {
    this.form = new FormGroup({
      'type': new FormControl(''),
      'notes': new FormControl('')
    });
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.vehicles.getConnTypes)
      .subscribe(connTypes => {
        this.connectionTypesInfo = new MatTableDataSource(connTypes);
        this.connectionTypesInfo.data = connTypes;
        this.connectionTypesInfo.sortingDataAccessor = (item: TypZapiecia, property) => {
          switch (property) {
            case 'Id':
              return item.id;
            case 'Type':
              return item.nazwa;
            case 'Notes':
              return item.opis;
            default:
              return item[property];
          }
        };
        this.connectionTypesInfo.sort = this.sort;
      })
    );
    this.loadLatestData();
    this.subs.add(
      this.form.valueChanges.subscribe(value => {
        if (value.type === '') {
          this.selectedConnection = {nazwa: '', id: 0};
        }
        this.buttonContent = this.selectedConnection.id === 0 ? 'Add' : 'Update';
      })
    );
  }

  private loadLatestData() {
    this.store.dispatch(new Vehicles.LoadConnectionTypesRequest());
    this.selectedConnection = {nazwa: '', id: 0};
    this.form.reset();
  }

  removeElement(element: TypZapiecia) {
    this.veh.delconnectionType(element).subscribe(() => {
      this.loadLatestData();
    });

  }

  editElement(element: TypZapiecia) {
    this.selectedConnection = element;
    this.form.get('type').setValue(this.selectedConnection.nazwa);
    this.form.get('notes').setValue(this.selectedConnection.opis);
  }

  saveItem() {
    if (this.selectedConnection.id < 1) {
      const newConnType = {id: null, nazwa: this.form.get('type').value, opis: this.form.get('notes').value};
      this.veh.addConnectionType(newConnType)
        .subscribe(() => {
          this.loadLatestData();
        });
    } else {
      const updatedConnType = {id: this.selectedConnection.id, nazwa: this.form.get('type').value, opis: this.form.get('notes').value};
      this.veh.updConnectionType(updatedConnType)
        .subscribe(() => {
          this.loadLatestData();
        });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
