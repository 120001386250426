<div class="table-container mat-elevation-z8" fxLayout="column">
  <div class="action-buttons" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <ng-container *appHasPrivileges="[ePrivs.ADM_TERMINAL]">
      <button mat-raised-button color="primary" class="accept-button mat-button" (click)="addNewTerminal()">New device</button>
      <button mat-raised-button color="primary" class="accept-button mat-button" (click)="assignTerminal()">Assign</button>
      <mat-checkbox (change)="wycofane($event)" [(ngModel)]="showAll">Show withdrawn/terminated</mat-checkbox>
    </ng-container>
    <mat-form-field appearance="outline" class="custom-form-ctrl">
      <mat-label>Search...</mat-label>
      <input placeholder="Type to search..." matInput [formControl]="searchBoxControl">
    </mat-form-field>
  </div>
  <section>
    <mat-table [dataSource]="terminalList" matSort class="mat-elevation-z8" [trackBy]="trackByFn">

      <ng-container [matColumnDef]="TerminalTableColumns.Id">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="60px">Id</mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="60px">{{ element.id }}</mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.Status">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element._status.status }} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.Assigned">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Assigned?</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="assigned">
            {{
              !element.samochods?.length && !element.naczepas?.length ? 'No' : ''
            }}

            <terminal-veh-reg-number
              *ngIf="element.samochods?.length"
              [vehType]="vehicleType.TRUCK"
            >
              {{ element.samochods[0].nr_rej}}
            </terminal-veh-reg-number>

            <terminal-veh-reg-number
              *ngIf="element.naczepas?.length"
              [vehType]="vehicleType.TRAILER"
            >
              {{ element.naczepas[0].nr_rej}}
            </terminal-veh-reg-number>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.LastMsg">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Last msg.</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [innerHTML]="element | terminalLastTimeInfo"></span>
          <span *ngIf="(lastInfo | validateGpsInfo : element.id) !== true"
                [matTooltip]="'GPS position invalid!'"
                class="material-icons-outlined red-icon">
            error
          </span>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.SerialNumber">
        <mat-header-cell mat-header-cell *matHeaderCellDef>S/N</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.nr_seryjny }} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.BatteryLevel">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Battery level</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.zw !== null; else noBatteryData">
            <battery-level
              class="d-inline ml-2"
              matTooltip="battery level {{element.zw}}%"
              [percentage]="element.zw"
              [showPercentage]="true">
            </battery-level>
          </ng-container>

          <ng-template #noBatteryData>
              -
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.Software">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Software</mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="start center">
          <span fxFlex="50%">
            <abbr title="{{ element._opr.data_wydania | date : df.PIPE_DATETIME_LABEL }}: {{ element._opr.opis}}">
              {{ element._opr.oznaczenie }}
            </abbr>
          </span>
          <button fxFlex="50%" mat-icon-button (click)="updateSoftware(element)" *appHasPrivileges="[ePrivs.MNG_TERMINAL_SOFT]">
            <mat-icon>developer_board</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.Active">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="120px">Active | Test </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayoutGap="5px" fxFlex="120px">
          <span class="czy_aktywny" [class.highlight]="!element.czy_aktywny">{{ element.czy_aktywny | yesNo }}</span>
          <span>|</span>
          <span class="tryb_testowy" [class.highlight]="element.tryb_testowy">{{ element.tryb_testowy | yesNo }}</span>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.Notes">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Notes</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.uwagi }} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="TerminalTableColumns.Actions">
        <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" fxLayoutAlign="end center">
          <button mat-icon-button class="locker-status"
                  *ngIf="lockStatus(row).class !== 'none'" (click)="requestLockerChange(row)">
            <bolt-status-icon [locker]="row.rygiels[0]"></bolt-status-icon>
          </button>
          <button mat-icon-button class="sim-status" *ngIf="simStatus(row) !== 'no-sim' else noSimCardButton"
                  (click)="showSimCardForm(row)">
            <mat-icon [class]="simStatus(row)">sim_card</mat-icon>
          </button>
          <ng-template #noSimCardButton>
            <button mat-icon-button class="sim-status" (click)="showSimCardForm(row)">
              <mat-icon class="no-sim">no_sim</mat-icon>
            </button>
          </ng-template>

          <button mat-icon-button [matMenuTriggerFor]="menu" *appHasPrivileges="[ePrivs.EDIT_TERMINAL]">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="edit(row)">
              <mat-icon>airport_shuttle</mat-icon>
              <span>Terminal</span>
            </button>
            <button mat-menu-item (click)="manageLocker(row)">
              <mat-icon>enhanced_encryption</mat-icon>
              <span>Locker</span>
            </button>
            <button mat-menu-item (click)="showSimCardForm(row)">
              <mat-icon>sim_card</mat-icon>
              <span>SIM</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
               [class.inactive]="!row.czy_aktywny || row._status.id === 4"
               class="element-row"
      ></mat-row>
    </mat-table>
  </section>
</div>
