import { Pipe, PipeTransform } from '@angular/core';
import { ITransportEntity } from '../interfaces/transport-entity';
import moment from 'moment';

@Pipe({
  name: 'isTransportStarted'
})
export class IsTransportStartedPipe implements PipeTransform {

  transform(value: ITransportEntity): boolean {
    return value.rozpoczety && moment(value.czasWyjazdu).diff(moment()) <= 0;
  }

}
