import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../../../app.reducer';
import * as TERMINAL from '../../../../ngrx/terminal.actions';
import { OprogramowanieTerminala, OprogramowanieWersjaRamkiInsert, Ramka, SoftSimpleInfo } from '../../../../models/dto/terminale';
import { Api } from '../../../../../environments/api';
import { TerminalSoftwareHelper } from '../../helpers';

@Component({
  selector: 'app-software-frame-dialog',
  templateUrl: './software-frame-dialog.component.html',
  styleUrls: ['./software-frame-dialog.component.scss']
})
export class SoftwareFrameDialogComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  oprogramowanie: OprogramowanieTerminala;
  ramki: Ramka[] = [];
  wersjeDostepne: SoftSimpleInfo[] = [];
  wersjePrzypisane: SoftSimpleInfo[] = [];

  constructor(private store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<SoftwareFrameDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public softwareId: number) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getSoftwareList),
        this.store.select(fromRoot.selectors.devices.getFrames)
      ]).pipe(
        filter(r => r[0].length > 0 && r[1].length > 0)
      ).subscribe(([a, b]) => {
        this.oprogramowanie = a.find(f => f.id === this.softwareId);
        this.wersjePrzypisane = TerminalSoftwareHelper.przypisaneWersjeDoTerminala(this.oprogramowanie);

        this.sortujWersje(this.wersjePrzypisane);

        this.wersjeDostepne = [];
        this.ramki = b;
        this.ramki.forEach(r => {
          r.wersjaRamkis.forEach(w => {
            if (this.wersjePrzypisane.find(wp => wp.wersja === w.wersja && wp.typ === w.typ && wp.nazwa === r.nazwa) || !w.active) {
              return;
            }

            const wer: SoftSimpleInfo = {
              id: w.id,
              nazwa: r.nazwa,
              typ: w.typ,
              wersja: w.wersja,
              status: w.active
            };
            this.wersjeDostepne.push(wer);
          });
        });
        this.sortujWersje(this.wersjeDostepne);
      })
    );
  }

  remove(w: SoftSimpleInfo) {
    if (!w.id_wersja_ramki_opr) {
      return false;
    }
    const request = {
      record: w.id_wersja_ramki_opr,
      successCallback: () => {
        const idx = this.wersjePrzypisane.findIndex(wp => wp.wersja === w.wersja && wp.typ === w.typ && wp.nazwa === w.nazwa);
        const e = this.wersjePrzypisane.splice(idx, 1);
        if (e[0].status) {
          this.wersjeDostepne.push(e[0]);
          this.sortujWersje(this.wersjeDostepne);
        }
      }
    };
    this.store.dispatch(new TERMINAL.RemoveSoftwareFrameRequest(request));
  }

  add(w: SoftSimpleInfo) {
    const record: OprogramowanieWersjaRamkiInsert = {
      _oprogramowanie_terminala: Api.Terminale.OprogramowanieTerminala + '/' + this.oprogramowanie.id,
      _wersja_ramki: Api.Ramki.WersjaRamek + '/' + w.id
    };
    const request = {
      record,
      successCallback: () => {
        const idx = this.wersjeDostepne.findIndex(wp => wp.wersja === w.wersja && wp.typ === w.typ && wp.nazwa === w.nazwa);
        const e = this.wersjeDostepne.splice(idx, 1);
        this.wersjePrzypisane.push(e[0]);
        this.sortujWersje(this.wersjePrzypisane);
      }
    };
    this.store.dispatch(new TERMINAL.NewSoftwareFrameRequest(request));
  }

  private sortujWersje(w: SoftSimpleInfo[]) {
    return w.sort((a, b) => a.nazwa > b.nazwa ? 1 : -1);
  }

}
