export interface SearchRouteComponentData {
  transsetId: number;
  transitId: number;
  sequence: number;
  /**
   * After route was approved and assigned to given transsetId
   */
  saveCallback: () => void;
  /**
   * Fallback when action failed or user want to cancel the action
   */
  fallback: () => void;
}
