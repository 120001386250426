import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import * as TRANSPORT from '../../ngrx/transport.actions';
import { updateTransportRequest } from '../../ngrx/transport.actions';
import * as TRANSSET from '../../../transport-set/ngrx/transset.actions';
import * as UI from '../../../../ngrx/ui.actions';
import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as MAP from '../../../../ngrx/map.actions';
import * as DRIVER from '../../../drivers/ngrx/driver.actions';

import { IRouteTemplateEntity } from '../../interfaces/route-template-entity';
import { AssignParkingToTransitComponent, TransportSetDetailsComponent } from '../../components';
import { ICompanyEntity, ICompanyRoute } from '../../interfaces';
import { RightDrawerService } from '../../../shared/services';
import { SearchRouteComponentData } from '../../../../models/map';
import { CreateNewTransitForm, DepartureAndArrival, TransportFormRoute } from '../../../../models/form/transit';
import { AppUserInfo } from '../../../../models/authentication';
import { MomentHelper, Strings, TransportHelper } from '../../../../helpers';
import { ITemplateMadeRouteSection } from '../../interfaces/template-made-route-section';
import { MapViewRoute, Privs, ToastType, TransportFormStep, UserRoles } from '../../../../helpers/enum';
import { BaseYesNoConfig, Coords, SelectOption } from '../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../shared/dialogs';
import { ITransportEntityExtended } from '../../interfaces/transport-entity';
import { TransportMsg } from '../../../../messages';
import { WlascicielTowaru, ZestawTransportowyVehicles } from '../../../../models/dto/transportSets';
import { Uzytkownik } from 'src/app/models/dto/user';
import { FormHelper } from 'src/app/modules/shared/helpers/form-helper';
import { TransitService } from '../../services';
import { VehicleBasic } from 'src/app/models/dto/vehicle';
import { isNil } from 'lodash';

@Component({
  selector: 'app-create-new-transport',
  templateUrl: './create-new-transport.component.html',
  styleUrls: ['./create-new-transport.component.scss']
})
export class CreateNewTransportComponent implements OnInit, OnDestroy {
  @ViewChild('panel') panel: MatExpansionPanel;
  isPageReady = false;
  isFormLinear = true;

  // security
  cargoOwnerLov: SelectOption<WlascicielTowaru>[] = [];
  cmLov: SelectOption<ICompanyEntity>[] = [];
  dispatchersLov: SelectOption<Uzytkownik>[];

  currentStep = TransportFormStep.TruckAndTrailer;
  linkStep = TransportFormStep.TruckAndTrailer;

  ePrivs = Privs;

  form: CreateNewTransitForm;
  today: Date;

  editedTransportId: number;
  editedTransitTranssetId: number;

  availableRouteSections: IRouteTemplateEntity[] = [];
  filteredRouteSections: IRouteTemplateEntity[] = [];

  readyTransportSetsPanelIsOpened$: Observable<boolean>;
  subs = new Subscription();
  currUser: AppUserInfo;
  editedTransit: ITransportEntityExtended = null;
  eMapViewRoute = MapViewRoute;

  refreshTrucksSubject$: Subject<DepartureAndArrival> = new ReplaySubject();
  trucks$: Observable<VehicleBasic[]> = this.refreshTrucksSubject$.pipe(
    switchMap(departureAndArrival => this.getAvailableTrucks(departureAndArrival)),
    catchError(() => EMPTY)
  );

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService,
    private router: Router,
    private readonly route: ActivatedRoute,
    private dialog: MatDialog,
    private transitService: TransitService
  ) {
    this.store.dispatch(TRANSSET.loadFullTranssetListRequestNew());
    this.store.dispatch(new DRIVER.LoadDriversRequest());
    this.store.dispatch(TRANSPORT.loadCargoOwnersRequest());
    this.store.dispatch(TRANSPORT.loadCmListRequest());

    this.today = new Date();

    this.route.params
      .pipe(
        take(1),
        filter(r => r['id'] !== undefined),
        map(r => {
          return [+r['id'], r['step'] ? +r['step'] : TransportFormStep.TruckAndTrailer];
        })
      )
      .subscribe(([transportId, step]) => {
        this.linkStep = step;
        this.isPageReady = false;
        this.editedTransportId = transportId;
        this.isFormLinear = false;
        this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId}));
        this.store.dispatch(TRANSPORT.loadTransportRoutesRequest({transportId}));
      });

    this.subs.add(
      this.store.select(fromRoot.selectors.auth.getUserInfo)
        .pipe(
          filter(r => r !== null && r.userId !== undefined),
          debounceTime(350)
        )
        .subscribe(r => {
          this.currUser = r;
        })
    );
    this.subs.add(
      combineLatest([
        this.store.select(fromTransport.getCargoOwners),
        this.store.select(fromTransport.getCmList),
        this.store.select(fromRoot.selectors.user.getCompanyUsersByRole(UserRoles.DYSPOZYTOR)),
      ])
        .pipe(debounceTime(250))
        .subscribe(([ownerList, cmList, dispatchers]) => {
          const cargoOwnerHash = FormHelper.makeSelectLov(ownerList, 'nazwa');
          cargoOwnerHash.push({value: null, label: 'None', disabled: false});

          const cmListCenter = cmList.map(cm => cm.centrum);
          const cmListHash = FormHelper.makeSelectLov(cmListCenter, 'nazwa');

          if (Strings.getObjectHash(cargoOwnerHash) !== Strings.getObjectHash(this.cargoOwnerLov)) {
            this.cargoOwnerLov = cargoOwnerHash;
          }
          if (Strings.getObjectHash(cmListHash) !== Strings.getObjectHash(this.cargoOwnerLov)) {
            this.cmLov = cmListHash;
          }
          this.dispatchersLov = FormHelper.makeSelectLov(dispatchers, ['imie', 'nazwisko']);
        })
    );
  }

  ngOnInit(): void {
    if (!this.editedTransportId) {
      this.isPageReady = true;
    }
    this.form = new CreateNewTransitForm();
    this.readyTransportSetsPanelIsOpened$ = this.store.select(fromTransport.getIfReadyTransportSetsPanelIsOpened);

    let hashEdit = '';
    this.subs.add(
      combineLatest([
        this.store.select(fromTransport.getCurrentTransit),
        this.store.select(fromTransport.getRoutesByTransitId(this.editedTransportId)),
      ])
        .pipe(
          filter(r => r[0] !== null),
          filter(r => hashEdit !== Strings.getObjectHash(r)),
          tap(r => hashEdit = Strings.getObjectHash(r)),
          debounceTime(500)
        )
        .subscribe(([transit, transitRoutes]) => {
          this.form.reset();
          this.editedTransit = transit;
          if (transit.zestaw) {
            this.editedTransitTranssetId = transit.zestaw.id;
          }
          this.form.patchFromTransportEntity(transit, transitRoutes);
          this.checkTransportShippingPoints(transitRoutes, transit);

          this.getAvailableSets(this.form.departureAndArrival.value);
          this.refreshTrucksSubject$.next(this.form.departureAndArrival.value);

          setTimeout(() => {
            this.isPageReady = true;
            setTimeout(() => {
              this.currentStep = this.linkStep;
            }, 150);
          }, 150);
        })
    );

    this.subs.add(
      this.store.select(fromTransport.getSelectedTransportSet)
        .subscribe(set => {
          if (set) {
            this.form.patchFromTransportSet(set);
            this.editedTransitTranssetId = set.id;

            const defaultTruck = TransportHelper.getBasicVehicle(set.truckSet);

            if(defaultTruck) {
              this.setTruck(defaultTruck);
            }
          }
        })
    );

    this.subs.add(
      combineLatest([
        this.form.departureAndArrival.get('fromDate').valueChanges,
        this.form.departureAndArrival.get('fromTime').valueChanges,
        this.form.departureAndArrival.get('toDate').valueChanges,
        this.form.departureAndArrival.get('toTime').valueChanges,
      ])
        .pipe(
          filter(() => this.form.isDepartureValid && this.form.isArrivalValid),
        )
        .subscribe(([fromDate, fromTime, toDate, toTime]) => {
          const departure = moment(MomentHelper.datetimeForGivenDateAndTime(fromDate, fromTime));
          const arrival = moment(MomentHelper.datetimeForGivenDateAndTime(toDate, toTime));
          if (arrival.unix() - departure.unix() <= 0) {
            this.form.departureAndArrival.patchValue({
              toDate: null,
              toTime: null
            });
          }

          this.getAvailableSets({fromDate, fromTime, toDate, toTime});
          this.refreshTrucksSubject$.next({fromDate, fromTime, toDate, toTime});
        }));

    this.subs.add(
      this.store.select(fromTransport.getRouteTemplateList)
        .pipe(
          filter(r => r.length > 0)
        )
        .subscribe((templates: IRouteTemplateEntity[]) => {
          this.availableRouteSections = templates;
          this.filteredRouteSections = templates;
        })
    );

    this.subscribeControls();
  }

  subscribeControls(): void {
    this.subs.add(
      this.form.getStepFourControl('monitored').valueChanges.subscribe(isMonitored => {
        const centrumControl: FormControl = this.form.getStepFourControl('centrum');

        if (!isMonitored) {
          if (!this.editedTransit?.przekazDoCm) {
            centrumControl.setValue(null);
          }
          centrumControl.disable();
        } else if (isMonitored && !this.editedTransit?.przekazDoCm) {
          centrumControl.enable();
        }
      })
    );

    this.subs.add(
      this.form.getStepFourControl('geofencing').valueChanges.subscribe(isGeofencing => {
        const geofMetryControl: FormControl = this.form.getStepFourControl('geofMetry');
        const GEOF_DEFAULT_M = 2000;

        if (!isGeofencing) {
          geofMetryControl.setValue(null);
          geofMetryControl.disable();
        } else if (isGeofencing) {
          if (!geofMetryControl.value) {
            geofMetryControl.setValue(GEOF_DEFAULT_M);
          }
          geofMetryControl.enable();
        }
      })
    );
  }

  updateTransportSecurity(): void {
    this.store.dispatch(updateTransportRequest({
      transport: {...this.form.stepFour.getRawValue()},
      transportId: this.editedTransportId
    }));
  }

  compareValues(t1: any, t2: any): boolean {
    return t1 && t2 ? t1.id === t2.id : t1 === t2;
  }

  stepSelectionChanged(event: StepperSelectionEvent) {
    this.router.navigate(['../' + event.selectedIndex], {relativeTo: this.route});
  }

  closingTimeIsWrong(stepName: string, formGroupName: string, addedDriver?: boolean): boolean {
    if (this.form.value) {
      const groupValue = addedDriver ?
        this.form.getRawValue()[stepName]['addedDrivers'][formGroupName] : this.form.getRawValue()[stepName][formGroupName];

      if (!groupValue) {
        return false;
      }

      const daysAreTheSame =
        groupValue['fromDate'] !== null && groupValue['toDate'] !== null
        && groupValue['fromDate'].getDate() === groupValue['toDate'].getDate();
      const closingTimeIsEarlierThanOpening =
        groupValue['toTime'] !== null && groupValue['fromTime'] !== null && groupValue['toTime'] <= groupValue['fromTime'];
      return daysAreTheSame && closingTimeIsEarlierThanOpening;
    }
  }

  setMinimumClosingDate(stepName: string, formGroupName: string, addedDriver?: boolean): Date {
    if (this.form.value) {
      const groupValue = addedDriver ?
        this.form.getRawValue()[stepName]['addedDrivers'][formGroupName] : this.form.getRawValue()[stepName][formGroupName];
      return groupValue && groupValue['fromDate'] ? groupValue['fromDate'] : this.today;
    }
  }

  closeTransportSetPanel(): void {
    if (this.panel.expanded) {
      this.store.dispatch(TRANSPORT.toggleReadyTransportSetsPanel({show: false}));
    }
  }

  showChosenSetDetails(): void {
    this.rightDrawer.open(TransportSetDetailsComponent, this.form.transsetObj.value);
  }

  saveTransport(): void {
    if (this.form.transsetObj.valid) {
      const transportRequest = this.form.getTransitRequestValue(this.currUser.userId ? +this.currUser.userId : undefined).transit;

      let message = TransportMsg.TRANSPORT_CREATED;
      if (transportRequest.id) {
        message = TransportMsg.TRANSPORT_UPDATED;
      }

      this.store.dispatch(TRANSPORT.saveTransportRequest({
        transportRequest,
        successCallback: (transportId) => {
          if (transportRequest.id) {
            this.store.dispatch(UI.showUserMessage({
              message: {
                type: ToastType.SUCCESS,
                title: 'Transport: ' + transportId,
                message
              }
            }));
          } else {
            this.router.navigate(['transport', 'edit', transportId, TransportFormStep.Drivers])
              .then(() => {
                this.store.dispatch(UI.showUserMessage({
                  message: {
                    type: ToastType.SUCCESS,
                    title: 'Transport: ' + transportId,
                    message
                  }
                }));
              });
          }
        }
      }));

    }
  }

  setFilter(active: boolean, routeSectionNo: number): void {
    const isOnlyPrivate = this.form.routeSectionByIdxType(routeSectionNo, 'onlyPrivate').value;
    this.filteredRouteSections = [...this.availableRouteSections
      .filter(item => {
        if (isOnlyPrivate) {
          return item.czy_prywatny && Strings.getNumberFromString(item._uzytkownik) === +this.currUser.userId;
        }
        return true;
      })];
  }

  editRouteSection(idx: number): void {
    const obj = this.form.routeSections.controls[idx];
    switch (obj.value.type) {
      case 'saved': {
        obj.patchValue({type: 'edit'});
        break;
      }
      case 'edit': {
        obj.patchValue({type: 'saved'});
        break;
      }
    }
  }

  createNewRouteSectionOption(no: number): void {
    const extraData: SearchRouteComponentData = {
      transsetId: this.editedTransitTranssetId,
      transitId: this.editedTransportId,
      sequence: no,
      fallback: () => this.router.navigate(['transport/edit/', this.editedTransportId, 2]),
      saveCallback: () => {
        this.router.navigate([this.editedTransportId ? `/transport/edit/${this.editedTransportId}/2` : '/transit/new-transport'])
          .then(() => {
            this.store.dispatch(UI.showUserMessage({
              message: {
                type: ToastType.SUCCESS, message: 'New route section has been successfully assigned to transport.'
              }
            }));
          });
      }
    };
    this.store.dispatch(MAP.ViewerExtraData({extraData}));
    this.router.navigate(['/map-view', MapViewRoute.TRANSPORT_NEW_ROUTE]);
  }

  saveTemplateMadeRouteSection(routeSectionOrderNumber: number, route: TransportFormRoute): void {
    const {id, savedRoute} = route;
    if (typeof savedRoute === 'string') {
      return;
    }
    const routeSection: ITemplateMadeRouteSection = {
      nazwa_trasy: savedRoute.nazwa,
      id_szablon: '' + savedRoute.id,
      id_przejazd: this.editedTransportId.toString(),
      przelicz_trase: true,
      numer_porzadkowy: routeSectionOrderNumber.toString(),
      debug: true,
    };

    const onTransportSaveSuccess = () => {
      this.store.dispatch(TRANSPORT.loadTransportRoutesRequest({transportId: this.editedTransportId}));
      this.store.dispatch(UI.showUserMessage({
        message: {
          type: ToastType.SUCCESS,
          message: 'Route has been assigned, reloading...'
        }
      }));
    };

    if (id) {
      this.store.dispatch(TRANSPORT.removeRouteSectionRequest({
        routeId: id,
        onSuccess: () => {
          this.store.dispatch(TRANSPORT.saveTemplateMadeRouteSectionRequest({
            route: routeSection,
            onSuccess: onTransportSaveSuccess
          }));
        }
      }));
    } else {
      this.store.dispatch(TRANSPORT.saveTemplateMadeRouteSectionRequest({
        route: routeSection,
        onSuccess: onTransportSaveSuccess
      }));
    }
  }

  twoRouteSectionsAreContinuous$(currSectionNumber: number): Observable<boolean> {
    if (currSectionNumber > 0) {
      const currentSectionIsBasedOnRouteTemplate = !!this.form.routeSections.value[currSectionNumber].savedRoute;
      const previousSectionIsBasedOnRouteTemplate = !!this.form.routeSections.value[currSectionNumber - 1].savedRoute;
      const currFormCtrl = this.form.routeSections.controls[currSectionNumber] as FormGroup;
      const currFormCtrlIsTouched = currFormCtrl.controls['savedRoute'].touched;
      if (currentSectionIsBasedOnRouteTemplate && previousSectionIsBasedOnRouteTemplate && currFormCtrlIsTouched) {
        const currRouteSection = this.availableRouteSections
          .find(o => o.nazwa === this.form.routeSections.value[currSectionNumber].savedRoute);
        const prevRouteSection = this.availableRouteSections
          .find(o => o.nazwa === this.form.routeSections.value[currSectionNumber - 1].savedRoute);
        const startPointOfCurrRouteSection = currRouteSection?._punkt_poczatkowy;
        const endPointOfPrevRouteSection = prevRouteSection?._punkt_koncowy;

        return of(startPointOfCurrRouteSection === endPointOfPrevRouteSection);
      }
      return of(true);
    } else {
      return of(true);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(TRANSPORT.showTransportSetAsNotChosen());
    this.store.dispatch(TRANSPORT.showTransportSetAsChosenNew({zestaw: null}));
    this.store.dispatch(TRANSPORT.loadTransitByIdSuccess({transit: null}));
    this.store.dispatch(TRANSPORT.toggleReadyTransportSetsPanel({show: false}));
  }

  removeRouteSection(sectionNo: number): void {
    const routeId = +(this.form.routeSectionByIdxType(sectionNo, 'id')?.value);

    if (routeId < 1) {
      this.form.removeRouteSectionByIdx(sectionNo);
      return;
    }

    const routeName = this.form.routeSectionByIdxType(sectionNo, 'assignedRoute').value.nazwa;

    const config: BaseYesNoConfig = {
      title: 'Removing assigned route',
      content: `<b>${routeName}</b><br />Are you sure you want to proceed?`,
      yesAction: () => {
        this.store.dispatch(TRANSPORT.removeRouteSectionRequest({
          routeId: +routeId,
          onSuccess: () => {
            if (this.editedTransportId) {
              this.store.dispatch(UI.showUserMessage({
                message: {
                  message: 'Route section has been removed.',
                  type: ToastType.INFO,
                }
              }));
              this.store.dispatch(TRANSPORT.loadTransportRoutesRequest({
                transportId: this.editedTransportId
              }));
              if (this.form.routeSections.length === 1) {
                this.store.dispatch(updateTransportRequest({
                  transport: {mscDocelowe: null, mscWyjazdu: null},
                  transportId: this.editedTransportId
                }));
              }
            }
          }
        }));
      },
      yesColor: 'accent',
      noColor: 'primary',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-transport-route-remove',
      position: {top: '7%'}
    });

  }

  savedRouteDisplay(value: IRouteTemplateEntity | ICompanyRoute): string {
    return value ? value.nazwa : '';
  }

  showOnMap(dataToMap: MapViewRoute.PARKING | MapViewRoute.TRANSPORT_ROUTE): void {
    if (this.form.routeSections.length > 0) {
      const lines = (this.form.routeSections.value as TransportFormRoute[])
        .filter(element => typeof element.savedRoute === 'string')
        .map(element => JSON.parse(element.savedRoute as string) as string[])
        .map(e => {
          return e
            .map(str => str.split(','))
            .map(([gpsNs, gpsEw]) => {
              return {gpsEw: +gpsEw, gpsNs: +gpsNs} as Coords;
            });
        });
      this.store.dispatch(MAP.AdhocLines({lines}));
    }

    if (dataToMap === MapViewRoute.PARKING) {
      this.router.navigate(['/map-view', MapViewRoute.TRANSPORT_PARKING_LIST]).then(() => {
        this.rightDrawer.open(
          AssignParkingToTransitComponent,
          {
            transit: this.editedTransit,
            fallback: () => this.router.navigate(['transport/edit/', this.editedTransportId, 3]),
          }
        );
      });
    } else if (dataToMap === MapViewRoute.TRANSPORT_ROUTE) {
      this.router.navigate(['/map-view', MapViewRoute.TRANSPORT_ROUTE, this.editedTransportId]);
    }
  }

  setTruck(truck: VehicleBasic): void {
    this.form.stepOne.patchValue({ truck });
  }

  get defaultSetTruck(): VehicleBasic | null | undefined {
    return (<ZestawTransportowyVehicles>(this.form.transsetObj.value))?.truckSet?.find((truck) => isNil(truck.czasOdlaczenia))?.samochod;
  }

  private getAvailableSets(departureAndArrival: DepartureAndArrival): void {
    const {fromDate, fromTime, toDate, toTime} = departureAndArrival;

    this.store.dispatch(TRANSPORT.loadTransportSetsAvailableWithinTimeRangeRequest({
      timeRange: {
        from: MomentHelper.datetimeForGivenDateAndTime(fromDate, fromTime).toISOString(),
        to: MomentHelper.datetimeForGivenDateAndTime(toDate, toTime).toISOString()
      }
    }));
  }

  private getAvailableTrucks(departureAndArrival: DepartureAndArrival): Observable<VehicleBasic[]> {
    const {fromDate, fromTime, toDate, toTime} = departureAndArrival;

    return this.transitService.getTrucksAvailableWithinTimeRange(
      MomentHelper.datetimeForGivenDateAndTime(fromDate, fromTime).toISOString(),
      MomentHelper.datetimeForGivenDateAndTime(toDate, toTime).toISOString()
    );
  }

  private checkTransportShippingPoints(routes: ICompanyRoute[], transit: ITransportEntityExtended) {
    if (routes.length < 1 || !transit) {
      return;
    }

    const start = routes[0].id_punkt_sped_poczatkowy;
    const koniec = [...routes].pop().id_punkt_sped_koncowy;

    this.subs.add(this.store.select(fromRoot.selectors.transset.getShippingPoints)
      .pipe(
        filter(r => r.length > 0)
      )
      .subscribe(points => {
        const updTransport = {
          id: transit.id
        };
        let toUpdate = false;
        if (transit.mscWyjazdu?.id !== start) {
          const point = points.find(p => p.id === start);
          if (point) {
            toUpdate = true;
            updTransport['mscWyjazdu'] = point;
          }
        }
        if (transit.mscDocelowe?.id !== koniec) {
          const point = points.find(p => p.id === koniec);
          if (point) {
            toUpdate = true;
            updTransport['mscDocelowe'] = point;
          }
        }

        if (toUpdate) {
          this.store.dispatch(TRANSPORT.saveTransportRequest({
            transportRequest: {...this.form.getTransitRequestValue().transit, ...updTransport}
          }));
        }
      }));
  }
}
